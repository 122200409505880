import React, {useState} from "react"
import '../../locales'
import {FormField, SelectNative, SelectOption} from "@metro-ui/core"
import "react-datepicker/dist/react-datepicker.css"

import {ICard, ISelectedCardAndBusiness, useGeneralContext} from "../../context/GeneralContext"
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {hasCountryConfig, isBlank} from "../../utils";
import {triggerCustomEventGoogleAnalytics360} from "../../ga360";

export const NO_CARD_SELECTED = "--"

const MainContainer = styled.div`   
  @media (min-width: 576px) {
    width: 20em;
    padding-bottom: 1em
  }
  @media (max-width: 576px) {
    width: 100%;
    padding-bottom: 1em
  }
`;

const CardholderDropDownList = (
  {
    isDisabled,
    filterMinusId,
    page,
    value,
    setValue
  }: {
    isDisabled?: boolean,
    filterMinusId?: boolean,
    page?: string,
    value?: ISelectedCardAndBusiness,
    setValue?: (card: ISelectedCardAndBusiness) => void
  }
) => {
  const {selectedCardAndBusiness, setSelectedCardAndBusiness, resolveCardsList, country, alexCssBusinessCards} = useGeneralContext()
  const {t} = useTranslation();
  const cardholderList: ICard[] = filterMinusId? resolveCardsList().filter(card => !card.cardHolderID.endsWith("--1")) : resolveCardsList();
  const showCardHolderID: boolean = hasCountryConfig(country,'CARD_HOLDER_DROPDOWN_DISPLAY_ID_ACTIVATED_COUNTRIES')

  const getPersonalCard = () => {
    if (alexCssBusinessCards?.length !== 2) {
      return null;
    }

    if (alexCssBusinessCards.filter(card => card.b2C).length > 0
        && alexCssBusinessCards.filter(card => card.b2C)[0].legitimations.length != 0) {
      return alexCssBusinessCards.filter(card => card.b2C)[0];
    }

    return null;
  }

  const personalCard = getPersonalCard()

  if (cardholderList.length <= 1){
    return <></>
  }

  function handleSelectedCardChange(newValue: string) {
    if (page != "SEARCH_FORM" && setValue) {
      setValue({
        businessId: selectedCardAndBusiness?.businessId,
        cardholderId: newValue === NO_CARD_SELECTED ? undefined : newValue
      })
    } else {
      setSelectedCardAndBusiness({
        businessId: selectedCardAndBusiness?.businessId,
        cardholderId: newValue === NO_CARD_SELECTED ? undefined : newValue
      })
    }
    triggerCustomEventGoogleAnalytics360("card switching")
  }

  let SETTINGS_OVERVIEW_SELECTED_CARD = value?.cardholderId ? value?.cardholderId : NO_CARD_SELECTED;
  let INVOICE_PAGE_SELECTED_CARD = selectedCardAndBusiness?.cardholderId ? selectedCardAndBusiness?.cardholderId : NO_CARD_SELECTED;

  return (
    <MainContainer>
      <FormField
        htmlFor="id"
        label={t("common.card")}
        data-testid="CardholderDropDownListFormField"
      >
        <SelectNative
          id="some-id"
          name="some-name"
          data-testid="CardholderDropDownListSelectSearchable"
          isDisabled={isDisabled}
          value={page != "SEARCH_FORM" ? SETTINGS_OVERVIEW_SELECTED_CARD: INVOICE_PAGE_SELECTED_CARD}
          onChange={(
            _event: KeyboardEvent | React.SyntheticEvent,
            newValue: string,
          ) => {handleSelectedCardChange(newValue);}}
        >
          {page == "SEARCH_FORM" && <SelectOption data-testid="CardholderDropDownListSelectOptionDefault" key={NO_CARD_SELECTED} value={NO_CARD_SELECTED}>{t("all.cards")}</SelectOption>}
          {cardholderList.map(cardholder => (
            <SelectOption data-testid="CardholderDropDownListSelectOption" value={cardholder.cardHolderID} key={cardholder.cardHolderID}>
              {!showCardHolderID && (!isBlank(cardholder.cardHolderName) ? cardholder.cardHolderName : cardholder.cardHolderID)}
              {showCardHolderID && cardholder.cardHolderID}
            </SelectOption>
          ))}
          {personalCard ? <SelectOption isDisabled>------------------------------</SelectOption> : <></>}
          {personalCard ? <SelectOption value={personalCard.legitimations[0]} key={personalCard.legitimations[0]} data-testid="CardholderDropDownListSelectPersonal">{t("personal.card")}</SelectOption> : <></>}
        </SelectNative>
      </FormField>
    </MainContainer>
  )
}

export default CardholderDropDownList;