import Footer from "./Footer";
import Header from "./Header";
import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {useIdamAuthContext} from "../context/IdamAuthContext";
import NavigationBar from "./NavigationBar";
import {useTranslation} from "react-i18next";
import {Alert} from "@metro-ui/core";
import {useGeneralContext} from "../context/GeneralContext";
import {getConfig, getCountryConfig, hasCountryConfig, isIpadOS, parseCardholder, saveCookie} from "../utils";
import CookieDisclaimerX from "./metrox/CookieDisclaimerMetroX";
import {IAlexCssBusinessCards, useApi} from "../context/ApiContext";
import {useLocation, useNavigate} from "react-router-dom";
import {pageList, pages} from "../App";
import {loadGoogleAnalytics, triggerGoogleAnalytics} from "../gtm";
import i18n from "i18next";
import {setActiveContext} from "./metrox/FlyoutMetroX";
import {Survey} from "./qualtrics/Survey";
import {ReleaseNote} from "./qualtrics/ReleaseNote";
import Cookies from "js-cookie";
import {Md5} from 'ts-md5';
import AppBanner from "./AppBanner";
import * as _ from "lodash";

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Space = styled.div`
    height:100%;
    flex-grow:3
`;

const Main = styled.div`
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;

    @media (min-width: 576px) {
        padding-right: 15px;
        padding-left: 15px
    }
    
    @media (min-width: 768px) {
        padding-right: 15px;
        padding-left: 15px
    }
    
    @media (min-width: 992px) {
        padding-right: 15px;
        padding-left: 15px
    }
    
    @media (min-width: 1200px) {
        padding-right: 15px;
        padding-left: 15px
    }
    
    @media (min-width: 576px) {
        width: 540px;
        max-width: 100%
    }
    
    @media (min-width: 768px) {
        width: 720px;
        max-width: 100%
    }
    
    @media (min-width: 992px) {
        width: 960px;
        max-width: 100%
    }
    
    @media (min-width: 1200px) {
        width: 1140px;
        max-width: 100%
    }
`;

const Pre = styled.pre`
  font-family: Lato, sans-serif;
  font-size: 16px;
  margin: 0;
  white-space: pre-wrap;
`;

function Layout({children}: { children: React.ReactNode }) {
  const {idToken, login, isImpersonationMode, idamToken} = useIdamAuthContext()
  const api = useApi()
  const {
    country,
    storeId,
    setStoreId,
    apiErrorMessage,
    cleanApiErrorMessage,
    selectedCardAndBusiness,
    impersonatedCard,
    isAppReady,
    gtmInitialized,
    setGtmInitialized,
    setAlexCssBusinessCards,
    alexCssBusinessCards,
    cardFromIdamToken,
    resolveCardsList,
    getAllCards,
    setCardFromIdamToken,
    setSelectedCardAndBusiness,
    customerRole
  } = useGeneralContext();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const isMobile = /iPhone|iPad|Android/i.test(navigator.userAgent) || isIpadOS();
  const [key, setKey] = useState(0);

  const reloadComponent = () => {
    if (key < 3){
      setKey(prevKey => prevKey + 1);
    }
  };

  const [showMaintenanceMessage, setShowMaintenanceMessage] = React.useState<boolean>(
      hasCountryConfig(country, "MAINTENANCE_MESSAGE_ENABLED")
  );

  const [showCustomerInfo, setShowCustomerInfo] = React.useState<boolean>(shouldShowCustomerMessage());

  if (isAppReady) {
    let navigateTo = ""

    if (isImpersonationMode && impersonatedCard === undefined && pathname !== pages.impersonation.path) {
      navigateTo = pages.impersonation.path;
    }

    if (pageList.filter((page) => page.path === pathname).length === 0) {
      if (isImpersonationMode)
        navigateTo = pages.impersonation.path;
      else
        navigateTo = pages.invoices.path;
    }

    navigateTo && navigate(navigateTo)
  }

  useEffect(() => {
    login();
  }, [])

  function shouldShowCustomerMessage() {
    const cookieValue = Cookies.get("customer-info-1")
    return t("customer.info", {'defaultValue':''}).trim().length > 0 &&
        (cookieValue === undefined || cookieValue !== Md5.hashStr(t("customer.info")))
  }

  function resolveActiveBusiness() {
    const isFlyoutActivated = hasCountryConfig(country, "FLYOUT_METROX");
    if (isFlyoutActivated) {
      api?.getActiveBusiness()?.then((activeContext: string) => {
        setActiveContext(activeContext, setSelectedCardAndBusiness)
      }).catch((e) => {
        navigate(pages.noAccount.path)
      })
    }
  }

  function getCardholderFromIdamToken() {
    const cardholderId = idamToken?.cardholderId;
    if (!cardholderId) {
      navigate(pages.noAccount.path);
    } else {
      setCardFromIdamToken(cardholderId);
    }
  }

  useEffect(() => {
    if (isAppReady && !isImpersonationMode) {
      if (hasCountryConfig(country, "ALEX_COUNTRY")) {
        resolveActiveBusiness();
      }
    }
  }, [isAppReady, isImpersonationMode,key])

  useEffect(() => {
    if (isAppReady && !isImpersonationMode) {
      if (hasCountryConfig(country, "ALEX_COUNTRY")) {
        if (selectedCardAndBusiness) {
          if (_.isEmpty(selectedCardAndBusiness.businessId) && key < 3) {
            setTimeout(() => reloadComponent(), 3000)
          } else {
            api?.getAlexCardholders(selectedCardAndBusiness.businessId)?.then((alexCssBusinessCards: IAlexCssBusinessCards[]) => {
              if (alexCssBusinessCards?.find((element) => element.legitimations.length)?.legitimations.length) {
                setAlexCssBusinessCards(alexCssBusinessCards);
              } else {
                navigate(pages.noAccount.path)
              }
            }).catch((e) => {
              navigate(pages.noAccount.path)
            })
          }
        }
      } else {
        getCardholderFromIdamToken();
      }
    }
  }, [isAppReady, isImpersonationMode, selectedCardAndBusiness?.businessId,key]);

  function getCardholderStoreId(allCards: string[]) {
    let cardholder: string | undefined = undefined;
    if (selectedCardAndBusiness?.cardholderId)
      cardholder = selectedCardAndBusiness?.cardholderId
    else {
      let cards = resolveCardsList()
      if (cards.length)
        cardholder = cards[0].cardHolderID
      else {
        if (allCards.length)
          cardholder = allCards[0]
      }
    }
    return parseCardholder(cardholder)?.storeId;
  }

  useEffect(() => {
    const allCards = getAllCards()
    if (isAppReady && allCards.length) {
      const cardHolderStoreId = getCardholderStoreId(allCards);
      if (cardHolderStoreId && cardHolderStoreId !== storeId) {
        setStoreId(cardHolderStoreId);
      }
    }
  }, [isAppReady, selectedCardAndBusiness, alexCssBusinessCards, cardFromIdamToken])

  useEffect( () => {
    const languageTag = i18n.language
    const pageName = pageList.find((page) => page.path.includes(pathname))?.name;
    if (isAppReady && !isImpersonationMode && pageName && storeId && customerRole) {
      if (!gtmInitialized) {
        loadGoogleAnalytics(
            pageName,
            languageTag,
            storeId,
            200,
            customerRole
        );
        setGtmInitialized(true);
      } else {
        triggerGoogleAnalytics(
            pageName,
            languageTag,
            storeId,
            200,
            customerRole
        )
      }
    }
  }, [isAppReady, isImpersonationMode, pathname, storeId, customerRole])

  return (
    <> {isAppReady &&
    <StyledLayout>
        <iframe
            src={getCountryConfig(country, "IDAM_ISSUER") + "/authorize/api/oauth2/check_session_iframe"}
            data-openid-origin={getCountryConfig(country, "IDAM_ISSUER")}
            data-openid-client-id={getCountryConfig(country, "IDAM_CLIENT_ID")}
            data-openid-session-state={idToken?.session_state}
            data-logout-request-uri={"/clearSession"}
            frameBorder={0}
            width={0}
            height={0}
        />
        <>
          <Header/>
          { isMobile === true && <AppBanner/> }
          {isImpersonationMode &&
          <Alert
              variant="error"
              isOverarching
              title={t("page.impersonation.info.title") + ":"}
              actions={[
                {
                  text: t("page.impersonation.info.description"), onClick: () => {
                    navigate(pages.impersonation.path)
                  }
                },
              ]}
          >
            {impersonatedCard && "(" + t("common.customer") + ": " + impersonatedCard + ")"}
          </Alert>
          }
        </>
        <Body>
            {(getAllCards().length || isImpersonationMode) ? <NavigationBar/> : <div style={{height: '20px'}}/>}
            <Main>
              <div style={{
                display: "flex",
                flexDirection: "column",
                columnGap: "10px"
              }}>
                {(alexCssBusinessCards || cardFromIdamToken) && !resolveCardsList().length &&
                  <Alert
                      variant="warning"
                      title=""
                      size="medium"
                  >
                    {t("warning.business.has.no.valid.card")}
                  </Alert>
                }
                {showMaintenanceMessage &&
                  <Alert
                      variant="warning"
                      title=""
                      size="medium"
                      onCtaClick={() => setShowMaintenanceMessage(false)}
                  >
                    {t("maintenance.message")}
                  </Alert>
                }
                {showCustomerInfo &&
                <Alert
                    variant="info"
                    title=""
                    size="medium"
                    data-testid="customer-info-alert"
                    onCtaClick={() => {
                      setShowCustomerInfo(false)
                      saveCookie("customer-info-1", Md5.hashStr(t("customer.info")), Number(getConfig("CUSTOMER_INFO_COOKIE_AGE")))
                    }}
                >
                  <Pre>{t("customer.info")}</Pre>
                </Alert>
                }
                {apiErrorMessage &&
                  <Alert
                      variant="error"
                      title=""
                      size="medium"
                      onCtaClick={() => cleanApiErrorMessage()}
                  >
                    {apiErrorMessage}
                  </Alert>
                }
              </div>
              {children}
            </Main>
        </Body>
        <Space/>
        <Footer/>
        <CookieDisclaimerX/>
        <Survey/>
        <ReleaseNote/>
    </StyledLayout>
    } </>
  )
}

export default Layout;