import React from "react";
import styled from "styled-components";
import {useGeneralContext} from "../../context/GeneralContext";
import {getCountryConfig, hasCountryConfig} from "../../utils";
import useScript from "../../hook/useScript";
import FlyoutMetroX from "./FlyoutMetroX";
import {Button} from "@metro-ui/core";
import {useIdamAuthContext} from "../../context/IdamAuthContext";
import {useTranslation} from "react-i18next";

const ButtonContainer = styled.div`
  height: 100%;
  margin: 10px;
`

function Header() {
  const {country} = useGeneralContext()
  const {logout} = useIdamAuthContext()
  const {t} = useTranslation();
  const url = getCountryConfig(country, "METROX_URL") + '/data/apps/metro?sc_device=webcomponent&components=bucket%20header%20container'
  useScript(url, "METROX_HEADER")
  const hasFlyout = hasCountryConfig(country, "FLYOUT_METROX");

  return (
    <>
      <cms-bucket-header-container>
        <div slot="account-switch">
          {hasFlyout ?
            <FlyoutMetroX/> :
            <ButtonContainer>
              <Button variant="outlineInverted" onClick={logout}>{t("nav.logout")}</Button>
            </ButtonContainer>}
        </div>
      </cms-bucket-header-container>
    </>
  )

}

export default Header;