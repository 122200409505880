import React, {useEffect, useState} from 'react';
import {Alert, Button, Cell, Checkbox, Description, Grid, Heading, Separator} from "@metro-ui/core";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import TextInputField from "../component/InputField/TextInputField";
import {useGeneralContext} from "../context/GeneralContext";
import {pages} from "../App";
import {useNavigate} from "react-router-dom";
import {useIdamAuthContext} from "../context/IdamAuthContext";

interface IImpersonationState {
  storeId: string,
  customerId: string,
  cardId: string,
  anyError: boolean,
  confirmation: boolean,
}

const defaultImpersonationState: IImpersonationState = {
  storeId: "",
  customerId: "",
  cardId: "",
  anyError: false,
  confirmation: false,
}

const BottomSettingsLayout = styled.div`
    display: flex;
    justify-content: end;
`
const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`
const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`
const FlexHalfWidth = styled.div`
    flex: none;
    width: 35%;
`
const HorizontalSpace = styled.div`
    flex: none;
    width: 5%;
`

function ImpersonationPage() {
  const {t} = useTranslation();
  const {isAppReady, country, setImpersonatedCard} = useGeneralContext();
  const {isImpersonationMode} = useIdamAuthContext();
  const navigate = useNavigate();
  const [impersonationState, setImpersonationState] = useState<IImpersonationState>(defaultImpersonationState);

  useEffect(()=>{
    if(isAppReady && !isImpersonationMode)
      navigate(pages.invoices.path)
  }, [isAppReady, isImpersonationMode])

  if(!isAppReady || !isImpersonationMode)
    return <></>;

  return (
    <>
      <Heading>
        {t("page.impersonation.title")}
      </Heading>
      <Separator/>
      <FlexRow>
        <FlexHalfWidth>
          {t("page.impersonation.description")}
        </FlexHalfWidth>
        <HorizontalSpace/>
        <FlexColumn>
          <Alert
            variant="info"
            title=""
          >
            {t("page.impersonation.attention")}
          </Alert>
          {impersonationState.anyError &&
          <Alert
              variant="error"
              title=""
              size="medium"
              onCtaClick={() => setImpersonationState({...impersonationState, anyError: false})}
          >
            {t("page.impersonation.update.error")}
          </Alert>
          }
          <Grid>
            <Cell span={2} start={1} row={1}>
              <TextInputField
                title={t("page.impersonation.store.placeholder")}
                placeHolder={t("page.impersonation.store.placeholder")}
                value={impersonationState.storeId}
                setValue={(storeId) => {
                  storeId.match(/^\d+$/) ?
                    setImpersonationState({...impersonationState, storeId: storeId}) :
                    storeId === "" && setImpersonationState({...impersonationState, storeId: ""})
                }}
                hasError={impersonationState.anyError && !Number(impersonationState.storeId)}
                errorMessage={t("page.impersonation.store.help")}
              />
            </Cell>
            <Cell span={2} start={3} row={1}>
              <TextInputField
                title={t("page.impersonation.customer.placeholder")}
                placeHolder={t("page.impersonation.customer.placeholder")}
                value={impersonationState.customerId}
                setValue={(customerId) => {
                  customerId.match(/^\d+$/) ?
                    setImpersonationState({...impersonationState, customerId: customerId}) :
                    customerId === "" && setImpersonationState({
                      ...impersonationState,
                      customerId: ""
                    })
                }}
                hasError={impersonationState.anyError && !Number(impersonationState.customerId)}
                errorMessage={t("page.impersonation.customer.help")}
              />
            </Cell>
            <Cell span={2} start={5} row={1}>
              <TextInputField
                title={t("page.impersonation.card.placeholder")}
                placeHolder={t("page.impersonation.card.placeholder")}
                value={impersonationState.cardId}
                setValue={(cardId) => {
                  cardId.match(/^\d+$/) ?
                    setImpersonationState({...impersonationState, cardId: cardId}) :
                    cardId === "" && setImpersonationState({...impersonationState, cardId: ""})
                }}
                hasError={impersonationState.anyError && !Number(impersonationState.cardId)}
                errorMessage={t("page.impersonation.card.help")}
              />
            </Cell>
          </Grid>
          <div>
            <Checkbox
              id="confirm"
              name="confirm"
              data-testid="confirmationCheckbox"
              isChecked={impersonationState.confirmation}
              onChange={() => {
                setImpersonationState({
                  ...impersonationState,
                  confirmation: !impersonationState.confirmation
                })
              }}
            >
              {t("page.impersonation.confirm")}
            </Checkbox>
            <Description>
              {t("page.impersonation.confirm.description")}
            </Description>
          </div>
        </FlexColumn>
      </FlexRow>
      <Separator/>
      <BottomSettingsLayout>
        <Button
          size="small"
          data-testid="submitButton"
          onClick={() => {
            if (impersonationState.confirmation && Number(impersonationState.storeId) && Number(impersonationState.customerId) && Number(impersonationState.cardId)) {
              let cardHolderId = country.toUpperCase() + "-" + impersonationState.storeId + "-" + impersonationState.customerId + "-" + impersonationState.cardId;
              setImpersonatedCard(cardHolderId)
              navigate(pages.invoices.path)
            } else setImpersonationState({...impersonationState, anyError: true})
          }}
          isDisabled={!impersonationState.confirmation}
        >
          {t("page.settings.update")}
        </Button>
      </BottomSettingsLayout>
    </>
  );
}

export default ImpersonationPage;