import React, {useEffect, useState} from "react";
import {Button, Card, Heading, IconButton, Layout, Text} from "@metro-ui/core";
import {Icons} from "@metro-ui/core/es6/icons/allAtOnce";
import {getConfig, isIpadOS} from "../utils";
import {getCountryApp} from "../countryToggles";
import {useGeneralContext} from "../context/GeneralContext";
import {triggerCustomEventGoogleAnalytics360} from "../ga360";
import {useTranslation} from "react-i18next";

function AppBanner() {
    const {country} = useGeneralContext()
    const appType = getCountryApp(country)
    const [showBanner, setShowBanner] = useState<boolean>(true)
    const {t} = useTranslation()

    useEffect(() => {
        const isBannerClosed = localStorage.getItem('isBannerClosed');

        if (isBannerClosed) setShowBanner(false);
    }, []);

    const redirectToApp = () => {
        const isIOS = /iPhone|iPad/i.test(navigator.userAgent) || isIpadOS()
        const isAndroid = /Android/i.test(navigator.userAgent)

        if (isIOS) redirectToIOSApp()
        else if (isAndroid) redirectToAndroidApp()
    };

    const redirectToIOSApp = () => {
        triggerCustomEventGoogleAnalytics360("app banner clicked", 'IOS');

        if (appType === 'makro') window.location.href = getConfig('MAKRO_COMPANION_IOS_LINK')
            else window.location.href = getConfig('METRO_COMPANION_IOS_LINK')
    }

    const redirectToAndroidApp = () => {
        triggerCustomEventGoogleAnalytics360("app banner clicked", 'Android');

        if (appType === 'makro') window.location.href = getConfig('MAKRO_COMPANION_ANDROID_LINK')
            else window.location.href = getConfig('METRO_COMPANION_ANDROID_LINK')
    }

    const closeBanner = () => {
        localStorage.setItem('isBannerClosed', 'true');
        setShowBanner(false);
    }

    return (
        <>
            { showBanner &&
                <Card style={{marginBottom: '0.5rem'}}>
                    <Layout direction='row' style={{display: 'flex', alignItems: 'center'}}>
                        <img src={appType === 'makro' ? '/makroIcon.png' : '/logo512.png'} alt='App Logo'
                             style={{maxWidth: '5rem', maxHeight: '5rem', padding: '1rem'}}/>
                        <Layout direction='column' grow={1}>
                            <Heading
                                style={{margin: '1rem 0 0 0'}}>{appType === 'makro' ? 'MAKRO Companion' : 'METRO Companion'}</Heading>
                            <Text style={{margin: '0'}}>{t('app.banner.description')}</Text>
                            <Button
                                onClick={redirectToApp}
                                style={{margin: '0.5rem 0 1rem 0'}}
                                data-testid='app-banner-button'>{t('app.banner.button')}</Button>
                        </Layout>
                        <IconButton
                            Icon={Icons['Close']}
                            className="storybook-icon-button"
                            label="Add something"
                            onClick={closeBanner}
                            style={{alignSelf: 'flex-start', justifyContent: 'end', marginTop:'0.5rem'}}
                            data-testid='app-banner-close-button'
                        />
                    </Layout>
                </Card>
            }
        </>
    );
}

export default AppBanner