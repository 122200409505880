import React from 'react';
import {useTranslation} from "react-i18next";
import {Separator, Tab, TabGroup, Tabs} from '@metro-ui/core';
import {useLocation, useNavigate} from "react-router-dom";
import {pageList, pages} from '../App'
import {useIdamAuthContext} from "../context/IdamAuthContext";
import {getConfig, getCountryURLConfig, hasCountryConfig} from "../utils";
import {useGeneralContext} from "../context/GeneralContext";
import NewNavigationBar from "./NewNavigationBar";

export default function NavigationBar() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const {isImpersonationMode} = useIdamAuthContext();
    const {country} = useGeneralContext();
    const newNavBarEnabled = getConfig("NEW_NAV_BAR")

    const shopOnlineTabName = "shopOnline";
    const shopOnlineURL = getCountryURLConfig(country, "SHOP_ONLINE_URL");

    const onTabChangeHandler = (name: string | number): void => {
        if (name === shopOnlineTabName) {
            window.location.href = shopOnlineURL;
        } else {
            navigate(pageList.find((page) => page.name === name)?.path!)
        }
    }

    return (
        newNavBarEnabled === 'true' ?
            <NewNavigationBar/> :
            <div data-testid="current-nav-bar">
                <div style={{width: '20px'}}>
                    <TabGroup
                        value={pageList.find((page) => page.path === pathname)?.name}
                        onChange={onTabChangeHandler}
                        id="navigation"
                    >
                        <Tabs>
                            {
                                shopOnlineURL !== '#' &&
                                <Tab name={shopOnlineTabName}>{t("nav.onlineShop")}</Tab>
                            }
                            <Tab name={pages.invoices.name}>{t(pages.invoices.translationKey)}</Tab>
                            {
                                hasCountryConfig(country, "OVER_VIEW_PAGE_ACTIVATED_COUNTRIES") &&
                                <Tab name={pages.overview.name}>{t(pages.overview.translationKey)}</Tab>
                            }
                            {
                                hasCountryConfig(country, "SETTING_PAGE_ACTIVATED_COUNTRIES") &&
                                <Tab name={pages.settings.name}>{t(pages.settings.translationKey)}</Tab>
                            }
                            {
                                isImpersonationMode &&
                                <Tab name={pages.impersonation.name}>{t(pages.impersonation.translationKey)}</Tab>
                            }
                        </Tabs>
                    </TabGroup>
                </div>
                <Separator style={{marginTop: '0px'}}/>
            </div>
    );
}