import React, {useEffect} from "react";
import {ISelectedCardAndBusiness, useGeneralContext} from "../../context/GeneralContext";
import useScript from "../../hook/useScript";
import {getCountryConfig} from "../../utils";
import "@webcomponents/webcomponentsjs/webcomponents-bundle";
import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js';
import {useIdamAuthContext} from "../../context/IdamAuthContext";
import {Mutex} from "async-mutex";

let eventSubscribed = false
let oldActiveContext : string | undefined = undefined;
const mutex = new Mutex();
export async function setActiveContext(activeContext : string, setSelectedCardAndBusiness: (cardholderAndBusiness: ISelectedCardAndBusiness | undefined) => void) {
  await mutex.runExclusive(async () => {
    if (activeContext !== oldActiveContext) {
      setSelectedCardAndBusiness({businessId: activeContext, cardholderId: undefined})
      oldActiveContext = activeContext
    }
  })
}

function FlyoutMetroX() {
  const {country, setSelectedCardAndBusiness} = useGeneralContext()
  const {logoutWithIdToken, ssoToken} = useIdamAuthContext()

  const url = getCountryConfig(country, "METROX_URL") + '/data/apps/metro?sc_device=webcomponent&components=user%20profile'
  useScript(url, "METROX_FLYOUT")

  const style = document.createElement('style');
  style.id = 'flyout-metro-x-custom-style'
  style.innerHTML ='.ncms-webcomp .account-user .user-icon .arrow::after { top: 1px;}';

  useEffect(() => {
    if (ssoToken) {
      document.querySelector('cms-user-profile')?.addEventListener('cms:user-profile:logout', function (e) {
        logoutWithIdToken(ssoToken);
        e.preventDefault();
      })
    }
  }, [ssoToken])

  useEffect(() => {
    if(!eventSubscribed)
    {
      document.querySelector('cms-user-profile')?.addEventListener('cms:user-profile:active-context-change', function(e) {
        // @ts-ignore
        let activeContext = e.activeContext || (e.originalEvent || e.target).activeContext;
        setActiveContext(activeContext, setSelectedCardAndBusiness)
      })
      eventSubscribed = true;
    }

    // inject custom css into shadow root
    if (!document.getElementById(style.id)) {
        document.querySelector('cms-user-profile')?.shadowRoot?.append(style);
    }

  }, [document.querySelector('cms-user-profile')])

  return (
    <cms-user-profile>
    </cms-user-profile>
  )
}

export default FlyoutMetroX;
