import React, {useEffect, useState} from "react";
import {Button, FormField} from "@metro-ui/core";
import {Icons} from "@metro-ui/core/es6/icons/allAtOnce";
import {useTranslation} from "react-i18next";
import DateInputField from "./InputField/DateInputField";
import TextInputField from "./InputField/TextInputField";
import {useGeneralContext} from "../context/GeneralContext";
import CardholderDropDownList from "./InputField/CardholderDropDownList";
import styled from "styled-components";
import {triggerCustomEventGoogleAnalytics360} from "../ga360";

const MainContainer = styled.div`
      @media (min-width: 576px) {
        flex-direction: row;
        align-items: flex-end;
      }
`;

const CardHolderContainer = styled.div`
      display: flex;
      @media (min-width: 576px) {
          width: 20rem;
      }
`;

const DateAndPatternContainer = styled.div`
      display: flex;
      @media (max-width: 576px) {
          flex-direction: column;
          gap: 10px;
      }
      @media (min-width: 576px) {
          flex-direction: row;
          gap: 10px;
          align-items: baseline;
      }
`;

const CustomContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const PatternContainer = styled.div`
    @media (min-width: 576px) {
      flex-grow: 2;
    }
`;

function SearchForm({searchCallback, isDisabled, isWaiting}:
                      {
                        searchCallback: (startDate: Date, endDate: Date, searchPattern?: string) => void,
                        isDisabled: boolean,
                        isWaiting: boolean
                      }
) {
  const {selectedCardAndBusiness, isAppReady, invoiceLimit,
      from, setFrom, to, setTo, setSearchValue
  } = useGeneralContext();
  const [search, setSearch] = React.useState<string>("");
  const {t} = useTranslation();
  const validationPattern = /(^[\p{L}0-9\s.,\-_/]+$)/ug
  const [searchDisabled, setSearchDisabled] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const triggerSearch = () =>{
    const trimmed = search.trim()
    searchCallback(
      from,
      to,
        trimmed === "" ? undefined : trimmed
    )
    setSearchValue(trimmed)
    setSearch(trimmed)

    triggerCustomEventGoogleAnalytics360("invoice search", trimmed)
  }
  
  useEffect(() => {
      if (isAppReady)
        searchCallback(from, to, search === "" ? undefined : search)
    }
    , [isAppReady, selectedCardAndBusiness, invoiceLimit]);

  useEffect(() => {
      const trimmedSearch = search.trim()
      if (trimmedSearch.length > 0 && trimmedSearch.length < 3) {
          setSearchDisabled(true)
          setErrorMessage(t("page.error.invalidSearchInput"))
      } else if (trimmedSearch.length === 0 || validationPattern.test(trimmedSearch)) {
          setSearchDisabled(false)
          setErrorMessage("")
      } else {
          setSearchDisabled(true)
          setErrorMessage(t("page.error.wrongSearchInput"))
      }
  }, [search]);

  return (
    <>
      <div style={{minWidth:'90vw'}}></div>
      <div style={{marginBottom: '20px'}}>
        <MainContainer>
            <CardHolderContainer>
                <CardholderDropDownList
                    isDisabled={isDisabled}
                    page = "SEARCH_FORM"
                />
            </CardHolderContainer>
            <DateAndPatternContainer>
                <CustomContainer>
                    <DateInputField
                        title={t("common.from")}
                        value={from}
                        data-testid="SearchFormFromInput"
                        setValue={(date : Date) => {setFrom(date)}}
                        minDate={new Date('2018-01-01')}
                        maxDate={to}
                    />
                </CustomContainer>
                <CustomContainer>
                    <DateInputField
                        title={t("common.to")}
                        value={to}
                        data-testid="SearchFormToInput"
                        setValue={(date : Date) => {setTo(date)}}
                        minDate={from}
                        maxDate={new Date()}
                    />
                </CustomContainer>
                <PatternContainer>
                    <TextInputField
                        title={t("page.invoices.search")}
                        placeHolder={t("page.invoices.search.placeholder")}
                        value={search}
                        data-testid="SearchFormSearchInput"
                        setValue={(value) => {
                            setSearch(value)
                        }}
                        onEnterKey={!searchDisabled ? triggerSearch : () => {}}
                        hasError={searchDisabled}
                        errorMessage={errorMessage}
                    />
                </PatternContainer>
                <CustomContainer>
                    <FormField htmlFor="search-btn" label="" style={{gap: "0.5rem"}}>
                        <Button
                            id="search-btn"
                            Icon={Icons["Search"]}
                            variant={"primary"}
                            size="small"
                            data-testid="SearchFormButton"
                            isDisabled={isDisabled || searchDisabled}
                            isWaiting={isWaiting}
                            onClick={triggerSearch}
                        >
                            {t("page.invoices.search")}
                        </Button>
                    </FormField>
                </CustomContainer>
            </DateAndPatternContainer>
        </MainContainer>
      </div>
    </>
  )
}

export default SearchForm;