import {IAlexCssBusinessCards, ILegitimationsWithName} from "./ApiContext";
import {hasCountryConfig, stringOrEmpty} from "../utils";
import {ICard, ISelectedCardAndBusiness} from "./GeneralContext";

//Methode used to check if we have any card in the current cards model (alex or non alex)
//Generally used to check if we should redirect to the non account page or show a message for the user to select
//a business that had valid cards
export const getAllCards = (country: string, alexCssBusinessCards?: IAlexCssBusinessCards[], cardFromIdamToken?: string) => {
    const isAlexCountry = hasCountryConfig(country, "ALEX_COUNTRY");
    if (isAlexCountry) {
        return alexCssBusinessCards?.flatMap((elem) => elem.legitimations) ?? [];
    }

    return cardFromIdamToken? [cardFromIdamToken] : []
}

//Used for the card drop down list and for all cards query
export const resolveCardsList = (country: string, alexCssBusinessCards?: IAlexCssBusinessCards[], cardFromIdamToken?: string, selectedCardAndBusiness?: ISelectedCardAndBusiness): ICard[] => {
    const isFlyoutActivated = hasCountryConfig(country, "FLYOUT_METROX");
    const isAlexCountry = hasCountryConfig(country, "ALEX_COUNTRY");

    if (!isAlexCountry) {
        return cardFromIdamToken ? [{cardHolderID: cardFromIdamToken}] : [];
    }

    if (!alexCssBusinessCards || alexCssBusinessCards.length == 0) {
        return [];
    }

    if (isFlyoutActivated) {
        if (!hasB2cCard(alexCssBusinessCards)) {
            return getCardsListFromSelectedBusiness(alexCssBusinessCards, selectedCardAndBusiness?.businessId);
        }

        if (selectedCardAndBusiness?.businessId === "personal") {
            return getB2cCardsList(alexCssBusinessCards);
        }

        return alexCssBusinessCards.filter((element) => !element.b2C)
            .map(element => element.legitimationsWithName)
            .map(legitimations => legitimations.map(legitimation => toCard(legitimation)))
            .flat();
    } else {
        return alexCssBusinessCards.map(element => element.legitimationsWithName)
            .map(legitimations => legitimations.map(legitimation => toCard(legitimation)))
            .flat();
    }
}

const hasB2cCard = (cards: IAlexCssBusinessCards[]) => cards && cards.some(card => card.b2C);

const getCardsListFromSelectedBusiness = (businessCards:IAlexCssBusinessCards[], businessId?:string) => {
    const selectedBusinessAccount = businessCards.find((element) => element.accountId === businessId);
    return selectedBusinessAccount?.legitimationsWithName?.map(legitimation => toCard(legitimation)) ?? [];
}

const getB2cCardsList = (businessCards:IAlexCssBusinessCards[]) => {
    const b2cAccount = businessCards.find((element) => element.b2C);
    return b2cAccount?.legitimationsWithName?.map(legitimation => toCard(legitimation)) ?? [];
}

const toCard = (legitimation: ILegitimationsWithName): ICard => {
    return {
        cardHolderName: `${stringOrEmpty(legitimation.firstName)} ${stringOrEmpty(legitimation.middleName)} ${stringOrEmpty(legitimation.lastName)}`,
        cardHolderID: legitimation.legitimation
    }
}
