import React, {ReactNode, useContext, useEffect, useMemo, useState} from 'react';
import {
  getBaseUrlForCountry,
  getCountryConfig,
  getHashParams,
  getJwtPayload,
  randomString,
  removeHashParamsFromUrl
} from "../utils";
import {useGeneralContext} from "./GeneralContext";

export const defaultValue: IIdamAuth = {
  idamToken: undefined,
  accessToken: "",
  idToken: undefined,
  ssoToken: "",
  isImpersonationMode: false,
  logout: () => {
  },
  login: () => {
  },
  logoutWithIdToken: (idToken: string | undefined) => {
  },
  redirectToIdamAuth: (idamCountry: string, redirectUri: string) => {}
}

export const IdamAuthContext = React.createContext<IIdamAuth>(defaultValue);

const IdamAuthProvider = ({children}: { children: ReactNode }) => {

  const {country, setIsAppReady, language} = useGeneralContext()
  const [ssoToken, setSsoToken] = useState<string>()
  const [idToken, setIdToken] = useState<IIdToken>()
  const [accessToken, setAccessToken] = useState<string>()
  const [idamToken, setIdamToken] = useState<IIdamToken>()
  const [isImpersonationMode, setIsImpersonationMode] = useState<boolean>()

  const nonce = randomString();

  useEffect(()=>{
    if (idamToken && !isImpersonationMode) {
      const idamCountry = idamToken.country
      if (idamCountry.toLowerCase() != country.toLowerCase()) {
        redirectToIdamAuth(idamCountry, getBaseUrlForCountry(idamCountry));
      }
    }
  },[idamToken])

  const login = () => {
    if (idToken || ssoToken || accessToken || idamToken)
      return

    // if idamToken is not loaded
    if (!idamToken) {
      // check Hash Params from IDAM redirection
      if (!getAccessTokenFromHashParams()) {
        // check local storage
        if (!getAccessTokenFromLocalStorage()) {
          const redirectURL = window.location.origin + window.location.pathname
          redirectToIdamAuth(country, redirectURL)
        }
      }
    }
  }

  const logout = () => {
    setIsAppReady(false);
    logoutWithIdToken(ssoToken);
  }

  const logoutWithIdToken = (idToken: string | undefined) => {
    localStorage.removeItem("accessToken")
    localStorage.removeItem("idToken")
    redirectToIdamLogout(idToken)
  }

  const getAccessTokenFromHashParams = (): boolean => {
    const {access_token: accessToken, id_token: idToken} = getHashParams()
    if (!accessToken || !idToken)
      return false;

    removeHashParamsFromUrl();
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("idToken", idToken);
    loadAccessToken(accessToken, idToken);

    return true;
  }

  const getAccessTokenFromLocalStorage = (): boolean => {
    const accessToken = localStorage.getItem("accessToken");
    const idToken = localStorage.getItem("idToken");

    if (!accessToken || !idToken)
      return false;

    loadAccessToken(accessToken, idToken)
    return true;
  }

  const loadAccessToken = (accessToken: string, ssoToken: string) => {
    const idamToken: IIdamToken = getJwtPayload(accessToken);
    const idToken: IIdToken = getJwtPayload(ssoToken);
    if (validateIdamToken(idamToken) && validateIdToken(idToken)) {
      let impersonationPosition = false;

      idamToken.authorization.forEach((value, key, map) => {
        if ("MINVAS_IMPERSONATION" in value)
          impersonationPosition = true;
      })
      setIsImpersonationMode(impersonationPosition);
      setAccessToken(accessToken);
      setSsoToken(ssoToken);
      setIdamToken(idamToken);
      setIdToken(idToken);
      setIsAppReady(true)
    } else {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("idToken");
      redirectToIdamAuth(country, window.location.origin);
    }
  }

  const getIdamLocale = (country: string) => {
    const countryIdamLocale = getCountryConfig(country, "IDAM_LOCALE")
    if(countryIdamLocale)
      return countryIdamLocale;
    return language;
  }

  const redirectToIdamAuth = (idamCountry: string, redirectUri: string) => {
    let url = getCountryConfig(idamCountry, "IDAM_ISSUER")
      + "/authorize/api/oauth2/authorize"
      + "?redirect_uri=" + redirectUri
      + "&realm_id=" + getCountryConfig(idamCountry, "IDAM_REALM_ID")
      + "&client_id=" + getCountryConfig(idamCountry, "IDAM_CLIENT_ID")
      + "&response_type=token id_token"
      + "&scope=openid"
      + "&country_code=" + idamCountry.toUpperCase()
      + "&locale_id=" + getIdamLocale(idamCountry)
      + "&nonce=" + nonce;
    window.location.href = encodeURI(url);
  }

  const redirectToIdamLogout = (idToken: string | undefined) => {
    if (!idToken)
      return redirectToIdamAuth(country, window.location.origin);

    let url = getCountryConfig(country, "IDAM_ISSUER")
      + "/authorize/api/oauth2/op_session_end"
      + "?post_logout_redirect_uri=" + window.location.origin
      + "&id_token_hint=" + idToken;
    window.location.href = encodeURI(url);
  }

  const validateIdamToken = (idamToken: IIdamToken): boolean => validateExp(idamToken.exp)
  const validateIdToken = (idToken: IIdToken): boolean => validateExp(idToken.exp)

  const validateExp = (tokenExp: number): boolean => tokenExp * 1000 >= new Date().getTime()


  const contextValue = useMemo(() => ({
    ssoToken, idamToken, accessToken, idToken, isImpersonationMode,
    login, logout, logoutWithIdToken, redirectToIdamAuth
  }), [accessToken, idToken, idamToken, isImpersonationMode, ssoToken]);
  return (
    <IdamAuthContext.Provider value={contextValue}>
      {children}
    </IdamAuthContext.Provider>
  )
}

export const useIdamAuthContext = () => useContext(IdamAuthContext);

export default IdamAuthProvider;