import React, {ReactNode, useContext, useMemo} from 'react';
import {useIdamAuthContext} from "./IdamAuthContext";
import axios, {AxiosRequestConfig} from "axios";
import download from 'downloadjs'
import {useTranslation} from "react-i18next";
import {useGeneralContext} from "./GeneralContext";
import {hasCountryConfig, parseCardholderForCustomerSettings} from "../utils";
import moment from "moment";

export interface IApi {
  getAlexCardholders: (cdmAccountId?: string) => Promise<IAlexCssBusinessCards[]> | null;
  getCardholders: () => Promise<string[]> | null;
  getActiveBusiness: () => Promise<string> | null;
  getInvoices: (startDate?: Date, endDate?: Date, start?: number, limit?: number, search?: string, cdmAccountId?: string, cardholdersScope?: string[] | undefined) => Promise<IInvoiceResponse> | null;
  downloadInvoice: (transactionId: string, cdmAccountId?: string) => Promise<void> | null;
  downloadInvoicesList: (transactionIds: string[], cdmAccountId?: string) => Promise<void> | null;
  getSettings: (cardholderIds: string) => Promise<ICustomerSettings[]> | null;
  saveSettings: (cardholderIds: string, mail: boolean, print: boolean) => Promise<boolean> | null;
  activatedSettings: (country: string) => Promise<ICountrySetting> | null;
  getFilteredCardholders: (setting: string, enabled: boolean, cdmAccountId?: string) => Promise<ICustomer[]> | null;
  overviewStartingDate: (country: string) => Promise<string> | null;
  overviewReportDownload: (year:number, month:number, locale:string, cdmAccountId?: string, cardholdersScope?: string[] | undefined) => Promise<void> | null;
}

export interface ICustomerInvoice {
  articleIdList: string[]
  currency: string
  customerCardholderNumber: number
  customerCountryCode: string
  customerId: number
  customerInvoiceType: string
  customerStoreId: number
  invoiceTimestamp: number
  invoiceNumber: string
  netAmount: number
  orderIdList: string[]
  totalAmount: number
  transactionId: string
  voided: false
  podInvoice?: IPodInvoice
  downloaded: boolean
  searchedArticles: IArticle[]
}

export interface IArticle {
  szItemDesc: string
  lItemNumber: string
  szItemInputString: string
  szGTIN: string | null
}
export interface IPodInvoice {
  invoiceTimestamp: number
  transactionId: string
  invoiceNumber: string
  customerInvoiceType: string
  downloaded: boolean
}

export interface IInvoiceEmbeddedResponse {
  customerInvoices: ICustomerInvoice[]
}

export interface IInvoiceResponse {
  activationDate: Date
  limit: number
  numFound: number
  start: number
  _embedded: IInvoiceEmbeddedResponse
}

export interface ICustomerSettings {
  settings: ICustomerSetting[]
  customer: ICustomer
}

export interface ICustomerSetting {
  setting: ISetting
  value: boolean;
}

export interface ICountrySetting {
  activatedSettings: ISetting[]
}

export interface ICustomer {
  country: string
  storeId: number
  customerId: number
  cardholderId: number
}

export interface ISetting {
  name: string
  description: string
  defaultValue: boolean
}

export interface ILegitimationsWithName {
  firstName: string;
  lastName: string;
  middleName: string;
  legitimation: string;
}

export interface IAlexCssBusinessCards {
  accountId: string
  role: string,
  legitimations: string[]
  legitimationsWithName: ILegitimationsWithName[]
  b2C: boolean
}

export const defaultApiValue = {
  getCardholders: () => null,
  getAlexCardholders: (cdmAccountId?: string) => null,
  getActiveBusiness: () => null,
  getInvoices: (startDate?: Date, endDate?: Date, start?: number, limit?:number, search?: string, cdmAccountId?: string, cardholdersScope?: string[] | undefined) => null,
  downloadInvoice: (transactionId: string, cdmAccountId?: string) => null,
  downloadInvoicesList: (transactionIds: string[]) => null,
  getSettings: (cardholderIds: string) => null,
  saveSettings: (cardholderIds: string, mail: boolean, print: boolean) => null,
  activatedSettings: (country: string) => null,
  getFilteredCardholders: (setting: string, enabled: boolean, cdmAccountId?: string) => null,
  overviewStartingDate: (country: string) => null,
  overviewReportDownload: (year:number, month:number, locale:string, cdmAccountId?: string, cardholdersScope?: string[] | undefined) => null
}

export const ApiContext = React.createContext<IApi>(defaultApiValue);

const ApiContextProvider = ({children}: { children: ReactNode }) => {
  const {accessToken, redirectToIdamAuth} = useIdamAuthContext()
  const {t} = useTranslation();
  const {cleanApiErrorMessage, setApiErrorMessage, country, setOverviewReportEmpty, setInvoiceDownloadEmptyTransactionId} = useGeneralContext()

  const contextValueObject = {
    getCardholders: () => {
      cleanApiErrorMessage()
      if (!accessToken)
        return null;

      return axios.get("cardholders/v1/cardholders", {
        headers: {Authorization: `Bearer ${accessToken}`},
      })
          .then((response) => response.data)
          .catch((err) => handleError(err))
    },

    getAlexCardholders: (cdmAccountId?: string) => {
      cleanApiErrorMessage()
      if (!accessToken)
        return null;

      return axios.get("cardholders/v1/cardholders/css", {
        headers: {Authorization: `Bearer ${accessToken}`}, params: {cdmAccountId}
      })
          .then((response) => response.data)
          .catch((err) => handleError(err))
    },

    getActiveBusiness: () => {
      cleanApiErrorMessage()
      if (!accessToken)
        return null;

      return axios.get("cardholders/v1/cardholders/active-context", {
        headers: {Authorization: `Bearer ${accessToken}`},
      })
          .then((response) => response.data)
          .catch((err) => handleError(err))
    },

    getInvoices: (startDateObj?: Date, endDateObj?: Date, start?: number, limit?: number, search?: string, cdmAccountId?: string, cardholdersScope?: string[] | undefined) => {
      cleanApiErrorMessage()
      if (!accessToken)
        return null;

      const from = startDateObj?.toISOString().slice(0, 10)
      const to = endDateObj?.toISOString().slice(0, 10)
      const pod = !search

      return axios.get("mriapi/v1/customer/invoices", {
        headers: {Authorization: `Bearer ${accessToken}`},
        params: {
          from, to, start, limit, search, cdmAccountId, cards: cardholdersScope?.toString(), pod
        }
      })
          .then((response) => response.data)
          .catch((err) => handleError(err))
    },

    downloadInvoice: (transactionId: string, cdmAccountId?:string)=> {
      cleanApiErrorMessage()
      if (!accessToken)
        return null;

      return axios.get("mriapi/v1/customer/invoicePdf/" + transactionId, {
        params: {cdmAccountId},
        headers: {Authorization: `Bearer ${accessToken}`},
        responseType: 'blob',
      })
          .then((response) => {
            if (response.status === 204) {
              const errorMessage= t("download.error.invoice") === "download.error.invoice" ? "There were some issues downloading your invoice, please try again later." : t("download.error.invoice")
              setApiErrorMessage(errorMessage, "")
            } else {
              const file = new Blob([response.data], {type: 'application/pdf'});
              download(file, transactionId, cdmAccountId);
            }
          })
          .catch((err) => handleError(err, transactionId))
    },

    downloadInvoicesList: (transactionIds: string[], cdmAccountId?:string) => {
      cleanApiErrorMessage()
      if(!accessToken || transactionIds.length === 0)
        return null;

      return axios.post("mriapi/v1/customer/invoicePdf/batch", {ids: transactionIds}, {
        params:{cdmAccountId},
        headers: {Authorization: `Bearer ${accessToken}`},
        responseType: 'blob'
      }).then((response) => {
        if (response.status === 204) {
          setApiErrorMessage("There was an error retrieving requested files, please try again later.", "")
        } else {
          if (response.headers['x-errors'] !== undefined && response.headers['x-errors'].length > 0) {
            const errorMessage=t("download.error.some.invoices") === "download.error.some.invoices" ? "There were some invoice that could not be downloaded" : t("download.error.some.invoices")
            setApiErrorMessage(errorMessage + ": " + response.headers['x-errors'], "")
          }
          const content = response.headers['content-type'];
          download(response.data, t("common.download") + ".zip", content)
        }
      })
          .catch((err) => handleError(err))
    },

    getSettings: (cardholderIds: string) => {
      cleanApiErrorMessage()
      if(!accessToken) return null;

      let config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      };

      if (cardholderIds) {
        config.params = {cardholderIds}
      }

      return axios.get("settingsapi/v1/settings/customer", config)
          .then((response) => response.data)
          .catch((err) => handleError(err))
    },

    saveSettings: (cardholderIds: string, mail: boolean, printout: boolean) => {
      cleanApiErrorMessage()
      if(!accessToken) return null;
      const config = { headers: {'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}`}};

      let body = [] as ICustomerSettings[];
      let customer;
      let customerSetting = [] as ICustomerSetting[];

      customer = parseCardholderForCustomerSettings(cardholderIds);

      const isPrintSettingActivated = hasCountryConfig(country, "INVOICE_PRINT_CONTROL");
      const isMailSettingActivated = hasCountryConfig(country, "INVOICE_MAIL_CONTROL");

      if (isMailSettingActivated) {
        let setting = {} as ISetting;
        setting.name = "MAIL_NOTIFICATION"
        customerSetting.push({setting: setting, value: mail})
      }
      if (isPrintSettingActivated) {
        let setting = {} as ISetting;
        setting.name = "PRINTOUT"
        customerSetting.push({setting: setting, value: printout})
      }

      body.push({customer: customer ? customer : {} as ICustomer, settings: customerSetting})

      return axios.put("settingsapi/v1/settings/customer", body, config)
          .then((response) => true)
          .catch((err) => {
            handleError(err)
            return false
          });
    },

    activatedSettings: (country: string) => {
      cleanApiErrorMessage()
      return axios.get("settingsapi/v1/settings/customer/activated/" + country)
          .then((response) => response.data)
          .catch((err) => handleError(err))
    },

    getFilteredCardholders: (setting: string, enabled: boolean, cdmAccountId?: string) => {
      cleanApiErrorMessage()
      if(!accessToken) return null;

      let config: AxiosRequestConfig = {
        params: {cdmAccountId, setting, enabled},
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      };

      return axios.get("settingsapi/v1/settings/customer/filter", config)
          .then((response) => response.data)
          .catch((err) => handleError(err))
    },

    overviewStartingDate: (country: string) => {
      cleanApiErrorMessage();
      if (!accessToken)
        return null;

      return axios.get("mriapi/v1/customer/overview/startdate/" + country, {
        headers: {Authorization: `Bearer ${accessToken}`},
      })
          .then((response) => response.data)
          .catch((err) => handleError(err))
    },

    overviewReportDownload: (year:number, month:number, locale:string, cdmAccountId?: string, cardholdersScope?: string[] | undefined) => {
      cleanApiErrorMessage();
      if (!accessToken)
        return null;

      function getFilename() {
        if (moment().year() === year && moment().month() + 1 === month)
          return year + "-" + month + " (1~" + moment().date() + ")";
        else
          return year + "-" + month;
      }

      return axios.get("mriapi/v1/customer/overview/download/" + year + "/" + month, {
        headers: {Authorization: `Bearer ${accessToken}`},
        responseType: 'blob',
        params: {
          locale, cdmAccountId, cards: cardholdersScope?.toString()
        }
      })
          .then((response) => {
            if(response.status === 204) {
              setOverviewReportEmpty(true)
            }
            else {
              const content = response.headers['content-type'];
              download(response.data, getFilename() + ".ods", content)
            }
          })
          .catch((err) => handleError(err))
    }
  }

  function handleError(error: any, transactionId?: string) {
    if (error.response?.status === 401) {
      let redirectUrl = window.location.origin + window.location.pathname
      redirectToIdamAuth(country, redirectUrl);
    } else if (error.response?.status === 404) {
      setInvoiceDownloadEmptyTransactionId(transactionId)
    } else {
      setApiErrorMessage(error.message, error.request?.responseURL?.toString());
    }
  }

  const contextValue: IApi = useMemo(() => contextValueObject, [accessToken, country]);

  return (
    <ApiContext.Provider value={contextValue}>
      {children}
    </ApiContext.Provider>
  )
}

export const useApi = () => useContext(ApiContext);

export default ApiContextProvider;