import React, {useState} from 'react'
import {BasicTableCell, BasicTableRow, Button, IconButton} from "@metro-ui/core";
import dateFormat from "dateformat";
import {Icons} from "@metro-ui/core/es6/icons/allAtOnce";
import InvoiceRowPod from "./InvoiceRowPod";
import {INVOICE_DATE_FORMAT} from "../../page/InvoicePage";
import {useApi} from "../../context/ApiContext";
import {useTranslation} from "react-i18next";
import {InvoiceRowProp} from "./InvoiceRowProps";
import {formatCurrencyForCountry, getInvoiceTypeTranslationKey} from "../../utils";
import {triggerCustomEventGoogleAnalytics360} from "../../ga360";
import styled, {css} from "styled-components";
import {useGeneralContext} from "../../context/GeneralContext";
import InvoiceRowArticleList from "./InvoiceRowArticleList";
import {highlightSearchTerm} from "./InvoiceUtils";

const StyledBasicTableCell = styled(BasicTableCell)<{ downloaded: boolean }>`
  font-weight: bold;
  opacity: 100%;
  ${p => p.downloaded && css`
    font-weight: normal;
    opacity: 60%;
  `}
`
const InvoiceRow = ({
                      invoice,
                      singleRowSelectionStatusUpdate,
                      tableRowSelectionStatus,
                      downloadingTransactionId,
                      setDownloadingTransactionId,
                      podInvoiceExists,
                      anySearchOrDownloadInProgress,
                      index
                    }: InvoiceRowProp) => {
  const {downloadInvoice} = useApi()
  const {t} = useTranslation();
  const [expandPod, setExpandPod] = useState<boolean>(false);
  const {selectedCardAndBusiness, searchValue, invoiceDownloadEmptyTransactionId} = useGeneralContext()
  const cdmAccountId = selectedCardAndBusiness?.businessId !== "personal" ? selectedCardAndBusiness?.businessId : undefined
  const downloaded = invoice.downloaded
  return <>
    <BasicTableRow
        key={invoice.transactionId}
        checkbox={{
          id: invoice.transactionId,
          name: invoice.transactionId,
          disabled: false,
          onChange: () => singleRowSelectionStatusUpdate(invoice.transactionId, invoice.podInvoice?.transactionId),
          isChecked: tableRowSelectionStatus[invoice.transactionId] > 0
        }}
        data-testid='tableRow'
    >
      <StyledBasicTableCell downloaded={downloaded} doesNotWrap={true} data-testid='tableCell'>
        {dateFormat(invoice.invoiceTimestamp * 1000, INVOICE_DATE_FORMAT, true, false)}
      </StyledBasicTableCell>
      <StyledBasicTableCell downloaded={downloaded} doesNotWrap={true} data-testid='tableCell'>
          {highlightSearchTerm(invoice.invoiceNumber, searchValue)}
      </StyledBasicTableCell>
      <StyledBasicTableCell downloaded={downloaded} doesNotWrap={true} data-testid='tableCell'>
        {t(getInvoiceTypeTranslationKey(invoice.customerInvoiceType))}
      </StyledBasicTableCell>
      <StyledBasicTableCell downloaded={downloaded} doesNotWrap={true} isAlignedRight={true} data-testid='tableCell'>
          {formatCurrencyForCountry(invoice.netAmount, invoice.customerCountryCode, invoice.currency)}
      </StyledBasicTableCell>
      <StyledBasicTableCell downloaded={downloaded} doesNotWrap={true} isAlignedRight={true} data-testid='tableCell'>
          {formatCurrencyForCountry(invoice.totalAmount, invoice.customerCountryCode, invoice.currency)}
      </StyledBasicTableCell>
      <BasicTableCell doesNotWrap={true} isAlignedRight={true}>
        <Button
            Icon={Icons["Download"]}
            variant={"outline"}
            size="small"
            isWaiting={downloadingTransactionId === invoice.transactionId}
            isDisabled={anySearchOrDownloadInProgress || invoice.transactionId === invoiceDownloadEmptyTransactionId}
            onClick={() => {
              setDownloadingTransactionId(invoice.transactionId)
              downloadInvoice(invoice.transactionId, cdmAccountId)?.then(() =>
                  setDownloadingTransactionId(""));
              triggerCustomEventGoogleAnalytics360("download invoice")
            }}
            data-testid='downloadPdfButton'
        >
          {invoice.transactionId === invoiceDownloadEmptyTransactionId ? t("page.overview.button.empty") : t("common.downloadPdf")}
        </Button>
      </BasicTableCell>
      {podInvoiceExists && <BasicTableCell>
        {invoice.podInvoice &&
        <>
          {t("common.payment")}
          <IconButton
              Icon={Icons[expandPod ? 'ChevronUpSmall' : 'ChevronDownSmall']} label={'expand'}
              hasNoInteractionEffect
              onClick={() => {
                setExpandPod(!expandPod)
                triggerCustomEventGoogleAnalytics360("expand POD")
              }}
              style={{"verticalAlign":"middle"}}
              data-testid='podButton'
          />
        </>}
      </BasicTableCell>}
    </BasicTableRow>
      {expandPod && invoice.podInvoice &&
          <InvoiceRowPod
              podInvoice={invoice.podInvoice}
              anySearchOrDownloadInProgress={anySearchOrDownloadInProgress}
              downloadingTransactionId={downloadingTransactionId}
              setDownloadingTransactionId={setDownloadingTransactionId}
          />}
      {invoice.searchedArticles && invoice.searchedArticles.length !== 0 &&
          <InvoiceRowArticleList
              searchedArticles={invoice.searchedArticles}
              even={index % 2 === 0}
              downloaded={invoice.downloaded}
          />}
  </>
}
export default InvoiceRow;