import React, {useEffect, useState} from 'react';
import {ICustomer, ICustomerInvoice, IInvoiceResponse, useApi} from "../context/ApiContext";
import {useTranslation} from "react-i18next";
import {
  BasicTableBody,
  BasicTableHead,
  BasicTableHeadCell,
  BasicTableResponsive,
  Button,
  Heading,
  Separator,
  Text
} from '@metro-ui/core';
import {Icons} from "@metro-ui/core/es6/icons/allAtOnce";
import styled from "styled-components";
import SearchForm from "../component/SearchForm";
import {ICard, useGeneralContext} from "../context/GeneralContext";
import {useIdamAuthContext} from "../context/IdamAuthContext";
import InvoiceRow from "../component/invoice/InvoiceRow"
import {hasCountryConfig} from "../utils";
import InvoiceMobileViewRow from "../component/invoice/InvoiceMobileViewRow";
import {triggerCustomEventGoogleAnalytics360} from "../ga360";
import InvoiceLimitDropDownList from "../component/InputField/InvoiceLimitDropDownList";
import {useNavigate} from "react-router-dom";
import {pages} from "../App";
import _ from "lodash";

const SubContainer = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 575px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  @media (max-width: 575px) {
    justify-content: flex-end
  }
`;

const DesktopViewContainer = styled.div`
  @media (max-width: 575px) {
      display: none;
  }
`;

const MobileViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media (min-width: 576px) {
      display: none;
  }
`;

interface IInvoicesState {
  pageIndex: number
  limit: number
  invoicesNumber: number

  invoices: ICustomerInvoice[] | undefined
  tableRowSelectionStatus: { [key: string]: number }

  downloadingTransactionId: string
  multipleFilesDownloadInProgress: boolean
  nextPageSearchInProgress: boolean
  prevPageSearchInProgress: boolean
  searchInProgress: boolean

  startDate: Date | undefined
  endDate: Date | undefined
  searchPattern: string | undefined
}

const defaultInvoicesStateValue: IInvoicesState = {
  pageIndex: 0,
  limit: 10,
  invoicesNumber: 0,

  invoices: undefined,
  tableRowSelectionStatus: {},
  downloadingTransactionId: "",

  multipleFilesDownloadInProgress: false,
  nextPageSearchInProgress: false,
  prevPageSearchInProgress: false,
  searchInProgress: false,

  startDate: undefined,
  endDate: undefined,
  searchPattern: undefined,
}

export const INVOICE_DATE_FORMAT = "dd/mm/yyyy, HH:MM";

function InvoicePage() {
  const [invoicesState, setInvoicesState] = useState<IInvoicesState>(defaultInvoicesStateValue);
  const api = useApi()
  const {t} = useTranslation();
  const {isAppReady, country, resolveCardsList, alexCssBusinessCards, cardFromIdamToken, selectedCardAndBusiness, impersonatedCard, invoiceLimit} = useGeneralContext();
  const {isImpersonationMode} = useIdamAuthContext();
  const navigate = useNavigate();
  const isFlyoutActivated = hasCountryConfig(country, "FLYOUT_METROX");
  const cdmAccountId = selectedCardAndBusiness?.businessId !== "personal" ? selectedCardAndBusiness?.businessId : undefined
  const [cardsDisabledPrintSuppression, setCardsDisabledPrintSuppression] = useState<ICustomer[]>([])

  const hasCards = (cardsList: ICard[]) => cardsList.length > 0 || (isImpersonationMode && impersonatedCard !== undefined)

  const hasActivatedBusiness = () => isImpersonationMode || !isFlyoutActivated || selectedCardAndBusiness?.businessId

  const resolveCardholderScope = (param: string | undefined) => {
    if(param)
      return [param]
    return undefined;
  };

  function resolveCdmAccountId() {
    if (selectedCardAndBusiness) {
      const selectedAccount = alexCssBusinessCards?.find(card => card.legitimations.find(l => l === selectedCardAndBusiness?.cardholderId))
      if (selectedAccount?.b2C) {
        return 'personal';
      } else {
        return selectedCardAndBusiness.businessId;
      }
    }

    return undefined;
  }

  const getInvoices = (
    requestedPageIndex: number,
    PgeSize: number,
    startDate: Date | undefined,
    endDate: Date | undefined,
    searchPattern: string | undefined,
    actionSource: string
  ) => {
    const cardsList = resolveCardsList()
    if (!hasCards(cardsList) || !hasActivatedBusiness())
      return;

    let cardholdersScope: string[] | undefined = resolveCardholderScope(isImpersonationMode ? impersonatedCard : selectedCardAndBusiness?.cardholderId);
    let cdmAccountId: string|undefined = resolveCdmAccountId();

    switch (actionSource) {
      case "SEARCH":
        setInvoicesState({...invoicesState, searchInProgress: true});
        invoicesState.searchInProgress = false;
        break;
      case "PREV":
        setInvoicesState({...invoicesState, prevPageSearchInProgress: true});
        window.scrollTo(0, 0)
        invoicesState.nextPageSearchInProgress = false;
        break;
      case "NEXT":
        setInvoicesState({...invoicesState, nextPageSearchInProgress: true});
        window.scrollTo(0, 0)
        invoicesState.prevPageSearchInProgress = false;
        break;
    }

    const offset = requestedPageIndex * PgeSize;
    api?.getInvoices(startDate, endDate, offset, invoiceLimit, searchPattern,
        cdmAccountId, cardholdersScope)?.then((response: IInvoiceResponse) => {
      const invoicesNumber = response.numFound
      if (invoicesNumber > 0) {
        const invoices: ICustomerInvoice[] = response._embedded.customerInvoices
        invoicesState.limit = response.limit
        invoicesState.tableRowSelectionStatus = {}
        invoices.forEach((invoice) => {
          invoicesState.tableRowSelectionStatus[invoice.transactionId] = 0
          if (invoice.podInvoice)
            invoicesState.tableRowSelectionStatus[invoice.podInvoice.transactionId] = 0
        })
        invoicesState.invoices = invoices
      }
      invoicesState.invoicesNumber = invoicesNumber
      invoicesState.pageIndex = requestedPageIndex
      invoicesState.startDate = startDate
      invoicesState.endDate = endDate
      invoicesState.searchPattern = searchPattern
      setInvoicesState({...invoicesState})
    }).catch((err) => {
      invoicesState.invoicesNumber = 0
      invoicesState.pageIndex = requestedPageIndex
      invoicesState.startDate = startDate
      invoicesState.endDate = endDate
      invoicesState.searchPattern = searchPattern
      setInvoicesState({...invoicesState})
    })
  }

  useEffect(()=>{
    if(isAppReady && !isImpersonationMode && invoicesState.invoicesNumber > 0 && !resolveCardsList().length)
    {
      invoicesState.invoicesNumber = 0
      setInvoicesState({...invoicesState})
    }
  },[isAppReady, invoicesState, isImpersonationMode, alexCssBusinessCards, cardFromIdamToken, selectedCardAndBusiness])

  useEffect(() => {
    if(cdmAccountId && hasCountryConfig(country, "SETTING_PAGE_ACTIVATED_COUNTRIES"))
      api.getFilteredCardholders("PRINTOUT", true, cdmAccountId)?.then(response => setCardsDisabledPrintSuppression(response))
  }, [cdmAccountId]);

  const singleRowSelectionStatusUpdate = (transactionId: string, podTransactionId?: string) => {
    invoicesState.tableRowSelectionStatus[transactionId] = invoicesState.tableRowSelectionStatus[transactionId] ? 0 : 1
    if(podTransactionId) {
      if (invoicesState.tableRowSelectionStatus[transactionId])
        invoicesState.tableRowSelectionStatus[podTransactionId] += 1
      else
        invoicesState.tableRowSelectionStatus[podTransactionId] -= 1
    }
    setInvoicesState({...invoicesState});
  }

  let allRowsSelected = false
  const tableRowSelectionStatus = Object.values(invoicesState.tableRowSelectionStatus);

  if (tableRowSelectionStatus.length !== 0) {
    allRowsSelected = Object.values(invoicesState.tableRowSelectionStatus).every((v) => v)
  }

  const allRowsSelectionStatusUpdate = () => {
    if (allRowsSelected) {
      Object.keys(invoicesState.tableRowSelectionStatus).forEach((id) => invoicesState.tableRowSelectionStatus[id] = 0)
    } else {
      Object.keys(invoicesState.tableRowSelectionStatus).forEach((id) => invoicesState.tableRowSelectionStatus[id] = 0)
      invoicesState.invoices?.forEach((invoice) => {
        invoicesState.tableRowSelectionStatus[invoice.transactionId] = 1;
        if(invoice.podInvoice) {
          invoicesState.tableRowSelectionStatus[invoice.podInvoice.transactionId] += 1;
        }
      })
    }
    setInvoicesState({...invoicesState});
  }

  const downloadSelectedInvoices = () => {
    setInvoicesState({...invoicesState, multipleFilesDownloadInProgress: true})
    let invoicesList: string[] = Object.keys(invoicesState.tableRowSelectionStatus).filter((id) => invoicesState.tableRowSelectionStatus[id]);
    if (!invoicesList || invoicesList.length === 0)
      return
    api?.downloadInvoicesList(invoicesList, cdmAccountId)?.then(() => setInvoicesState({
      ...invoicesState,
      multipleFilesDownloadInProgress: false
    }))
    triggerCustomEventGoogleAnalytics360("download multiple invoices", `${invoicesList.length}`)
  }

  const someRowsSelected = Object.values(invoicesState.tableRowSelectionStatus).some((v) => v)

  let headerChecked: boolean | undefined = false;
  if (allRowsSelected) {
    headerChecked = true;
  } else if (someRowsSelected) {
    headerChecked = undefined; // some rows checked
  }

  let anySearchOrDownloadInProgress = invoicesState.multipleFilesDownloadInProgress
    || invoicesState.nextPageSearchInProgress
    || invoicesState.prevPageSearchInProgress
    || invoicesState.searchInProgress
    || !!invoicesState.downloadingTransactionId;

  const searchCallback = (
    startDate: Date | undefined,
    endDate: Date | undefined,
    searchPattern: string | undefined,
  ) => {
    getInvoices(
      0,
      0,
      startDate,
      endDate,
      searchPattern,
      "SEARCH"
    )
  }

  const renderTableControls = () => (
      <SubContainer>
        <Button
            Icon={Icons["Download"]}
            variant={"secondary"}
            size="small"
            isDisabled={!someRowsSelected || anySearchOrDownloadInProgress}
            isWaiting={invoicesState.multipleFilesDownloadInProgress}
            onClick={() => downloadSelectedInvoices()}
            data-testid='downloadSelectedInvoicesButton'
        >
          {t("common.downloadSelected")}
        </Button>
        <PaginationContainer>
          <InvoiceLimitDropDownList/>
          <Button
              Icon={Icons["ArrowLeft"]}
              variant={"secondary"}
              size="small"
              isDisabled={invoicesState.pageIndex === 0 || anySearchOrDownloadInProgress}
              isWaiting={invoicesState.prevPageSearchInProgress}
              onClick={() => {
                getInvoices(
                    invoicesState.pageIndex - 1,
                    invoicesState.limit,
                    invoicesState.startDate,
                    invoicesState.endDate,
                    invoicesState.searchPattern,
                    "PREV")
                triggerCustomEventGoogleAnalytics360("page change", "previous");
              }}
              data-testid='prevPageButton'
          >
            {t("common.previous")}
          </Button>
          <Button
              Icon={Icons["ArrowRight"]}
              variant={"secondary"}
              size="small"
              isDisabled={invoicesState.pageIndex + 1 >= Math.ceil(invoicesState.invoicesNumber / invoicesState.limit) || anySearchOrDownloadInProgress}
              isWaiting={invoicesState.nextPageSearchInProgress}
              onClick={() => {
                getInvoices(
                    invoicesState.pageIndex + 1,
                    invoicesState.limit,
                    invoicesState.startDate,
                    invoicesState.endDate,
                    invoicesState.searchPattern,
                    "NEXT")
                triggerCustomEventGoogleAnalytics360("page change", "next");
              }}
              data-testid='nextPageButton'
          >
            {t("common.next")}
          </Button>
        </PaginationContainer>
      </SubContainer>
  );

  return (
    <>
      {((!isImpersonationMode && !_.isEmpty(resolveCardsList())) || (isImpersonationMode && impersonatedCard !== undefined)) &&

        <>
          <Heading>{t("page.invoices.listTitle")}</Heading>
          { cardsDisabledPrintSuppression.length > 0 && t("deactivate.invoice.printing.button") != "deactivate.invoice.printing.button" && t("deactivate.invoice.printing.description") != "deactivate.invoice.printing.description" ?
            <>
              <Text>{t("deactivate.invoice.printing.description")}</Text>
              <Button
              variant={'primary'}
              size={'small'}
              data-testid="printSuppressionButton"
              onClick={() => {
                triggerCustomEventGoogleAnalytics360("print suppression redirect")
                navigate(pages.settings.path)
              }}>
                {t("deactivate.invoice.printing.button")}
              </Button>
            </> : null
          }
          <Separator/>
          <SearchForm
            searchCallback={searchCallback}
            isDisabled={anySearchOrDownloadInProgress}
            isWaiting={invoicesState.searchInProgress}
          />
          {invoicesState.invoicesNumber > 0 && renderTableControls()}
          <DesktopViewContainer>
            <BasicTableResponsive>
            <BasicTableHead checkbox={{
              id: 'headId',
              name: 'headName',
              disabled: false,
              onChange: () => allRowsSelectionStatusUpdate(),
              isChecked: headerChecked,
              isDisabled: invoicesState.invoicesNumber === 0,
            }}
              data-testid='tableHead'
            >
              <BasicTableHeadCell>
                {t("common.date")}
              </BasicTableHeadCell>
              <BasicTableHeadCell>
                {t("common.invoiceNumber")}
              </BasicTableHeadCell>
              <BasicTableHeadCell>
                {t("common.type")}
              </BasicTableHeadCell>
              <BasicTableHeadCell isAlignedRight={true}>
                {t("common.netAmount")}
              </BasicTableHeadCell>
              <BasicTableHeadCell isAlignedRight={true}>
                {t("common.totalAmount")}
              </BasicTableHeadCell>
              <BasicTableHeadCell>
              </BasicTableHeadCell>
              {invoicesState.invoices?.find(invoice=>invoice.podInvoice) && <BasicTableHeadCell/>}
            </BasicTableHead>
            <BasicTableBody data-testid='tableBody'>
              {invoicesState.invoicesNumber > 0 && invoicesState.invoices?.map((invoice, index) => (
                <InvoiceRow
                    index={index}
                    key={invoice.transactionId}
                    invoice={invoice}
                    singleRowSelectionStatusUpdate={singleRowSelectionStatusUpdate}
                    tableRowSelectionStatus={invoicesState.tableRowSelectionStatus}
                    downloadingTransactionId={invoicesState.downloadingTransactionId}
                    setDownloadingTransactionId={(downloadingTransactionId: string)=>setInvoicesState({
                      ...invoicesState, downloadingTransactionId: downloadingTransactionId})}
                    podInvoiceExists={!!invoicesState.invoices?.find(invoice=>invoice.podInvoice)}
                    anySearchOrDownloadInProgress={anySearchOrDownloadInProgress}
                />
              ))}
            </BasicTableBody>
          </BasicTableResponsive>
        </DesktopViewContainer>
          <MobileViewContainer>
            {invoicesState.invoicesNumber > 0 && invoicesState.invoices?.map((invoice, index) => (
                <InvoiceMobileViewRow
                    index={index}
                    key={invoice.transactionId}
                    invoice={invoice}
                    singleRowSelectionStatusUpdate={singleRowSelectionStatusUpdate}
                    tableRowSelectionStatus={invoicesState.tableRowSelectionStatus}
                    downloadingTransactionId={invoicesState.downloadingTransactionId}
                    setDownloadingTransactionId={(downloadingTransactionId: string) => setInvoicesState({
                      ...invoicesState, downloadingTransactionId: downloadingTransactionId
                    })}
                    podInvoiceExists={!!invoicesState.invoices?.find(invoice => invoice.podInvoice)}
                    anySearchOrDownloadInProgress={anySearchOrDownloadInProgress}
                />
            ))}
          </MobileViewContainer>
          {invoicesState.invoicesNumber > 0 ? renderTableControls()
            :
            <SubContainer style={{justifyContent: 'center'}}>
              {t("common.noResults")}
            </SubContainer>
          }
        </>
      }
    </>
  );
}

export default InvoicePage;
