import {getCountryConfig, hasCountryConfig, resolveCountry} from "./utils";
import {initializeGoogleAnalytics360, triggerGoogleAnalytics360} from "./ga360";
import {initializeGoogleAnalytics4} from "./ga4";
import Cookies from "js-cookie";

const country = resolveCountry().toUpperCase();
const gtm360Id = getCountryConfig(country, "GOOGLE_ANALYTICS_360_CONTAINER_ID")
const gtmGa4Id = getCountryConfig(country, "GOOGLE_ANALYTICS_GA4_CONTAINER_ID")

export const triggerGoogleAnalytics = (
    pageName: string,
    languageTag: string,
    storeId: string,
    responseStatus: number,
    customerRole: string
) => {
    if (
        !window.location.href.includes("localhost") &&
        hasCountryConfig(country, "GOOGLE_ANALYTICS_ACTIVATED_COUNTRIES") &&
        Cookies.get("allowedCookieCategories")?.includes("performance")
    ) {
        if (gtm360Id) {
            triggerGoogleAnalytics360(pageName, languageTag, storeId, responseStatus, customerRole);
        }
    }
}

export const loadGoogleAnalytics = (
    pageName: string,
    languageTag: string,
    storeId: string,
    responseStatus: number,
    customerRole: string
) => {
    if (
        !window.location.href.includes("localhost") &&
        hasCountryConfig(country, "GOOGLE_ANALYTICS_ACTIVATED_COUNTRIES") &&
        Cookies.get("allowedCookieCategories")?.includes("performance")
    ) {
        if (gtm360Id) {
            initializeGoogleAnalytics360(pageName, languageTag, storeId, responseStatus, customerRole);
        }
        if (gtmGa4Id) {
            initializeGoogleAnalytics4();
        }
    }
}
