import {getCountryConfig} from "./utils";
import {MakroLogo, MetroLogo, MetroLogoTr} from "@metro-ui/core/es6/icons";

export function getCountryLogo(country: string) {
  const logo = getCountryConfig(country, "LOGO");
  switch (logo) {
    case "makro": {
      return MakroLogo;
    }
    case "metroTr": {
      return MetroLogoTr;
    }
    default: {
      return MetroLogo;
    }
  }
}

export function getCountryApp(country: string) {
  return getCountryConfig(country, "LOGO");
}