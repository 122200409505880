import GeneralContextProvider from "./GeneralContext";
import React, {ReactNode} from "react";
import IdamAuthProvider from "./IdamAuthContext";
import ApiContextProvider from "./ApiContext";

const ContextsLayer = (props: { children: ReactNode }) => {
  return (<>
    <GeneralContextProvider>
      <IdamAuthProvider>
        <ApiContextProvider>
          {props.children}
        </ApiContextProvider>
      </IdamAuthProvider>
    </GeneralContextProvider>
  </>)
}
export default ContextsLayer;