import {Layout, Separator, Text} from "@metro-ui/core";
import React from "react";
import {IArticle} from "../../context/ApiContext";
import {useTranslation} from "react-i18next";
import styled, {css} from "styled-components";
import {useGeneralContext} from "../../context/GeneralContext";
import {highlightSearchTerm} from "./InvoiceUtils";

const StyledText = styled(Text)<{ downloaded: boolean, flex: number}>`
  font-weight: bold;
  opacity: 100%;
  ${p => p.downloaded && css`
    font-weight: normal;
    opacity: 60%;
  `}
  flex: ${(p) => p.flex}
`
function InvoiceRowArticleList ({searchedArticles, even, downloaded} : {searchedArticles: IArticle[], even: boolean, downloaded: boolean}) {
    const {t} = useTranslation()
    const {searchValue, country} = useGeneralContext()
    const limitedSearchCountries = process.env.REACT_APP_LIMITED_SEARCH_FIELD_ACTIVATED_COUNTRIES as string;
    const isLimitedSearchCountry = limitedSearchCountries.includes(country.toUpperCase());

    return (
        <>
        <td style={{ backgroundColor: even ? "white" : "#f2f3f5"}}/>
            <td colSpan={6} style={{ backgroundColor: even ? "white" : "#f2f3f5"}}>
                <Layout direction="column">
                    <Layout direction="row" style={{ display: "flex"}}>
                        <StyledText downloaded={downloaded} flex={1}>{t('page.invoices.articles.table.MAN')}</StyledText>
                        <StyledText downloaded={downloaded} flex={1}>{t('page.invoices.articles.table.EAN')}</StyledText>
                        <StyledText downloaded={downloaded} flex={3.2}>{t('page.invoices.articles.table.description')}</StyledText>
                    </Layout>
                    <Separator style={{margin: 0}}/>
                    <Layout direction="column">
                        {searchedArticles.map(article => {
                            const content = article.szGTIN ? article.szGTIN : article.szItemInputString;
                            return (
                            <Layout key={article.lItemNumber} direction="row" style={{ display: "flex"}}>
                                <StyledText downloaded={downloaded} flex={1}>{highlightSearchTerm(article.lItemNumber, searchValue)}</StyledText>
                                <StyledText downloaded={downloaded} flex={1}>{isLimitedSearchCountry ? content : highlightSearchTerm(content, searchValue)}</StyledText>
                                <StyledText downloaded={downloaded} flex={3.2}>{highlightSearchTerm(article.szItemDesc, searchValue)}</StyledText>
                            </Layout>)})}
                    </Layout>
                </Layout>
            </td>
        </>
    )
}

export default InvoiceRowArticleList;