import {useGeneralContext} from "../../context/GeneralContext";
import {Label, SelectNative, SelectOption} from "@metro-ui/core";
import {useTranslation} from "react-i18next";
import React, {ChangeEvent} from "react";
import {triggerCustomEventGoogleAnalytics360} from "../../ga360";
import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  @media (max-width: 575px) {
    flex-direction: column;
  }
`;

const InvoiceLimitDropDownList = () => {
    const {invoiceLimit, setInvoiceLimit} = useGeneralContext()
    const {t} = useTranslation();

    return(
            <MainContainer>
                <Label htmlFor={""}>
                    { t("invoices.per.page.filter") !== "invoices.per.page.filter" ?
                        t("invoices.per.page.filter") : "Invoices/Page"}
                </Label>
                <SelectNative
                    id='invoiceLimitId'
                    data-testid='invoiceLimitTest'
                    onChange={(
                        event: ChangeEvent<HTMLSelectElement>,
                        value: string
                    ) => {
                        setInvoiceLimit(parseInt(value))
                        triggerCustomEventGoogleAnalytics360("number of invoices/page changed", value)
                    }}
                    name='invoiceLimitSelector'
                    value={invoiceLimit.toString()}
                >
                    <SelectOption value='10' key="limit10" data-testid='limit10'>
                        10
                    </SelectOption>
                    <SelectOption value='25' key="limit25" data-testid='limit25'>
                        25
                    </SelectOption>
                    <SelectOption value='50' key="limit50" data-testid='limit50'>
                        50
                    </SelectOption>
                    <SelectOption value='100' key="limit100" data-testid='limit100'>
                        100
                    </SelectOption>
                </SelectNative>
            </MainContainer>
    )
}

export default InvoiceLimitDropDownList;