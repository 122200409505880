import {InvoiceRowPodProp} from "./InvoiceRowProps";
import {useApi} from "../../context/ApiContext";
import {useTranslation} from "react-i18next";
import React from "react";
import {Button, Heading, Separator} from "@metro-ui/core";
import {capitalizeFirstLetter, getInvoiceTypeTranslationKey} from "../../utils";
import {MobileViewTableSubRow} from "./MobileViewTableSubRow";
import dateFormat from "dateformat";
import {INVOICE_DATE_FORMAT} from "../../page/InvoicePage";
import {Icons} from "@metro-ui/core/es6/icons/allAtOnce";
import {triggerCustomEventGoogleAnalytics360} from "../../ga360";
import {useGeneralContext} from "../../context/GeneralContext";

const InvoiceMobileViewRowPod = ({ podInvoice, downloadingTransactionId, anySearchOrDownloadInProgress, setDownloadingTransactionId }: InvoiceRowPodProp) => {
  const {downloadInvoice} = useApi()
  const {t} = useTranslation();
  const {selectedCardAndBusiness} = useGeneralContext()
  const cdmAccountId = selectedCardAndBusiness?.businessId !== "personal" ? selectedCardAndBusiness?.businessId : undefined

  return <div
    style={{
      display: 'flex',
      flexDirection: 'column'
    }}
  >
    <Heading
      variant="h3"
      color="primary"
      hasNoNativeMargin={true}
      style={{
        marginBottom: '5px',
        marginTop: '5px'
      }}
    >
      {capitalizeFirstLetter(t(getInvoiceTypeTranslationKey(podInvoice.customerInvoiceType)))}
    </Heading>
    <Separator style={{
      marginBottom: '5px',
      marginTop: '5px'
    }}/>
          {MobileViewTableSubRow(t("common.date"), dateFormat(podInvoice.invoiceTimestamp * 1000, INVOICE_DATE_FORMAT), podInvoice.downloaded)}
          {MobileViewTableSubRow(t("common.invoiceNumber"), podInvoice.invoiceNumber, podInvoice.downloaded)}
    <Button
      Icon={Icons["Download"]}
      variant={"outline"}
      size="small"
      isWaiting={downloadingTransactionId === podInvoice.transactionId}
      isDisabled={anySearchOrDownloadInProgress}
      onClick={() => {
        setDownloadingTransactionId(podInvoice.transactionId)
        downloadInvoice(podInvoice.transactionId, cdmAccountId )?.then(() => {
          setDownloadingTransactionId("")
        });
        triggerCustomEventGoogleAnalytics360("download POD invoice")
      }}
      data-testid='downloadPodMobileButton'
    >
      {t("common.downloadPdf")}
    </Button>
  </div>
}

export default InvoiceMobileViewRowPod;