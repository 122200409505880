import React from "react";
import FooterMetroX from "./metrox/FooterMetroX";

function Footer() {
    return (
        <div data-testid="FooterMetroX">
          <FooterMetroX/>
        </div>
    )
}

export default Footer;