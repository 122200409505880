import React from "react"
import '../../locales'
import {FormField, Input} from "@metro-ui/core"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import {useGeneralContext} from "../../context/GeneralContext"
import {getCountryConfig} from "../../utils"

const DateInputField = (
  {
    title,
    value,
    setValue,
    minDate,
    maxDate
  }: { title: string, value: Date, setValue: (date : Date) => void , minDate?: Date, maxDate?: Date}
) => {
  const {country} = useGeneralContext()
  let dateFormat = getCountryConfig(country, "DATE_FORMAT");

  return (
    <FormField
      htmlFor="id"
      label={title}
      data-testid="DateInputFieldFormField"
    >
      <DatePicker
        selected={value}
        dateFormat={dateFormat}
        locale={country}
        onChange={(date) => {
          date instanceof Date && setValue(date)
        }}
        customInput={
          <Input
            key={title}
            id={title}
            type="text"
            data-testid="DateInputFieldInput"
            name={title}
            style={{textAlign: "center"}}
          />}
        minDate={minDate}
        maxDate={maxDate}
      />
    </FormField>
  )
}

export default DateInputField;