import moment from "moment";
import {BasicTableCell, BasicTableRow, Button, FormField, Text} from "@metro-ui/core";
import {Icons} from "@metro-ui/core/es6/icons/allAtOnce";
import React from "react";
import {useTranslation} from "react-i18next";

export interface ReportBasicTableRowProps {
    isReady: boolean;
    isDisabled: boolean;
    isWaiting?: boolean;
    momentDate: moment.Moment;
    onClickDownload?: (date: moment.Moment) => void
    buttonText: string;
}

const ReportBasicTableRow = ({isReady, isDisabled, isWaiting, momentDate, onClickDownload, buttonText}: ReportBasicTableRowProps) => {
    const {t} = useTranslation();
    const rowId = (momentDate.year() + "_" + momentDate.month())
    const currentMonth = momentDate.month()
    const futureMonth = moment().month(currentMonth + 1).format('MMMM');
    const currentYear = momentDate.year()
    const monthYear = momentDate.format('MMMM YYYY')

    const onDownload = () => {
        if (isReady && !isDisabled && !isWaiting && onClickDownload !== undefined) {
            onClickDownload(momentDate);
        }
    }

    return (
        <BasicTableRow key={"key" + rowId} data-testid='OverviewRow'>
            <BasicTableCell doesNotWrap={true} isAlignedRight={false}>
                <FormField htmlFor={"id" + rowId} label="" shouldHideLabel>
                    <span>{monthYear}</span>
                    {isReady ?
                        <Text color="brand-primary" size="small" element="span">
                            {t("page.overview.availableUntil")} {`${futureMonth} ${currentYear + 1}`}
                        </Text>
                        :
                        <Text color="danger" size="small" element="span">
                            {t("page.overview.availableAt")} {monthYear}
                        </Text>
                    }
                </FormField>
            </BasicTableCell>
            <BasicTableCell doesNotWrap={true} isAlignedRight={true}>
                <Button
                    Icon={Icons["Download"]}
                    variant={"outline"}
                    size="small"
                    isDisabled={isDisabled}
                    isWaiting={isWaiting}
                    onClick={onDownload}
                    data-testid='OverviewRowDownloadButton'
                >
                    {buttonText}
                </Button>
            </BasicTableCell>
        </BasicTableRow>
    )
};

export default ReportBasicTableRow;