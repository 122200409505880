import React, {useEffect, useState} from 'react';
import {
  BasicTableBody,
  BasicTableHead,
  BasicTableHeadCell,
  BasicTableResponsive,
  Description,
  Heading,
  Separator
} from "@metro-ui/core";
import {useTranslation} from "react-i18next";
import {ISelectedCardAndBusiness, useGeneralContext} from "../context/GeneralContext";
import {pages} from "../App";
import {useNavigate} from "react-router-dom";
import {hasCountryConfig} from "../utils";
import {useApi} from "../context/ApiContext";
import moment from "moment";
import CardholderDropDownList from "../component/InputField/CardholderDropDownList";
import {useIdamAuthContext} from "../context/IdamAuthContext";
import {triggerCustomEventGoogleAnalytics360} from "../ga360";
import ReportBasicTableRow from "../component/report/ReportBasicTableRow";

const NO_DOWNLOAD_IN_PROGRESS = "";

function OverviewPage() {
  const [currentDownloadRowId, setCurrentDownloadRowId] = useState<string>(NO_DOWNLOAD_IN_PROGRESS)
  const {t} = useTranslation();
  const {isAppReady, country, overviewDateList, overviewReportEmpty,
    setOverviewReportEmpty, resolveCardsList, alexCssBusinessCards, selectedCardAndBusiness,
    impersonatedCard, setOverviewDateList, language, customerRole} = useGeneralContext();
  const {isImpersonationMode} = useIdamAuthContext();
  const api = useApi();
  const navigate = useNavigate();
  const currentDate = moment();
  const cardsList = resolveCardsList()
  const [dropdownCard, setDropdownCard] = useState(selectedCardAndBusiness)
  const [disabledRows, setDisabledRows] = useState<string[]>([])

  const checkIfB2CAccount = () => {
    return alexCssBusinessCards?.length == 1 && alexCssBusinessCards?.some((element) => element.b2C);
  }

  let cardholdersScope: string[]|undefined;
  let cdmAccountId: string|undefined;

  if (isImpersonationMode) {
    // @ts-ignore
    cardholdersScope = [impersonatedCard]
    cdmAccountId = undefined;
  } else if (dropdownCard?.cardholderId) {
    cardholdersScope = [dropdownCard?.cardholderId]
    if (checkIfB2CAccount()) {
      cdmAccountId = undefined;
    } else cdmAccountId = dropdownCard?.businessId;
  } else {
    cardholdersScope = cardsList.length ? [cardsList[0]?.cardHolderID] : undefined
    if (checkIfB2CAccount()) {
      cdmAccountId = undefined;
    }
    else cdmAccountId = dropdownCard?.businessId;
  }

  const triggerDownload = (momentDate: moment.Moment) => {
    setOverviewReportEmpty(false)
    const rowId = (momentDate.year() + "_" + momentDate.month())
    setCurrentDownloadRowId(rowId)

    customerRole === "ADMIN" ?
      api?.overviewReportDownload(momentDate.year(), momentDate.month() + 1, language, cdmAccountId, undefined)?.then(() => setCurrentDownloadRowId(NO_DOWNLOAD_IN_PROGRESS)) :
      api?.overviewReportDownload(momentDate.year(), momentDate.month() + 1, language, cdmAccountId, cardholdersScope)?.then(() => setCurrentDownloadRowId(NO_DOWNLOAD_IN_PROGRESS))

    triggerCustomEventGoogleAnalytics360("download monthly overview", `${momentDate.year()}/${momentDate.month() + 1}`)
  }

  const calculateOverviewInterval = (overviewStartDate: string) => {
    const activationDate = moment(overviewStartDate, "YYYY-MM-DD").date(1)
    let startReportDate = moment(currentDate).subtract(13, "months").date(1)
    if (startReportDate.isBefore(activationDate)) {
      startReportDate = activationDate
    }

    const tempOverviewDateList: moment.Moment[] = []
    let momentDateIterator = moment(currentDate).add(1, "months");
    while (momentDateIterator.subtract(1, "months") && momentDateIterator.isAfter(startReportDate)) {
      tempOverviewDateList.push(moment(momentDateIterator))
    }

    return tempOverviewDateList
  }

  useEffect(() => {
    if (hasCountryConfig(country, "OVER_VIEW_PAGE_ACTIVATED_COUNTRIES")) {
      if (isAppReady && overviewDateList === undefined) {
        api?.overviewStartingDate(country)?.then((overviewStartDate) => {
          if (overviewStartDate && overviewStartDate.length > 7) { //minimum date length is 8
            setOverviewDateList(calculateOverviewInterval(overviewStartDate))
          }
        })
      }
    } else {
      navigate(pages.invoices.path)
    }
  }, [isAppReady, overviewDateList])

  useEffect(() => {
    if(overviewReportEmpty) {
      setDisabledRows(currentRows => [...currentRows, currentDownloadRowId])
    }
  }, [overviewReportEmpty])

  if (!hasCountryConfig(country, "OVER_VIEW_PAGE_ACTIVATED_COUNTRIES") || (!cardsList.length && !isImpersonationMode)) {
    return <></>
  }

  return (
    <>
      <Heading>
        {t("page.overview.title")}
      </Heading>
      <Separator/>
      <Description>{t("page.overview.description")}</Description>
      <Description>{t("page.overview.subDescription")}</Description>

      { customerRole !== "ADMIN" ?
          <CardholderDropDownList
              data-testid="cardholderDropDown"
              isDisabled={currentDownloadRowId !== NO_DOWNLOAD_IN_PROGRESS}
              page="OVERVIEW_PAGE"
              value={dropdownCard}
              setValue={(card: ISelectedCardAndBusiness) => {
                setDropdownCard(card)
                setDisabledRows([])
              }}
          /> : null
      }
      <BasicTableResponsive>
        <BasicTableHead>
          <BasicTableHeadCell>
            {t("common.date")}
          </BasicTableHeadCell>
          <BasicTableHeadCell/>
        </BasicTableHead>
        <BasicTableBody>
          {overviewDateList?.map((overviewMomentDate) => (
            <ReportBasicTableRow
                key={(overviewMomentDate.year() + "_" + overviewMomentDate.month())}
                isReady={true}
                isDisabled={currentDownloadRowId !== NO_DOWNLOAD_IN_PROGRESS ||
                    disabledRows.includes(overviewMomentDate.year() + "_" + overviewMomentDate.month())}
                isWaiting={(overviewMomentDate.year() + "_" + overviewMomentDate.month()) === currentDownloadRowId}
                momentDate={overviewMomentDate}
                onClickDownload={triggerDownload}
                buttonText={disabledRows.includes(overviewMomentDate.year() + "_" + overviewMomentDate.month()) ?
                    t("page.overview.button.empty") : t("page.overview.downloadOds") }
            />
          ))}
        </BasicTableBody>
      </BasicTableResponsive>
    </>
  );
}

export default OverviewPage;
