import {getCountryConfig, resolveCountry} from "./utils";
import TagManager from "react-gtm-module";

const country = resolveCountry().toUpperCase();
const gtm360Id = getCountryConfig(country, "GOOGLE_ANALYTICS_360_CONTAINER_ID")

export const initializeGoogleAnalytics360 = (
    pageName: string,
    languageTag: string,
    storeId: string,
    responseStatus: number,
    customerRole: string
) => {
    if (gtm360Id) {
        const tagManagerArgs = {
            gtmId: gtm360Id,
            dataLayer: createPageEvent(pageName, languageTag, storeId, responseStatus, customerRole),
            dataLayerName: 'dataLayer'
        }
        TagManager.initialize(tagManagerArgs);
    }
}

export const triggerGoogleAnalytics360 = (
    pageName: string,
    languageTag: string,
    storeId: string,
    responseStatus: number,
    customerRole: string
) => {
    const pageImpressionEvent = {
        ...createPageEvent(pageName, languageTag, storeId, responseStatus, customerRole),
        event: "page.impression"
    }
    const tagManagerArgs = {
        dataLayer: pageImpressionEvent,
        dataLayerName: 'dataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)
}

const createPageEvent = (
    pageName: string, //'impersonation','invoices','settings','overview'
    languageTag: string,
    storeId: string, //Customer storeId
    responseStatus: number, //response of the api request
    customerRole: string // Customer role
) => {
    const pageName_ = responseStatus === 200 ? pageName : 'errorPage'
    return {
        GA360_solution: 'M:INVOICE',
        GA360_salesLine: getCountryConfig(country, "LOGO"),
        GA360_country: country,
        GA360_languageSelected: languageTag,
        GA360_pageName: pageName_ + '|' + country + '|M:INVOICE',
        GA360_pageTitle: pageName_ + '|' + pageName_,
        GA360_templateType: pageName_,
        GA360_userInterface: 'responsive-site',
        GA360_errorPage: responseStatus === 200 ? "no-error" : "errorpage",
        GA360_statusCode: responseStatus,
        GA360_userType: customerRole,
        GA360_storeId: storeId,
        GA360_loggedIn: 'logged-in'
    };
}

export const triggerCustomEventGoogleAnalytics360 = (
    eventAction: string,
    eventLabel?: string
) => {
    TagManager.dataLayer({
        dataLayer: {
            event: "manual.event",
            eventCategory:"eInvoice", eventAction, eventLabel,
        },
    })
}