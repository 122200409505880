import React from "react";
import {Button, Header as MetroHeader, Layout as MetroLayout} from "@metro-ui/core";
import {useGeneralContext} from "../context/GeneralContext";
import {hasCountryConfig} from "../utils";
import HeaderMetroX from "./metrox/HeaderMetroX";
import {useIdamAuthContext} from "../context/IdamAuthContext";
import {useTranslation} from "react-i18next";
import {getCountryLogo} from "../countryToggles";


function Header() {
  const {country, isAppReady} = useGeneralContext()
  const {logout, isImpersonationMode} = useIdamAuthContext()
  const {t} = useTranslation();

  const useMetroXHeader = hasCountryConfig(country, "HEADER_METROX")

  return (
    <>
      {useMetroXHeader && isAppReady && !isImpersonationMode?
        <div data-testid="HeaderMetroX">
          <HeaderMetroX/>
        </div>
        :
        <div data-testid="MetroHeader">
          <MetroHeader Logo={getCountryLogo(country)}>
            <MetroLayout grow={1} align="center" justify="end" spacing="medium">
              <Button variant="outlineInverted" onClick={logout}>{t("nav.logout")}</Button>
            </MetroLayout>
          </MetroHeader>
        </div>
      }
    </>
  )
}

export default Header;