import {Tab, TabGroup, Tabs} from "@metro-ui/core";
import {pageList, pages} from "../App";
import {getCountryURLConfig, hasCountryConfig} from "../utils";
import HelpButton from "./cmsWidget/HelpButton";
import React from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {useIdamAuthContext} from "../context/IdamAuthContext";
import {useGeneralContext} from "../context/GeneralContext";

const NavBarContainer = styled.div`
    background-color: #CCE0FF;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
`

const NavBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;

    @media (min-width: 576px) {
        padding-right: 15px;
        padding-left: 15px
    }

    @media (min-width: 768px) {
        padding-right: 15px;
        padding-left: 15px
    }

    @media (min-width: 992px) {
        padding-right: 15px;
        padding-left: 15px
    }

    @media (min-width: 1200px) {
        padding-right: 15px;
        padding-left: 15px
    }

    @media (min-width: 576px) {
        width: 540px;
        max-width: 100%
    }

    @media (min-width: 768px) {
        width: 720px;
        max-width: 100%
    }

    @media (min-width: 992px) {
        width: 960px;
        max-width: 100%
    }

    @media (min-width: 1200px) {
        width: 1140px;
        max-width: 100%
    }
`

export default function NewNavigationBar() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const {isImpersonationMode} = useIdamAuthContext();
    const {country} = useGeneralContext();
    const helpButtonEnabled = hasCountryConfig(country, "HELP_WIDGET_COUNTRIES")

    const shopOnlineTabName = "shopOnline";
    const shopOnlineURL = getCountryURLConfig(country, "SHOP_ONLINE_URL");

    const onTabChangeHandler = (name: string | number): void => {
        if (name === shopOnlineTabName) {
            window.location.href = shopOnlineURL;
        } else {
            navigate(pageList.find((page) => page.name === name)?.path!)
        }
    }

    return (
        <NavBarContainer data-testid="new-nav-bar">
            <NavBar>
                <div>
                    <TabGroup
                        value={pageList.find((page) => page.path === pathname)?.name}
                        onChange={onTabChangeHandler}
                        id="navigation"
                    >
                        <Tabs>
                            {
                                shopOnlineURL !== '#' &&
                                <Tab name={shopOnlineTabName}>{t("nav.onlineShop")}</Tab>
                            }
                            <Tab name={pages.invoices.name}>{t(pages.invoices.translationKey)}</Tab>
                            {
                                hasCountryConfig(country, "OVER_VIEW_PAGE_ACTIVATED_COUNTRIES") &&
                                <Tab name={pages.overview.name}>{t(pages.overview.translationKey)}</Tab>
                            }
                            {
                                hasCountryConfig(country, "SETTING_PAGE_ACTIVATED_COUNTRIES") &&
                                <Tab name={pages.settings.name}>{t(pages.settings.translationKey)}</Tab>
                            }
                            {
                                isImpersonationMode &&
                                <Tab name={pages.impersonation.name}>{t(pages.impersonation.translationKey)}</Tab>
                            }
                        </Tabs>
                    </TabGroup>
                </div>
                {helpButtonEnabled && <HelpButton/>}
            </NavBar>
        </NavBarContainer>
    )
}