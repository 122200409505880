//For moment.js
import moment from "moment";
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/nl";
import "moment/locale/es";
import "moment/locale/ro";
import "moment/locale/hu";
import "moment/locale/be";
import "moment/locale/bg";
import "moment/locale/uk";
import "moment/locale/hr";

//For datepicker
import {registerLocale} from "react-datepicker";
import de from "date-fns/locale/de";
import fr from "date-fns/locale/fr";
import it from "date-fns/locale/it";
import pl from "date-fns/locale/pl";
import pt from "date-fns/locale/pt";
import nl from "date-fns/locale/nl";
import es from "date-fns/locale/es";
import ro from "date-fns/locale/ro";
import hu from "date-fns/locale/hu";
import be from "date-fns/locale/be";
import bg from "date-fns/locale/bg";
import uk from "date-fns/locale/uk";
import hr from "date-fns/locale/hr";

export const updateMomentJsLocale = (locale:string) => {
  moment.locale(locale.substring(0, 2))
}

export const updateDatePickerLocale = (locale:string) => {
  switch (locale.substring(0, 2).toLowerCase()) {
    case "de":
      registerLocale("de", de);
      break;
    case "fr":
      registerLocale("fr", fr);
      break;
    case 'it':
      registerLocale("it", it);
      break;
    case 'pl':
      registerLocale("pl", pl);
      break;
    case 'pt':
      registerLocale("pt", pt);
      break;
    case 'nl':
      registerLocale("nl", nl);
      break;
    case 'es':
      registerLocale("es", es);
      break;
    case 'ro':
      registerLocale("ro", ro);
      break;
    case 'hu':
      registerLocale("hu", hu);
      break;
    case 'be':
      registerLocale("be", be);
      break;
    case 'bg':
      registerLocale("bg", bg);
      break;
    case 'uk':
      registerLocale("uk", uk);
      break;
    case 'hr':
      registerLocale("hr", hr);
      break;
  }
}