import React from "react";
import {useGeneralContext} from "../../context/GeneralContext";
import useScript from "../../hook/useScript";
import {getCountryConfig} from "../../utils";
import "@webcomponents/webcomponentsjs/webcomponents-bundle";
import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js';

function FooterMetroX() {
  const {country} = useGeneralContext()
  const url = getCountryConfig(country, "METROX_URL") + '/data/apps/metro?sc_device=webcomponent&components=global%20footer'

  useScript(url, "METROX_FOOTER")
  return (
    <>
      <cms-global-footer></cms-global-footer>
    </>
  )
}

export default FooterMetroX;