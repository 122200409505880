import {datadogLogs} from "@datadog/browser-logs";

const enableLogging = () => {
    datadogLogs.init({
        clientToken: 'pubf18760aefa0076fcb3cf7cdf8d00e97a',
        site: 'metrodigital.datadoghq.eu',
        forwardErrorsToLogs: process.env.DRP_CF_STAGE === "pp",
        sampleRate: 100,
        service: 'mposx.minvas.einvoice',
        env: process.env.DRP_CF_STAGE,
    })
    datadogLogs.addLoggerGlobalContext('solution_id', 'S00099')
    datadogLogs.addLoggerGlobalContext('solution', 'mposx')
    datadogLogs.addLoggerGlobalContext('marc_id', 'A1231')
    datadogLogs.addLoggerGlobalContext('vertical', 'minvas')
}

export default enableLogging;