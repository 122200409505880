import React from "react";
import {FormField, Input, Text} from "@metro-ui/core";

const TextInputField = (
  {
    title,
    placeHolder,
    value,
    setValue,
    onEnterKey,
    hasError,
    errorMessage,
  }: { title: string, placeHolder: string, value: string, setValue: (value: string) => void, onEnterKey?: () => void, hasError: boolean, errorMessage: string }
) => {
  return (
    <FormField
      htmlFor="id"
      label={title}
      data-testid="TextInputFieldFormField"
    >
      <Input
        id="id"
        type="text"
        data-testid="TextInputFieldInput"
        name={title}
        placeholder={placeHolder}
        triggerInputPulseAnimation={false}
        value={value}
        hasError={hasError}
        onChange={(_event: React.SyntheticEvent, newValue: string) => {
          setValue(newValue);
        }}
        onKeyDown={(event:React.KeyboardEvent) => {
          if (event.key === 'Enter') {
            onEnterKey && onEnterKey();
          }
        }}
      />
        <div style={{height: "1rem"}}>
            { hasError &&
                <Text data-testid="TextInputFieldErrorText" color="danger" size="small" element="span">{errorMessage}</Text>
            }
        </div>
    </FormField>
  )
}

export default TextInputField;