import React, {useEffect} from 'react';
import {useIdamAuthContext} from "../context/IdamAuthContext";
import {useGeneralContext} from "../context/GeneralContext";

function SettingsPage() {
    const {logoutWithIdToken, ssoToken} = useIdamAuthContext();
    const {isAppReady} = useGeneralContext();
    useEffect(()=>{if(isAppReady) logoutWithIdToken(ssoToken)}, [isAppReady])
    return (
        <>
        </>
    );
}


export default SettingsPage;