import {getCountryConfig, resolveCountry} from "./utils";
import TagManager from "react-gtm-module";

const country = resolveCountry().toUpperCase();
const gtmGa4Id = getCountryConfig(country, "GOOGLE_ANALYTICS_GA4_CONTAINER_ID")

export const initializeGoogleAnalytics4 = () => {
    if (gtmGa4Id) {
        const tagManagerArgs = {
            gtmId: gtmGa4Id
        }

        TagManager.initialize(tagManagerArgs);
    }
}