import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function MetadataDescription () {
    const { t } = useTranslation();

    useEffect(() => {
        const descriptionElement = document.querySelector('meta[name="description"]');
        descriptionElement?.setAttribute("content", t('app.meta.description'));
    }, [t]);

    return null;
};