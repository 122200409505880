import {Button, Description, Heading, Separator} from "@metro-ui/core";
import {useTranslation} from "react-i18next";
import React from "react";
import {useIdamAuthContext} from "../context/IdamAuthContext";
import {pages} from "../App";
import {useGeneralContext} from "../context/GeneralContext";
import {useNavigate} from "react-router-dom";

function NoAccountPage() {
  const {t} = useTranslation();
  const {logout} = useIdamAuthContext()
  const {isAppReady, getAllCards} = useGeneralContext();
  const navigate = useNavigate();

  if(!isAppReady)
    return <></>;
  else if(getAllCards().length){
    navigate(pages.invoices.path)
    return <></>;
  }

  return (
    <>
      <Heading>
        {t("page.error.cardholderDependency")}
      </Heading>
      <Separator/>
      <Description>
        {t("page.error.cardholderDependency.message")}
      </Description>
      <Button variant="primary" onClick={logout}>{t("nav.logout")}</Button>
    </>
  );
}

export default NoAccountPage;