import {Text, Link} from "@metro-ui/core";
import {Help} from "@metro-ui/core/es6/icons";
import {useEffect, useState} from "react";
import styled from "styled-components";
import {Helmet} from "react-helmet";
import {useGeneralContext} from "../../context/GeneralContext";
import {useTranslation} from "react-i18next";

const StyledText = styled(Text)`
  &:hover {
    text-decoration: underline;
  }
`;

const countryId: Record<string, string> = {
    'fr': '15000000390',
    'de': '15000000464',
    'pk': '80000008204',
    'it': '76000000384',
    'ua': '103000005983',
    'bg': '76000000407',
    'es': '101000008617'
}

export default function HelpButton() {
    const {country} = useGeneralContext()
    const [helpClicked, setHelpClicked] = useState(false)
    const {t} = useTranslation()

    const toggleHelp = () => {
        setHelpClicked(true);
    }

    useEffect(() => {
        const adjustIframePosition = () => {
            const iframe = document.getElementById('launcher-frame');
            if (iframe) {
                iframe.style.bottom = '70px';
            }
        };

        const timeoutId = setTimeout(adjustIframePosition, 100);

        return () => clearTimeout(timeoutId); // Clean up the timeout on component unmount
    }, [helpClicked]);

    const showWidget = () => {
        return (
            <Helmet>
                <script>
                    {`
                        window.fwSettings={ 'widget_id':${countryId[country]}};
                        !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}() 
                        FreshworksWidget('hide', 'ticketForm', ['name']);
                    `}
                </script>
                <script src={`https://euc-widget.freshworks.com/widgets/${countryId[country]}.js`} async defer></script>
                <script>
                    {`
                        FreshworksWidget("setLabels", {
                            '${country}': {
                            banner: "${t('help.widget.banner')}",
                            launcher: "${t('help.widget.launcher')}",
                            contact_form: {
                                title: "${t('help.widget.contact_form.title')}",
                                submit: "${t('help.widget.contact_form.submit')}",
                                confirmation: "${t('help.widget.contact_form.confirmation')}",
                            }
                        }});
                    `}
                </script>
            </Helmet>
        )
    }

    return (
        <Link id='help-button'
              onClick={() => {toggleHelp()}}
              style={{textDecoration: 'none', boxShadow: 'none'}}
              data-testid='help-button'
        >
            <Help color={'brand-primary'} style={{marginRight: '5px'}}/>
            <StyledText color={'brand-primary'}>{t('help.button.text')}</StyledText>
            {
                helpClicked ? showWidget() : null
            }
        </Link>
    )
}