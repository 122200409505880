import {Button, Checkbox, Heading, IconButton, Separator} from "@metro-ui/core";
import {capitalizeFirstLetter, formatCurrencyForCountry, getInvoiceTypeTranslationKey} from "../../utils";
import dateFormat from "dateformat";
import {Icons} from "@metro-ui/core/es6/icons/allAtOnce";
import React, {useState} from "react";
import {INVOICE_DATE_FORMAT} from "../../page/InvoicePage";
import {useApi} from "../../context/ApiContext";
import {useTranslation} from "react-i18next";
import {InvoiceRowProp} from "./InvoiceRowProps";
import {MobileViewPodTableSubRow, MobileViewTableSubRow} from "./MobileViewTableSubRow";
import InvoiceMobileViewRowPod from "./InvoiceMobileViewRowPod";
import {triggerCustomEventGoogleAnalytics360} from "../../ga360";
import { useGeneralContext } from "../../context/GeneralContext";

const InvoiceMobileViewRow = ({
                                invoice,
                                singleRowSelectionStatusUpdate,
                                tableRowSelectionStatus,
                                downloadingTransactionId,
                                setDownloadingTransactionId,
                                podInvoiceExists,
                                anySearchOrDownloadInProgress
                              }: InvoiceRowProp) => {
  const {downloadInvoice} = useApi();
  const {selectedCardAndBusiness} = useGeneralContext()
  const cdmAccountId = selectedCardAndBusiness?.businessId !== "personal" ? selectedCardAndBusiness?.businessId : undefined

  const {t} = useTranslation();
  const [expandPod, setExpandPod] = useState<boolean>(false);

  const PodExpansionIcon:JSX.Element = <IconButton
    Icon={Icons[expandPod ? 'ChevronUpSmall' : 'ChevronDownSmall']} label={'expand'}
    hasNoInteractionEffect
    onClick={() => {
      setExpandPod(!expandPod)
    }}
    data-testid="podExpandButton"
    style={{"verticalAlign": "middle"}}
  />

  return <div
    style={{
      display: 'flex',
      flexDirection: 'column'
    }}
    data-testid='mobileRowTest'
  >
    <div>
      <Checkbox
        id={invoice.transactionId}
        name={invoice.transactionId}
        data-testid="printoutCheckbox"
        isDisabled={false}
        isChecked={tableRowSelectionStatus[invoice.transactionId] > 0}
        onChange={() => singleRowSelectionStatusUpdate(invoice.transactionId, invoice.podInvoice ? invoice.podInvoice.transactionId : undefined)}
      >
        <Heading
          variant="h3"
          color="primary"
          hasNoNativeMargin={true}
          style={{
            marginBottom: '5px',
            marginTop: '5px'
          }}
        >
          {capitalizeFirstLetter(t(getInvoiceTypeTranslationKey(invoice.customerInvoiceType)))}
        </Heading>
      </Checkbox>
    </div>
    <Separator style={{
      marginBottom: '5px',
      marginTop: '5px'
    }}/>
          {MobileViewTableSubRow(t("common.date"), dateFormat(invoice.invoiceTimestamp * 1000, INVOICE_DATE_FORMAT), invoice.downloaded)}
          {MobileViewTableSubRow(t("common.invoiceNumber"), invoice.invoiceNumber, invoice.downloaded)}
          {MobileViewTableSubRow(t("common.netAmount"), formatCurrencyForCountry(invoice.netAmount, invoice.customerCountryCode, invoice.currency), invoice.downloaded)}
          {MobileViewTableSubRow(t("common.totalAmount"), formatCurrencyForCountry(invoice.totalAmount, invoice.customerCountryCode, invoice.currency), invoice.downloaded)}

    {invoice.podInvoice && <>
      {MobileViewPodTableSubRow(t("common.payment"), PodExpansionIcon)}
      {expandPod && <div style={{paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', backgroundColor:'#f2f5f8'}} data-testid="mobilePod">
       <InvoiceMobileViewRowPod
           podInvoice={invoice.podInvoice}
           anySearchOrDownloadInProgress={anySearchOrDownloadInProgress}
           downloadingTransactionId={downloadingTransactionId}
           setDownloadingTransactionId={setDownloadingTransactionId}
       />
      </div>}
    </>}
    <div style={expandPod?{paddingTop: '10px'}:{}}></div>
    <Button
      Icon={Icons["Download"]}
      variant={"outline"}
      size="small"
      isWaiting={downloadingTransactionId === invoice.transactionId}
      isDisabled={anySearchOrDownloadInProgress}
      onClick={() => {
        setDownloadingTransactionId(invoice.transactionId)
        downloadInvoice(invoice.transactionId, cdmAccountId)?.then(() =>
          setDownloadingTransactionId("")
        )
        triggerCustomEventGoogleAnalytics360("download invoice")
      }}
      data-testid='downloadPdfButtonMobilePod'
    >
      {t("common.downloadPdf")}
    </Button>
  </div>
}

export default InvoiceMobileViewRow