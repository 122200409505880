const parsed ={"bg":{
  "all.cards": "всички карти",
  "app.banner.button": "Използвайте нашето приложение",
  "app.banner.description": "Достъп до вашите фактури по всяко време и навсякъде",
  "common.cancel": "Отмени",
  "common.card": "Карта",
  "common.customer": "Клиент",
  "common.dataPrivacy": "Поверителност",
  "common.date": "Дата",
  "common.date.placeholder": "yyyy-mm-dd",
  "common.download": "Изтегли",
  "common.downloadPdf": "Изтегли .pdf",
  "common.downloadSelected": "Изтегли маркираните",
  "common.from": "От",
  "common.invoiceNumber": "Фактура №",
  "common.legalNotice": "Общи условия",
  "common.limit": "Показани фактури:",
  "common.netAmount": "Стойност без ДДС",
  "common.next": "Следващ",
  "common.noResults": "Няма резултати",
  "common.payment": "Плащане",
  "common.previous": "Предходен",
  "common.to": "До",
  "common.totalAmount": "Общо",
  "common.transaction.type.COD": "COD",
  "common.transaction.type.CONI": "Комбинирана фактура",
  "common.transaction.type.DELIVERY_SLIP": "Разписка за доставка",
  "common.transaction.type.DOWN_PAYMENT": "Авансово плащане",
  "common.transaction.type.EMPTIES": "Амбалаж",
  "common.transaction.type.INVOICE": "Фактура",
  "common.transaction.type.PACKING_LIST": "Списък за събиране",
  "common.transaction.type.PAYMENT_RECEIPT": "Касова бележка за плащане",
  "common.transaction.type.PAY_IN_CHARGE_REFUND": "Възстановяване на внесена сума",
  "common.transaction.type.REIMBURSEMENT_DOWN_PAYMENT": "Връщане на авансово плащане",
  "common.transaction.type.RETURN": "Връщане",
  "common.transaction.type.UNKNOWN": "Неясна",
  "common.transaction.type.VOIDING_INVOICE": "Отменяне на фактура",
  "common.type": "Вид",
  "download.error.invoice": "Има проблем при изтеглянето на вашата фактура, моля, опитайте отново по-късно.",
  "help.button.text": "Помощ и поддръжка",
  "invoices.card.header": "Фактури за {0}",
  "invoices.card.show": "Покажи фактури",
  "maintenance.message": "В момента сме изправени пред някои проблеми с нашата система, но работим усилено, за да ги отстраним. Моля, бъдете търпеливи и опитайте пак след малко.\n",
  "nav.invoices": "Фактури",
  "nav.logout": "Отписване",
  "nav.onlineShop": "Онлайн магазин\n",
  "nav.overview": "Общ преглед",
  "nav.settings": "Настройки",
  "page.error.cardholderDependency": "Не можем да открием профилите ви\n",
  "page.error.cardholderDependency.message": "Не можем да открием профилите поради възникнал проблем. Моля опитайте по-късно. \n",
  "page.error.default": "Възникна грешка!",
  "page.error.default.message": "Възникна грешка при извършването на посоченото искане. Моля опитайте отново!",
  "page.error.forbidden": "Забранено",
  "page.error.forbidden.message": "Нямате достъп до електронните фактури. Съжаляваме за причиненото неудобство.\n",
  "page.error.invalidSearchInput": "Моля въведете поне 3 символа",
  "page.error.noCardholder": "Не можем да достъпим до фактурите Ви\n",
  "page.error.noCardholder.message": "Нямаме достъп до Вашите фактури. Моля, опитайте да влезете с Вашия METRO клиентски акаунт.\n",
  "page.error.notFound": "Страницата не е намерена",
  "page.error.notFound.message": "Ресурса който търсите не бе намерен.",
  "page.error.no_available_cards": "Няма налични карти",
  "page.error.no_available_cards.message": "Все още не сте упълномощени да виждате данните за фактурата. Моля, завършете процеса на регистрация на потребител.",
  "page.error.resourceAccess": "Възникна проблем при извличането на информацията. ",
  "page.error.resourceAccess.message": "Нещо се обърка от наша страна и отне прекалено много време да обработим заявката Ви. Моля опитайте отново по-късно!",
  "page.error.sorry": "Извиняваме се!",
  "page.error.statusCode": "Статус код",
  "page.error.traceIdMessage": "Кода за тази грешка е:",
  "page.error.wrongSearchInput": "Въведеното трябва да съдържа само букви (A-Z, a-z), цифри (0-9) и тези знаци: /.,-_",
  "page.impersonation.attention": "С голяма сила идва и голяма отговорност: внимавайте какво правите. Всички промени в състоянието (промяна на настройките, маркиране на фактури като прочетени) също ще се отразят на представящия се клиент.",
  "page.impersonation.card.help": "Моля въведете номер на карта с цифри",
  "page.impersonation.card.placeholder": "Карта №",
  "page.impersonation.confirm": "Потвърждавам че имам разрешение да действам като този клиент. ",
  "page.impersonation.confirm.description": "Моля обърнете внимание че няма проверка, дали този клиент съществува. Ако клиента не съществува, ще излиза все едно няма информация за клиента. ",
  "page.impersonation.customer.help": "Моля въведете цифров клиентски номер",
  "page.impersonation.customer.placeholder": "Клиентски №",
  "page.impersonation.description": "Можете да използвате тази форма, за да станете специфичен клиент, и да използвате Електронни фактури с поддържащи функции.",
  "page.impersonation.info.description": "натиснете, за да настроите\n",
  "page.impersonation.info.title": "Достъп до имитиране",
  "page.impersonation.store.help": "Моля въведете номер на магазин с цифри",
  "page.impersonation.store.placeholder": "Магазин №",
  "page.impersonation.title": "Имитиране",
  "page.impersonation.update": "Приложи",
  "page.impersonation.update.error": "Изпращането на формата е неуспешно. Моля проверете грешките при валидиране. ",
  "page.impersonation.update.successful": "Потребителя е променен успешно. Моля отпишете се, за да излезете от имитирането. \n",
  "page.invoices.articles.table.description": "Описание",
  "page.invoices.articles.table.EAN": "Баркод",
  "page.invoices.articles.table.MAN": "МЕТРО артикулен №",
  "page.invoices.filter": "Филтър",
  "page.invoices.listTitle": "Моите фактури",
  "page.invoices.search": "Търси",
  "page.invoices.search.placeholder": "Търсене по № на фактура, име и т.н.",
  "page.overview.availableAt": "налично в началото на",
  "page.overview.availableUntil": "налично до края на",
  "page.overview.button.empty": "празен файл",
  "page.overview.description": "Този сайт предоставя списък на закупените артикули за изтегляне. Файловете се предоставят като документи за електронни таблици, които могат да се отварят с приложение за електронни таблици по ваш избор (например Microsoft Excel).",
  "page.overview.downloadOds": "изтегли .ods",
  "page.overview.export.fallback.VAT_CODES_UNKNOWN": "Неясен ДДС код {0}",
  "page.overview.export.header.MO_PM_TABLE_AMOUNT": "Стойност без ДДС",
  "page.overview.export.header.MO_PM_TABLE_CARD_TYPE_DESC": "Описание на типа карта",
  "page.overview.export.header.MO_PM_TABLE_CURRENCY_CODE": "Валутен код",
  "page.overview.export.header.MO_PM_TABLE_FISCAL_MEDIA_DESC": "Описание на фискалната медия",
  "page.overview.export.header.MO_PM_TABLE_MEDIA_DESC": "Описание",
  "page.overview.export.header.MO_TABLE_ARTICLE_DESCRIPTION": "Артикулно описание",
  "page.overview.export.header.MO_TABLE_ARTICLE_GROUP_NAME": "Артикулна група",
  "page.overview.export.header.MO_TABLE_ARTICLE_NUMBER": "Артикулен №",
  "page.overview.export.header.MO_TABLE_BUYING_STORE": "Магазин за покупка",
  "page.overview.export.header.MO_TABLE_CARDHOLDER_FIRST_NAME": "Първо име",
  "page.overview.export.header.MO_TABLE_CARDHOLDER_LAST_NAME": "Фамилия",
  "page.overview.export.header.MO_TABLE_CARD_NUMBER": "Карта №",
  "page.overview.export.header.MO_TABLE_CUSTOMER_NAME": "Име на клиент",
  "page.overview.export.header.MO_TABLE_CUSTOMER_TYPE": "Тип клиент",
  "page.overview.export.header.MO_TABLE_GTIN_NUMBER": "GTIN",
  "page.overview.export.header.MO_TABLE_INVOICE_DATE": "Дата на фактура",
  "page.overview.export.header.MO_TABLE_INVOICE_NUMBER": "Фактура №",
  "page.overview.export.header.MO_TABLE_INVOICE_TYPE": "Тип фактура",
  "page.overview.export.header.MO_TABLE_LOT_NUMBER": "Партиден №",
  "page.overview.export.header.MO_TABLE_ORDER_ID": "Поръчка №",
  "page.overview.export.header.MO_TABLE_PRICE_GROSS": "Цена с ДДС",
  "page.overview.export.header.MO_TABLE_PRICE_GROSS_WITH_DISCOUNT": "Брутна намалена цена",
  "page.overview.export.header.MO_TABLE_PRICE_NET": "Цена без ДДС",
  "page.overview.export.header.MO_TABLE_PRICE_PER_PIECE_KG": "Цена/бр/КГ",
  "page.overview.export.header.MO_TABLE_PRICE_PER_SELLING_UNIT_NET": "Цена/Продажна единица без ДДС",
  "page.overview.export.header.MO_TABLE_QUANTITY": "Количество",
  "page.overview.export.header.MO_TABLE_QUANTITY_PER_SELLING_UNIT": "Количество/Продажна еденица",
  "page.overview.export.header.MO_TABLE_TAXES_AND_FEES": "Такси",
  "page.overview.export.header.MO_TABLE_VAT": "ДДС",
  "page.overview.export.sheet.ARTICLE": "Артикули",
  "page.overview.export.sheet.PAYMENT": "Плащания",
  "page.overview.subDescription": "Предоставят се отчети за последните 13 месеца и няма ограничение кога да се изтеглят",
  "page.overview.title": "Преглед на покупката",
  "page.settings.description": "В тази страница можете да коригирате настройките на Електронни фактури. Моля, имайте предвид, че отнема няколко минути, настройките да бъдат обработени.\n",
  "page.settings.emailNotifications": "Изпрати имейл известия",
  "page.settings.emailNotifications.description": "Ако активирате тази опция, ще получавате имейл изветстие за всяка нова фактура в Електронни фактури. ",
  "page.settings.printAtCheckout": "Разпечатай фактура на касите",
  "page.settings.printAtCheckout.description": "Ако активирате тази опция, ще получавате разпечатана фактура на касата в магазина. Деактивирайте тази опция, ако не искате повече да получавате разпечатана фактура. Имате достъп до вскички дигитални документи чрез Електронни фактури. ",
  "page.settings.printSuppression": "Спестете хартия, електронни фактури",
  "page.settings.printSuppression.description": "В случай, че активирате тази опция, няма да получавате разпечатани фактури и ще спестите хартия.",
  "page.settings.title": "Настройки - Електронни фактури",
  "page.settings.update": "Запазете настройките",
  "page.settings.update.submitted": "Вашата промяна е изпратена. Може да отнеме известно време да бъде обработена. Моля бъдете търпеливи. ",
  "page.settings.update.successful": "Настройките са обновени успешно",
  "personal.card": "Лична карта",
  "warning.business.has.no.valid.card": "Моля, изберете бизнес от вашия акаунт, за да видите фактури, или бизнесът НЯМА ВАЛИДНА КАРТА."
},"de":{
  "all.cards": "alle",
  "app.banner.button": "Bitte nutzen Sie die App!",
  "app.banner.description": "Haben Sie überall und jederzeit Zugriff auf Ihre Rechnungen.",
  "common.cancel": "Abbrechen",
  "common.card": "Karte",
  "common.customer": "Kunde",
  "common.dataPrivacy": "Rechtliche Hinweise",
  "common.date": "Datum",
  "common.date.placeholder": "jjjj-mm-tt",
  "common.download": "Herunterladen",
  "common.downloadPdf": "PDF herunterladen",
  "common.downloadSelected": "Ausgewählte herunterladen",
  "common.from": "Von",
  "common.invoiceNumber": "Rechnungsnr.",
  "common.legalNotice": "Impressum",
  "common.limit": "Anzahl angezeigter Rechnungen:",
  "common.netAmount": "Netto",
  "common.next": "Weiter",
  "common.noResults": "Keine Ergebnisse",
  "common.payment": "Zahlung",
  "common.previous": "Zurück",
  "common.to": "Bis",
  "common.totalAmount": "Brutto",
  "common.transaction.type.COD": "COD",
  "common.transaction.type.CONI": "Sammelrechnung",
  "common.transaction.type.DELIVERY_SLIP": "Lieferschein",
  "common.transaction.type.DOWN_PAYMENT": "Anzahlung",
  "common.transaction.type.EMPTIES": "Leergut",
  "common.transaction.type.INVOICE": "Rechnung",
  "common.transaction.type.PACKING_LIST": "Warenbeleg",
  "common.transaction.type.PAYMENT_RECEIPT": "Zahlquittung",
  "common.transaction.type.PAY_IN_CHARGE_REFUND": "Bezahlung NB",
  "common.transaction.type.REIMBURSEMENT_DOWN_PAYMENT": "Gutschrift",
  "common.transaction.type.RETURN": "Rückgabe",
  "common.transaction.type.UNKNOWN": "Unbekannt",
  "common.transaction.type.VOIDING_INVOICE": "Stornierung",
  "common.type": "Typ",
  "deactivate.invoice.printing.button": "Rechnungsdruck deaktivieren",
  "deactivate.invoice.printing.description": "Sie möchten künftig bei Ihren Einkäufen im Markt keine ausgedruckten Rechnungen mehr erhalten?",
  "download.error.invoice": "Beim Herunterladen Ihrer Rechnung sind Probleme aufgetreten. Bitte versuchen Sie es später noch einmal.",
  "download.error.some.invoices": "Fehler beim Download",
  "help.button.text": "Hilfe & Unterstützung",
  "invoices.card.header": "Rechnungen für {0}",
  "invoices.card.show": "Rechnungen",
  "invoices.per.page.filter": "Rechnungen pro Seite",
  "maintenance.message": "Übergreifende Rechnungseinsicht: \nSie wollen die Rechnungen Ihrer Einkaufsberechtigten sehen?\nBitte kontaktieren Sie unsere Kundenhotline (kontakt@metro.de oder Rufnummer 0211/17607090).",
  "nav.invoices": "Rechnungen",
  "nav.logout": "Ausloggen",
  "nav.onlineShop": "Online Shop",
  "nav.overview": "Übersicht",
  "nav.settings": "Einstellungen",
  "page.error.cardholderDependency": "Kunde kann nicht gefunden werden",
  "page.error.cardholderDependency.message": "Aktuell gibt es technische Probleme. Bitte versuchen Sie es später noch einmal.",
  "page.error.default": "Ein Fehler ist aufgetreten!",
  "page.error.default.message": "Ein Fehler ist beim Ausführen Ihrer Anfrage aufgetreten. Bitte versuchen Sie es später noch ein mal!",
  "page.error.forbidden": "Keine Berechtigung",
  "page.error.forbidden.message": "Die Anfrage darf von Ihnen nicht ausgeführt werden. Bitte entschuldigen Sie die Unannehmlichkeiten.",
  "page.error.invalidSearchInput": "Bitte die letzten 3 Stellen eingeben!",
  "page.error.noCardholder": "Fehler beim Rechnungszugriff",
  "page.error.noCardholder.message": "Wir können keine Rechnungen für Sie abholen. Bitte loggen Sie sich als ein Metro Kunde ein.",
  "page.error.notFound": "Die Seite wurde nicht gefunden",
  "page.error.notFound.message": "Die gesuchte Ressource wurde leider nicht gefunden.",
  "page.error.no_available_cards": "keiner verfügbar",
  "page.error.no_available_cards.message": "Sie sind aktuell nicht berechtigt die Rechnungen einzusehen. Bitte vervollständigen Sie ihre Registrierung.",
  "page.error.resourceAccess": "Problem beim Datenzugriff",
  "page.error.resourceAccess.message": "Auf unserer Seite ist ein Fehler aufgetreten und die Bearbeitung Ihrer Anfrage hat zu lange gedauert. Bitte versuchen Sie es später noch ein mal!",
  "page.error.sorry": "Es tut uns leid!",
  "page.error.statusCode": "Statuscode",
  "page.error.traceIdMessage": "Der Fehlercode für diesen Fehler ist:",
  "page.error.wrongSearchInput": "Bitte verwenden Sie nur (A-Z, a-z), (0-9) oder folgende Sonderzeichen (/.,-_).",
  "page.impersonation.attention": "Mit großer Macht kommt große Verantwortung: Seien Sie vorsichtig was Sie tun. Alle Zustandsänderungen (Einstellungsänderungen, Dokumente als gelesen markieren) wirken sich auch auf den imitierten Kunden aus.",
  "page.impersonation.card.help": "Bitte geben Sie eine numerische Kartennummer ein",
  "page.impersonation.card.placeholder": "CardID",
  "page.impersonation.confirm": "Ich bestätige, dass ich autorisiert bin mich als diesen Kunden auszugeben.",
  "page.impersonation.confirm.description": "Bitte beachten Sie dass nicht geprüft wird ob dieses Kunden wirklich existiert. Wenn Sie eine nicht existierenden Kunden eingeben, agieren Sie wie ein Kunde ohne Rechnungsdaten.",
  "page.impersonation.customer.help": "Bitte geben Sie eine numerische Kundennummer ein.",
  "page.impersonation.customer.placeholder": "CustomerID",
  "page.impersonation.description": "Sie können dieses Formular verwenden um eInvoice zu Supportzwecken im Kontext eines bestimmten Kunden zu verwenden.",
  "page.impersonation.info.description": "zum konfigurieren klicken",
  "page.impersonation.info.title": "Identitätswechsel",
  "page.impersonation.store.help": "Bitte geben Sie eine numerische Store-Nummer ein (BKZ)",
  "page.impersonation.store.placeholder": "StoreID",
  "page.impersonation.title": "Identitätswechsel",
  "page.impersonation.update": "Übernehmen",
  "page.impersonation.update.error": "Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte prüfen Sie die markierten Felder.",
  "page.impersonation.update.successful": "Kunde erfolgreich gewechselt. Melden Sie sich ab, um den Indentitätswechsel zu beenden.",
  "page.invoices.articles.table.description": "Beschreibung",
  "page.invoices.articles.table.EAN": "EAN",
  "page.invoices.articles.table.MAN": "Metro Artikelnr.",
  "page.invoices.filter": "Filtern",
  "page.invoices.listTitle": "Meine Rechnungen",
  "page.invoices.search": "Suche",
  "page.invoices.search.placeholder": "Suche nach Rechnungsnr., Name usw.",
  "page.overview.availableAt": "verfügbar von",
  "page.overview.availableUntil": "verfügbar bis",
  "page.overview.button.empty": "leere Datei",
  "page.overview.description": "Hier können Sie eine Liste mit den gekauften Artikeln herunterladen. Die Datei können Sie mit z.B. Microsoft Excel öffnen.",
  "page.overview.downloadOds": "Herunterladen",
  "page.overview.export.fallback.VAT_CODES_UNKNOWN": "unbekannter Steuercode",
  "page.overview.export.header.MO_PM_TABLE_AMOUNT": "Betrag",
  "page.overview.export.header.MO_PM_TABLE_CARD_TYPE_DESC": "Kartentyp",
  "page.overview.export.header.MO_PM_TABLE_CURRENCY_CODE": "Währung",
  "page.overview.export.header.MO_PM_TABLE_FISCAL_MEDIA_DESC": "Zahlart",
  "page.overview.export.header.MO_PM_TABLE_MEDIA_DESC": "Zahlart",
  "page.overview.export.header.MO_TABLE_ARTICLE_DESCRIPTION": "Artikelbezeichnung",
  "page.overview.export.header.MO_TABLE_ARTICLE_GROUP_NAME": "Artikelgruppe",
  "page.overview.export.header.MO_TABLE_ARTICLE_NUMBER": "Artikelnummer",
  "page.overview.export.header.MO_TABLE_BUYING_STORE": "BKZ",
  "page.overview.export.header.MO_TABLE_CARDHOLDER_FIRST_NAME": "Vorname",
  "page.overview.export.header.MO_TABLE_CARDHOLDER_LAST_NAME": "Nachname",
  "page.overview.export.header.MO_TABLE_CARD_NUMBER": "Kartennummer",
  "page.overview.export.header.MO_TABLE_CUSTOMER_NAME": "Kundenname",
  "page.overview.export.header.MO_TABLE_CUSTOMER_TYPE": "Kundentyp",
  "page.overview.export.header.MO_TABLE_GTIN_NUMBER": "GTIN",
  "page.overview.export.header.MO_TABLE_INVOICE_DATE": "Rechnungsdatum",
  "page.overview.export.header.MO_TABLE_INVOICE_NUMBER": "Rechnungsnummer",
  "page.overview.export.header.MO_TABLE_INVOICE_TYPE": "Beleg",
  "page.overview.export.header.MO_TABLE_LOT_NUMBER": "Charge",
  "page.overview.export.header.MO_TABLE_ORDER_ID": "Bestellnummer",
  "page.overview.export.header.MO_TABLE_PRICE_GROSS": "Bruttopreis",
  "page.overview.export.header.MO_TABLE_PRICE_GROSS_WITH_DISCOUNT": "Brutto-Rabattpreis",
  "page.overview.export.header.MO_TABLE_PRICE_NET": "Nettopreis",
  "page.overview.export.header.MO_TABLE_PRICE_PER_PIECE_KG": "Preis pro Einheit",
  "page.overview.export.header.MO_TABLE_PRICE_PER_SELLING_UNIT_NET": "VK-Preis netto",
  "page.overview.export.header.MO_TABLE_QUANTITY": "Menge",
  "page.overview.export.header.MO_TABLE_QUANTITY_PER_SELLING_UNIT": "Menge",
  "page.overview.export.header.MO_TABLE_TAXES_AND_FEES": "Steuern",
  "page.overview.export.header.MO_TABLE_VAT": "Steuer",
  "page.overview.export.sheet.ARTICLE": "Artikel",
  "page.overview.export.sheet.PAYMENT": "Zahlung",
  "page.overview.subDescription": "Verfügbar sind die Dateien der letzten 13 Monate.",
  "page.overview.title": "Übersicht",
  "page.settings.description": "Auf dieser Seite können Sie Ihre eInvoice-Einstellungen bearbeiten. Bitte beachten Sie, dass Änderungen direkte Auswirkungen auf die Rechnungserstellung an der Kasse haben können und, dass es einige Minuten in Anspruch nimmt, bis diese wirksam werden.",
  "page.settings.emailNotifications": "Rechnung per E-Mail erhalten",
  "page.settings.emailNotifications.description": "Wenn Sie diese Option aktivieren, erhalten Sie eine E-Mail inkl. der Rechnung als PDF für jedes neue Dokument in eInvoice.",
  "page.settings.printAtCheckout": "Ausdruck der Rechnung an der Kasse",
  "page.settings.printAtCheckout.description": "Wenn Sie diese Option aktivieren, erhalten Sie eine gedruckte Rechnung an der Marktkasse. Deaktivieren Sie diese Option, wenn Sie in Zukunft keine gedruckte Rechnung mehr erhalten möchten. Sie können weiterhin auf alle Ihre digitalen Dokumente über eInvoice zugreifen.",
  "page.settings.printSuppression": "Papier sparen, Rechnung digital",
  "page.settings.printSuppression.description": "Wenn Sie diese Option aktivieren, erhalten Sie keine gedruckten Rechnungen\nan der Marktkasse und sparen Papier.",
  "page.settings.title": "eInvoice Einstellungen",
  "page.settings.update": "Einstellungen anpassen",
  "page.settings.update.submitted": "Deine Einstellungen werden übertragen. Die Bearbeitung kann einige Zeit in Anspruch nehmen.",
  "page.settings.update.successful": "Einstellungen erfolgreich gespeichert.",
  "personal.card": "PK-Beleg",
  "warning.business.has.no.valid.card": "Bitte Kunden auswählen oder es gibt keinen gültigen EKB!"
},"de_BE":{
  "common.cancel": "Abbrechen",
  "common.card": "Karte",
  "common.dataPrivacy": "Rechtliche Hinweise",
  "common.date": "Datum",
  "common.date.placeholder": "jjjj-mm-tt",
  "common.download": "Herunterladen",
  "common.downloadPdf": "PDF herunterladen",
  "common.downloadSelected": "Ausgewählte herunterladen",
  "common.from": "Von",
  "common.invoiceNumber": "Rechnungsnr.",
  "common.legalNotice": "Impressum",
  "common.limit": "Anzahl angezeigter Rechnungen:",
  "common.netAmount": "Netto",
  "common.next": "Weiter",
  "common.noResults": "Keine Ergebnisse",
  "common.previous": "Zurück",
  "common.to": "Bis",
  "common.totalAmount": "Brutto",
  "common.transaction.type.CONI": "Sammelrechnung",
  "common.transaction.type.DELIVERY_SLIP": "Lieferschein",
  "common.transaction.type.EMPTIES": "Leergut",
  "common.transaction.type.INVOICE": "Rechnung",
  "common.transaction.type.PACKING_LIST": "Warenbeleg",
  "common.transaction.type.PAYMENT_RECEIPT": "Zahlquittung",
  "common.transaction.type.RETURN": "Rückgabe",
  "common.transaction.type.UNKNOWN": "Unbekannt",
  "common.transaction.type.VOIDING_INVOICE": "Stornierung",
  "common.type": "Typ",
  "invoices.card.header": "Rechnungen für {0}",
  "invoices.card.show": "Rechnungen",
  "maintenance.message": "Wir haben zurzeit einige Probleme mit unseren Systemen, aber wir arbeiten hart daran sie zu beheben. Bitte haben Sie etwas Geduld. Wir sind bald wieder voll einsatzbereit.",
  "nav.invoices": "Rechnungen",
  "nav.logout": "Abmelden",
  "nav.settings": "Einstellungen",
  "page.error.default": "Ein Fehler ist aufgetreten!",
  "page.error.default.message": "Ein Fehler ist beim Ausführen Ihrer Anfrage aufgetreten. Bitte versuchen Sie es später noch ein mal!",
  "page.error.forbidden": "Keine Berechtigung",
  "page.error.forbidden.message": "Die Anfrage darf von Ihnen nicht ausgeführt werden. Bitte entschuldigen Sie die Unannehmlichkeiten.",
  "page.error.noCardholder": "Fehler beim Rechnungszugriff",
  "page.error.noCardholder.message": "Wir können keine Rechnungen für Sie abholen. Bitte loggen Sie sich als ein Metro Kunde ein.",
  "page.error.notFound": "Die Seite wurde nicht gefunden",
  "page.error.notFound.message": "Die gesuchte Ressource wurde leider nicht gefunden.",
  "page.error.resourceAccess": "Problem beim Datenzugriff",
  "page.error.resourceAccess.message": "Auf unserer Seite ist ein Fehler aufgetreten und die Bearbeitung Ihrer Anfrage hat zu lange gedauert. Bitte versuchen Sie es später noch ein mal!",
  "page.error.sorry": "Es tut uns leid!",
  "page.error.statusCode": "Statuscode",
  "page.error.traceIdMessage": "Der Fehlercode für diesen Fehler ist:",
  "page.impersonation.attention": "Mit großer Macht kommt große Verantwortung: Seien Sie vorsichtig was Sie tun. Alle Zustandsänderungen (Einstellungsänderungen, Dokumente als gelesen markieren) wirken sich auch auf den imitierten Kunden aus.",
  "page.impersonation.card.help": "Bitte geben Sie eine numerische Kartennummer ein",
  "page.impersonation.card.placeholder": "CardID",
  "page.impersonation.confirm": "Ich bestätige das ich authorisiert bin mich als diesen Kunden auszugeben.",
  "page.impersonation.confirm.description": "Bitte beachten Sie dass nicht geprüft wird ob dieses Kunden wirklich existiert. Wenn Sie eine nicht existierenden Kunden eingeben, agieren Sie wie ein Kunde ohne Rechnungsdaten.",
  "page.impersonation.customer.help": "Bitte geben Sie eine numerische Kundennummer ein.",
  "page.impersonation.customer.placeholder": "CustomerID",
  "page.impersonation.description": "Sie können dieses Formular verwenden um eInvoice zu Supportzwecken im Kontext eines bestimmten Kunden zu verwenden.",
  "page.impersonation.info.description": "zum konfigurieren klicken",
  "page.impersonation.info.title": "Identitätswechsel",
  "page.impersonation.store.help": "Bitte geben Sie eine numerische Store-Nummer ein (BKZ)",
  "page.impersonation.store.placeholder": "StoreID",
  "page.impersonation.title": "Identitätswechsel",
  "page.impersonation.update": "Übernehmen",
  "page.impersonation.update.error": "Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte prüfen Sie die markierten Felder.",
  "page.impersonation.update.successful": "Kunde erfolgreich gewechselt. Melden Sie sich ab um den Indentitätswechsel zu beenden.",
  "page.invoices.articles.table.description": "Beschreibung",
  "page.invoices.articles.table.EAN": "EAN",
  "page.invoices.articles.table.MAN": "Metro Artikelnr.",
  "page.invoices.filter": "Filtern",
  "page.invoices.listTitle": "Meine Rechnungen",
  "page.invoices.search": "Suche",
  "page.invoices.search.placeholder": "Suche nach Rechnungsnr., Name usw.",
  "page.settings.description": "Auf dieser Seite können Sie Ihre eInvoice-Einstellungen bearbeiten. Bitte beachten Sie, dass eine Änderung einige Minuten in Anspruch nimmt um vollständig verarbeitet zu werden.",
  "page.settings.emailNotifications": "Benachrichtigung über Rechnung per E-Mail erhalten",
  "page.settings.emailNotifications.description": "Wenn Sie diese Option aktivieren, erhalten Sie in Zukunft eine eMail-Benachrichtung für jedes neue Dokument in eInvoice.",
  "page.settings.printAtCheckout": "Andruck der Rechnung an Kasse notwendig",
  "page.settings.printAtCheckout.description": "Wenn Sie diese Option aktivieren, erhalten Sie eine gedruckte Rechnung an der Marktkasse. Deaktivieren Sie diese Option, wenn Sie in Zukunft keine gedruckte Rechnung mehr erhalten möchten. Sie können weiterhin auf alle Ihre digitalen Dokumente über eInvoice zugreifen.",
  "page.settings.title": "eInvoice Einstellungen",
  "page.settings.update": "Einstellungen anpassen",
  "page.settings.update.submitted": "Deine Einstellungen werden übertragen. Die Bearbeitung kann einige Zeit in Anspruch nehmen.",
  "page.settings.update.successful": "Einstellungen erfolgreich gespeichert."
},"en":{
  "all.cards": "all cards",
  "app.banner.button": "Use our app",
  "app.banner.description": "Access your invoices anytime and anywhere",
  "app.meta.description": "Invoices? Preferably digital! Simply cancel your paper invoice now.",
  "common.cancel": "Cancel",
  "common.card": "Card",
  "common.customer": "Customer",
  "common.dataPrivacy": "Privacy",
  "common.date": "Date",
  "common.date.placeholder": "yyyy-mm-dd",
  "common.download": "Download",
  "common.downloadPdf": "Download .pdf",
  "common.downloadSelected": "Download Selected",
  "common.from": "From",
  "common.invoiceNumber": "Invoice No.",
  "common.legalNotice": "Terms & Conditions",
  "common.limit": "Invoices Shown:",
  "common.netAmount": "Net Amount",
  "common.next": "Next",
  "common.noResults": "No results",
  "common.payment": "Payment",
  "common.previous": "Previous",
  "common.to": "To",
  "common.totalAmount": "Total Amount",
  "common.transaction.type.COD": "COD",
  "common.transaction.type.CONI": "Consolidated Invoice",
  "common.transaction.type.DELIVERY_SLIP": "Delivery slip",
  "common.transaction.type.DOWN_PAYMENT": "Down payment",
  "common.transaction.type.EMPTIES": "Empties",
  "common.transaction.type.INVOICE": "Invoice",
  "common.transaction.type.PACKING_LIST": "Packing List",
  "common.transaction.type.PAYMENT_RECEIPT": "Payment receipt",
  "common.transaction.type.PAY_IN_CHARGE_REFUND": "Pay in charge refund",
  "common.transaction.type.REIMBURSEMENT_DOWN_PAYMENT": "Reimbursement down payment",
  "common.transaction.type.RETURN": "Return",
  "common.transaction.type.UNKNOWN": "Unknown",
  "common.transaction.type.VOIDING_INVOICE": "Voiding invoice",
  "common.type": "Type",
  "download.error.invoice": "There were some issues downloading your invoice, please try again later.",
  "help.button.text": "Help & Support",
  "invoices.card.header": "Invoices for {0}",
  "invoices.card.show": "Show invoices",
  "maintenance.message": "We're currently facing some issues with our system, but we're working hard to fix the problem. Please be patient. We will be fully up and running soon.",
  "nav.invoices": "Invoices",
  "nav.logout": "Log Out",
  "nav.onlineShop": "Online Shop",
  "nav.overview": "Overview",
  "nav.settings": "Settings",
  "page.error.cardholderDependency": "Cannot find your accounts",
  "page.error.cardholderDependency.message": "We cannot find your accounts due to some issues. Please try again later.",
  "page.error.default": "Whoops! An error occurred!",
  "page.error.default.message": "An error occurred when performing the specified request. Please try again later!",
  "page.error.forbidden": "Forbidden",
  "page.error.forbidden.message": "You are not allowed to do that. Sorry for the inconvenience.",
  "page.error.invalidSearchInput": "Please enter at least 3 characters",
  "page.error.noCardholder": "Cannot Access Invoices",
  "page.error.noCardholder.message": "We cannot access any invoices for you. Please try logging in with a Metro Customer account.",
  "page.error.notFound": "Page Not Found",
  "page.error.notFound.message": "The resource you were looking for was not found.",
  "page.error.no_available_cards": "No available cards",
  "page.error.no_available_cards.message": "You are not authorised yet to see invoice data. Please finish your user registration process.",
  "page.error.resourceAccess": "Problem while fetching data",
  "page.error.resourceAccess.message": "Something went wrong on our side and it took too long to process your request. Please try again later!",
  "page.error.sorry": "We apologize!",
  "page.error.statusCode": "Status code",
  "page.error.traceIdMessage": "The error code for this error is:",
  "page.error.wrongSearchInput": "Input should only contain letters (A-Z, a-z), digits (0-9) and these characters: /.,-_",
  "page.impersonation.attention": "With great power comes great responsibility: be careful what you're doing. All state changes (modifying settings, mark invoices as read) will also affect to the impersonate customer.",
  "page.impersonation.card.help": "Please enter a numerical card number",
  "page.impersonation.card.placeholder": "CardID",
  "page.impersonation.confirm": "I confirm that I'm allowed to become and act like this customer.",
  "page.impersonation.confirm.description": "Please keep in mind that there is no check if this customer really exists. If you become a non existing customer, you act like a customer who has no data.",
  "page.impersonation.customer.help": "Please enter a numerical customer number",
  "page.impersonation.customer.placeholder": "CustomerID",
  "page.impersonation.description": "You can use this form to become a specific customer and use einvoice in his context for support purposes.",
  "page.impersonation.info.description": "click to configure",
  "page.impersonation.info.title": "Impersonation access",
  "page.impersonation.store.help": "Please enter a numerical store number",
  "page.impersonation.store.placeholder": "StoreID",
  "page.impersonation.title": "Impersonation",
  "page.impersonation.update": "Apply",
  "page.impersonation.update.error": "Form submission failed. Please check validation errors.",
  "page.impersonation.update.successful": "User successfully changed. Use logout to quit impersonation.",
  "page.invoices.articles.table.description": "Description",
  "page.invoices.articles.table.EAN": "EAN",
  "page.invoices.articles.table.MAN": "Metro Article No.",
  "page.invoices.filter": "Filter",
  "page.invoices.listTitle": "My Invoices",
  "page.invoices.search": "Search",
  "page.invoices.search.placeholder": "Search for articles, invoice number etc.",
  "page.overview.availableAt": "available at beginning of",
  "page.overview.availableUntil": "available until end of",
  "page.overview.button.empty": "empty file",
  "page.overview.description": "This site provides a downloadable list of purchased articles and payments. Files are provided as spreadsheet documents which can be opened with spreadsheet application of your choice (e.g. Microsoft Excel).",
  "page.overview.downloadOds": "download .ods",
  "page.overview.export.fallback.VAT_CODES_UNKNOWN": "Unknown vat code {0}",
  "page.overview.export.header.MO_PM_TABLE_AMOUNT": "Amount",
  "page.overview.export.header.MO_PM_TABLE_CARD_TYPE_DESC": "Card type description",
  "page.overview.export.header.MO_PM_TABLE_CURRENCY_CODE": "Currency code",
  "page.overview.export.header.MO_PM_TABLE_FISCAL_MEDIA_DESC": "Fiscal media description",
  "page.overview.export.header.MO_PM_TABLE_MEDIA_DESC": "Media Description",
  "page.overview.export.header.MO_TABLE_ARTICLE_DESCRIPTION": "Article description",
  "page.overview.export.header.MO_TABLE_ARTICLE_GROUP_NAME": "Article Group",
  "page.overview.export.header.MO_TABLE_ARTICLE_NUMBER": "Article number",
  "page.overview.export.header.MO_TABLE_BUYING_STORE": "Buying Store",
  "page.overview.export.header.MO_TABLE_CARDHOLDER_FIRST_NAME": "First Name",
  "page.overview.export.header.MO_TABLE_CARDHOLDER_LAST_NAME": "Last Name",
  "page.overview.export.header.MO_TABLE_CARD_NUMBER": "Card number",
  "page.overview.export.header.MO_TABLE_CUSTOMER_NAME": "Customer name",
  "page.overview.export.header.MO_TABLE_CUSTOMER_TYPE": "Customer Type",
  "page.overview.export.header.MO_TABLE_GTIN_NUMBER": "GTIN",
  "page.overview.export.header.MO_TABLE_INVOICE_DATE": "Invoice date",
  "page.overview.export.header.MO_TABLE_INVOICE_NUMBER": "Invoice number",
  "page.overview.export.header.MO_TABLE_INVOICE_TYPE": "Invoice Type",
  "page.overview.export.header.MO_TABLE_LOT_NUMBER": "Lot number",
  "page.overview.export.header.MO_TABLE_ORDER_ID": "Order ID",
  "page.overview.export.header.MO_TABLE_PRICE_GROSS": "Price gross",
  "page.overview.export.header.MO_TABLE_PRICE_GROSS_WITH_DISCOUNT": "Gross Discounted Price",
  "page.overview.export.header.MO_TABLE_PRICE_NET": "Price net",
  "page.overview.export.header.MO_TABLE_PRICE_PER_PIECE_KG": "Price/Piece/KG",
  "page.overview.export.header.MO_TABLE_PRICE_PER_SELLING_UNIT_NET": "Price/Selling Unit net",
  "page.overview.export.header.MO_TABLE_QUANTITY": "Quantity",
  "page.overview.export.header.MO_TABLE_QUANTITY_PER_SELLING_UNIT": "Quantity/Selling Unit",
  "page.overview.export.header.MO_TABLE_TAXES_AND_FEES": "Taxes & Fees",
  "page.overview.export.header.MO_TABLE_VAT": "VAT",
  "page.overview.export.sheet.ARTICLE": "Articles",
  "page.overview.export.sheet.PAYMENT": "Payments",
  "page.overview.subDescription": "Past 13 months reports are made available and there is no restriction when to download",
  "page.overview.title": "Purchase overview",
  "page.settings.description": "On this page you're able to edit your eInvoice preferences. Please note that changing preferences take some minutes to get fully processed.",
  "page.settings.emailNotifications": "Send e-mail notifications",
  "page.settings.emailNotifications.description": "If you enable this option you'll receive a email notification for every new document in eInvoice.",
  "page.settings.printAtCheckout": "Print invoice at checkout",
  "page.settings.printAtCheckout.description": "If you enable this option you'll receive a printed invoice during store checkout. Deactivate this option if you don't want to receive a printed invoice anymore. You're still able to access all your digital documents through eInvoice.",
  "page.settings.printSuppression": "Save paper, invoice digitally",
  "page.settings.printSuppression.description": "If you activate this option, you will not receive any printed invoices at checkout and save paper.",
  "page.settings.title": "eInvoice Settings",
  "page.settings.update": "Save Settings",
  "page.settings.update.submitted": "Your change has been submitted. It may take a while to process. Please be patient.",
  "page.settings.update.successful": "Settings sucessfully updated",
  "personal.card": "Personal Card",
  "warning.business.has.no.valid.card": "Please select a business from your account to see invoices, or business has NO VALID CARD."
},"es":{
  "common.cancel": "Cancelado",
  "common.card": "Tarjeta",
  "common.customer": "Cliente",
  "common.dataPrivacy": "Privacidad",
  "common.date": "Fecha",
  "common.date.placeholder": "aaaa-mm-dd",
  "common.download": "Descargar",
  "common.downloadPdf": "Ver factura",
  "common.downloadSelected": "Descargar selección",
  "common.from": "Desde",
  "common.invoiceNumber": "Nº Factura",
  "common.legalNotice": "Términos y condiciones",
  "common.limit": "Facturas mostradas:",
  "common.netAmount": "Importe neto",
  "common.next": "Siguiente",
  "common.previous": "Anterior",
  "common.to": "Hasta",
  "common.totalAmount": "Importe total",
  "common.transaction.type.CONI": "Factura consolidada",
  "common.transaction.type.DELIVERY_SLIP": "Delivery slip",
  "common.transaction.type.DOWN_PAYMENT": " ",
  "common.transaction.type.EMPTIES": "Factura de envases",
  "common.transaction.type.INVOICE": "Factura",
  "common.transaction.type.PACKING_LIST": "Packing list",
  "common.transaction.type.PAYMENT_RECEIPT": "Factura pago a la entrega",
  "common.transaction.type.RETURN": "Factura de devolución",
  "common.transaction.type.UNKNOWN": "Factura",
  "common.transaction.type.VOIDING_INVOICE": "Factura anulada",
  "common.type": "Tipo",
  "invoices.card.header": "Facturas  ",
  "invoices.card.show": "Mostrar facturas",
  "maintenance.message": "Estamos teniendo algunos problemas con nuestro sistema, pero estamos trabajando para solucionarlos lo antes posible. Sentimos las molestias ocasionadas, estaremos de nuevo activos muy pronto.",
  "nav.invoices": "Facturas",
  "nav.logout": "Cerrar sesión",
  "nav.onlineShop": "Detalle mensual",
  "nav.overview": "Resumen",
  "nav.settings": "Ajustes",
  "page.error.cardholderDependency.message": "No podemos reconocer su número de cliente. Por favor inténtelo más tarde.",
  "page.error.default": "Se ha producido un error!",
  "page.error.default.message": "Se ha producido un error al realizar la consulta indicada. Por favor, inténtelo más tarde!",
  "page.error.forbidden": "Prohibido",
  "page.error.forbidden.message": "No tiene privilegios para realizar esta acción. Disculpe las molestias.",
  "page.error.invalidSearchInput": "Por favor introduzca al menos 3 caracteres",
  "page.error.noCardholder": "No se puede acceder a las facturas",
  "page.error.notFound": "Página no encontrada",
  "page.error.notFound.message": "La consulta que está realizando no obtuvo resultados.",
  "page.error.sorry": "Disculpe",
  "page.error.traceIdMessage": "El código de error para esta incidencia es:",
  "page.impersonation.confirm.description": " ",
  "page.impersonation.customer.help": "Por favor introduzca un número de cliente",
  "page.impersonation.customer.placeholder": "Nº Cliente",
  "page.impersonation.store.help": "Por favor, introduzca un número de Centro",
  "page.impersonation.store.placeholder": "Nº de centro",
  "page.impersonation.update": "Aplicar",
  "page.invoices.articles.table.description": "Descripción",
  "page.invoices.articles.table.EAN": "Código EAN",
  "page.invoices.articles.table.MAN": "Nº artículo Makro",
  "page.invoices.filter": "Filtro",
  "page.invoices.listTitle": "Mis facturas",
  "page.invoices.search": "Buscar",
  "page.invoices.search.placeholder": "Buscar por nº de factura, descripción artículo, EAN",
  "page.overview.availableAt": "Disponible a comienzos de",
  "page.overview.availableUntil": "Disponible hasta finales de",
  "page.overview.description": "Aquí podrás encontrar el detalle de tus compras, incluyendo los tres  últimos meses. Puedes descargar esta información en un fichero Excel para facilitarte su manejo.",
  "page.overview.downloadOds": "Descarga",
  "page.overview.export.fallback.VAT_CODES_UNKNOWN": "Impuesto desconocido",
  "page.overview.export.header.MO_TABLE_ARTICLE_DESCRIPTION": "Descripción Art.",
  "page.overview.export.header.MO_TABLE_ARTICLE_GROUP_NAME": "Grupo artículo",
  "page.overview.export.header.MO_TABLE_ARTICLE_NUMBER": "Nº Articulos",
  "page.overview.export.header.MO_TABLE_BUYING_STORE": "Centro ",
  "page.overview.export.header.MO_TABLE_CARD_NUMBER": "Nº Tarjeta",
  "page.overview.export.header.MO_TABLE_CUSTOMER_NAME": "Cliente",
  "page.overview.export.header.MO_TABLE_GTIN_NUMBER": "GTIN",
  "page.overview.export.header.MO_TABLE_INVOICE_DATE": "Fecha Factura",
  "page.overview.export.header.MO_TABLE_INVOICE_NUMBER": "Nº Factura",
  "page.overview.export.header.MO_TABLE_LOT_NUMBER": "Nº Lote",
  "page.overview.export.header.MO_TABLE_ORDER_ID": "Nº Pedido",
  "page.overview.export.header.MO_TABLE_PRICE_GROSS": "Precio Bruto",
  "page.overview.export.header.MO_TABLE_PRICE_NET": "Precio Neto",
  "page.overview.export.header.MO_TABLE_PRICE_PER_PIECE_KG": "Precio/Pieza/Kg",
  "page.overview.export.header.MO_TABLE_PRICE_PER_SELLING_UNIT_NET": "Precio unitario de venta",
  "page.overview.export.header.MO_TABLE_QUANTITY": "Cantidad",
  "page.overview.export.header.MO_TABLE_QUANTITY_PER_SELLING_UNIT": "Cantidad/Unidad de venta",
  "page.overview.export.header.MO_TABLE_TAXES_AND_FEES": "Tasas e Impuestos",
  "page.overview.export.header.MO_TABLE_VAT": "Impuesto",
  "page.overview.subDescription": " ",
  "page.overview.title": "Resumen de Compras",
  "page.settings.description": "En esta página puedes editar las preferencias de tu factura digital. Para que dicha actualización sea realizada correctamente, el proceso necesitará unos minutos. Disculpa las molestias.",
  "page.settings.emailNotifications": "Notificacion vía e-mail",
  "page.settings.emailNotifications.description": "Marca la casilla de “Notificación vía email” y guarda los cambios para empezar a recibir tus facturas en formato digital en tu dirección de email cada vez que realices una compra en Makro. Si no recibes tus facturas en tu email, recuerda mirar en la carpeta “No deseados” de tu correo por si hubiera entrado ahí.",
  "page.settings.printAtCheckout": "Imprimir factura en Cajas",
  "page.settings.printAtCheckout.description": "Si desmarcas esta casilla, no recibirás la factura en papel, sino en formato digital con total validez legal y fiscal. Siempre tendrás disponibles tus facturas en nuestra aplicación MAKRO COMPANION y en el apartado correspondiente en tu área personal MAKRO.",
  "page.settings.printSuppression": "Ahorre papel, facture digitalmente",
  "page.settings.printSuppression.description": "Si activas esta opción, no recibirás ninguna factura impresa al finalizar la compra y ahorrarás papel.",
  "page.settings.title": "Ajustes Factura digital",
  "page.settings.update": "Guardar cambios",
  "page.settings.update.submitted": "Sus cambios han sido tramitados. Esta acción podrían tomar algún tiempo. Rogamos tenga paciencia.",
  "page.settings.update.successful": "Ajustes actualizados correctamente"
},"fr":{
  "all.cards": "Toutes les cartes",
  "common.card": "Carte",
  "common.dataPrivacy": "Cookies Metro",
  "common.date": "Date facture",
  "common.date.placeholder": "aaaa-mm-jj",
  "common.download": "Téléchargement",
  "common.downloadPdf": "Téléchargement PDF",
  "common.downloadSelected": "Télécharger la sélection",
  "common.from": "De",
  "common.invoiceNumber": "N° Facture",
  "common.legalNotice": "Mentions légales",
  "common.limit": "Affichage",
  "common.netAmount": "Total HT",
  "common.next": "Suivant",
  "common.noResults": "Pas de résultat",
  "common.previous": "Précédent",
  "common.to": "À",
  "common.totalAmount": "Total TTC",
  "common.transaction.type.CONI": "Facture consolidée",
  "common.transaction.type.DELIVERY_SLIP": "Bordereau de livraison",
  "common.transaction.type.DOWN_PAYMENT": "Acompte",
  "common.transaction.type.EMPTIES": "Consignes",
  "common.transaction.type.INVOICE": "Facture",
  "common.transaction.type.PACKING_LIST": "Bordereau d‘expédition",
  "common.transaction.type.PAYMENT_RECEIPT": "Reçu",
  "common.transaction.type.PAY_IN_CHARGE_REFUND": "Regule dette",
  "common.transaction.type.REIMBURSEMENT_DOWN_PAYMENT": "Remboursement d’acompte",
  "common.transaction.type.RETURN": "Retour",
  "common.transaction.type.UNKNOWN": "Inconnu",
  "common.transaction.type.VOIDING_INVOICE": "Annulation",
  "common.type": "Type",
  "invoices.per.page.filter": "Factures/page",
  "nav.invoices": "Mes Factures",
  "nav.logout": "Déconnexion",
  "nav.onlineShop": "Mes produits",
  "nav.overview": "Aperçu",
  "nav.settings": "Paramètres",
  "page.error.default": "Une erreur est survenue.",
  "page.error.default.message": "Une erreur est survenue lors du traitement de votre requête. Merci de bien vouloir réessayer plus tard.",
  "page.error.invalidSearchInput": "Données d’entrée invalides.",
  "page.error.noCardholder": "Factures inaccessibles",
  "page.error.noCardholder.message": "Les factures sont inaccessibles. Veuillez vous connecter à nouveau avec votre compte client metro.fr.",
  "page.error.notFound": "Page introuvable",
  "page.error.notFound.message": "La page que vous recherchez est introuvable.",
  "page.error.sorry": "Désolé",
  "page.invoices.articles.table.description": "Description",
  "page.invoices.articles.table.EAN": "EAN",
  "page.invoices.articles.table.MAN": "N° d’article Metro",
  "page.invoices.filter": "Filtrer",
  "page.invoices.listTitle": "Mes Factures",
  "page.invoices.search": "Rechercher",
  "page.invoices.search.placeholder": "Rechercher n° facture, désignation article, etc.",
  "page.overview.availableAt": "Disponible à partir de : ",
  "page.overview.availableUntil": "Disponible jusqu’à : ",
  "page.overview.description": "Cette page vous permet de télécharger un listing des produits que vous avez acheté chaque mois. Les fichiers peuvent être ouverts par une application gérant les feuilles de calcul (par exemple Microsoft Excel).",
  "page.overview.downloadOds": "Télécharger",
  "page.overview.export.fallback.VAT_CODES_UNKNOWN": "Code TVA inconnu {0}",
  "page.overview.export.header.MO_TABLE_ARTICLE_DESCRIPTION": "Description de l’article",
  "page.overview.export.header.MO_TABLE_ARTICLE_GROUP_NAME": "Groupe d’articles",
  "page.overview.export.header.MO_TABLE_ARTICLE_NUMBER": "N° de l’article",
  "page.overview.export.header.MO_TABLE_BUYING_STORE": "Halles d’achat",
  "page.overview.export.header.MO_TABLE_CARD_NUMBER": "Numéro de carte",
  "page.overview.export.header.MO_TABLE_CUSTOMER_NAME": "Nom de client",
  "page.overview.export.header.MO_TABLE_GTIN_NUMBER": "EAN",
  "page.overview.export.header.MO_TABLE_INVOICE_DATE": "Date de facture",
  "page.overview.export.header.MO_TABLE_INVOICE_NUMBER": "N° de facture",
  "page.overview.export.header.MO_TABLE_LOT_NUMBER": "N° de lot",
  "page.overview.export.header.MO_TABLE_ORDER_ID": "N° de commande",
  "page.overview.export.header.MO_TABLE_PRICE_GROSS": "Prix TTC",
  "page.overview.export.header.MO_TABLE_PRICE_NET": "Prix HT",
  "page.overview.export.header.MO_TABLE_PRICE_PER_PIECE_KG": "Prix/Pc/kg",
  "page.overview.export.header.MO_TABLE_PRICE_PER_SELLING_UNIT_NET": "Prix Vente d’unité (HT)",
  "page.overview.export.header.MO_TABLE_QUANTITY": "Quantité",
  "page.overview.export.header.MO_TABLE_QUANTITY_PER_SELLING_UNIT": "Nombre par Unité METRO",
  "page.overview.export.header.MO_TABLE_TAXES_AND_FEES": "Taxes & Frais",
  "page.overview.export.header.MO_TABLE_VAT": "TVA %",
  "page.overview.subDescription": "Les aperçus des 13 derniers mois sont disponibles au téléchargement.",
  "page.overview.title": "Aperçu mensuel",
  "page.settings.emailNotifications": "Recevoir des notifications par e-mails",
  "page.settings.title": "Paramétrages",
  "page.settings.update": "Modifier les paramètres"
},"fr_BE":{
  "common.card": "Carte",
  "common.dataPrivacy": "Cookies Metro",
  "common.date": "Date facture",
  "common.date.placeholder": "aaaa-mm-jj",
  "common.download": "Téléchargement",
  "common.downloadPdf": "Téléchargement PDF",
  "common.downloadSelected": "Télécharger la sélection",
  "common.from": "De",
  "common.invoiceNumber": "N° Facture",
  "common.legalNotice": "Mentions légales",
  "common.limit": "Affichage",
  "common.netAmount": "Total HT",
  "common.next": "Suivant",
  "common.noResults": "Pas de résultat",
  "common.previous": "Précédent",
  "common.to": "À",
  "common.totalAmount": "Total TTC",
  "common.transaction.type.CONI": "Facture consolidée",
  "common.transaction.type.DELIVERY_SLIP": "Bordereau de livraison",
  "common.transaction.type.DOWN_PAYMENT": "Acompte",
  "common.transaction.type.EMPTIES": "Consignes",
  "common.transaction.type.INVOICE": "Factures",
  "common.transaction.type.PACKING_LIST": "Bordereau d‘expédition",
  "common.transaction.type.PAYMENT_RECEIPT": "Reçu",
  "common.transaction.type.PAY_IN_CHARGE_REFUND": "Regule dette",
  "common.transaction.type.REIMBURSEMENT_DOWN_PAYMENT": "Remboursement d’acompte",
  "common.transaction.type.RETURN": "Retour",
  "common.transaction.type.UNKNOWN": "Inconnu",
  "common.transaction.type.VOIDING_INVOICE": "Annulation",
  "common.type": "Type",
  "nav.invoices": "Mes Factures",
  "nav.logout": "Déconnexion",
  "nav.onlineShop": "Mes produits",
  "nav.overview": "Aperçu",
  "nav.settings": "Paramètres",
  "page.error.default": "Une erreur est survenue.",
  "page.error.default.message": "Une erreur est survenue lors du traitement de votre requête. Merci de bien vouloir réessayer plus tard.",
  "page.error.noCardholder": "Factures inaccessibles",
  "page.error.noCardholder.message": "Les factures sont inaccessibles. Veuillez vous connecter à nouveau avec votre compte client metro.be",
  "page.error.notFound": "Page introuvable",
  "page.error.notFound.message": "La page que vous recherchez est introuvable.",
  "page.error.sorry": "Désolé",
  "page.invoices.articles.table.description": "Description",
  "page.invoices.articles.table.EAN": "EAN",
  "page.invoices.articles.table.MAN": "N° d’article Metro",
  "page.invoices.filter": "Filtrer",
  "page.invoices.listTitle": "Mes Factures",
  "page.invoices.search": "Rechercher",
  "page.invoices.search.placeholder": "Rechercher n° facture, désignation article, etc.",
  "page.overview.availableAt": "disponible à partir du début de",
  "page.overview.availableUntil": "disponible jusqu’à la fin de",
  "page.overview.description": "Sur cette page vous trouvez un aperçu mensuel de tous les articles que vous avez achetés ce mois. Cliquez sur le bouton ‘Télécharger’ pour ouvrir un fichier Excel qui contient toutes ces informations.",
  "page.overview.downloadOds": "Télécharger",
  "page.overview.export.header.MO_TABLE_ARTICLE_DESCRIPTION": "description de l’article",
  "page.overview.export.header.MO_TABLE_ARTICLE_GROUP_NAME": "Groupe d’articles",
  "page.overview.export.header.MO_TABLE_ARTICLE_NUMBER": "N° de l’article",
  "page.overview.export.header.MO_TABLE_BUYING_STORE": "Siège  d’achat",
  "page.overview.export.header.MO_TABLE_CARD_NUMBER": "Numéro de carte",
  "page.overview.export.header.MO_TABLE_CUSTOMER_NAME": "Nom de client",
  "page.overview.export.header.MO_TABLE_INVOICE_DATE": "Date de facture",
  "page.overview.export.header.MO_TABLE_INVOICE_NUMBER": "N° de facture",
  "page.overview.export.header.MO_TABLE_LOT_NUMBER": "N° de lot",
  "page.overview.export.header.MO_TABLE_ORDER_ID": "N° de commande",
  "page.overview.export.header.MO_TABLE_PRICE_GROSS": "Prix incl",
  "page.overview.export.header.MO_TABLE_PRICE_NET": "Prix excl",
  "page.overview.export.header.MO_TABLE_PRICE_PER_PIECE_KG": "Prix/Pc/kg",
  "page.overview.export.header.MO_TABLE_PRICE_PER_SELLING_UNIT_NET": "Prix Vente d’unité (excl)",
  "page.overview.export.header.MO_TABLE_QUANTITY": "Quantité",
  "page.overview.export.header.MO_TABLE_QUANTITY_PER_SELLING_UNIT": "Nombre par Unité METRO",
  "page.overview.export.header.MO_TABLE_TAXES_AND_FEES": "Taxes & Fees",
  "page.overview.export.header.MO_TABLE_VAT": "TVA %",
  "page.overview.subDescription": "Sachez que l’aperçu mensuel ne peut être consulté que pendant une période de 12 mois. Si vous voulez consulter le fichier plus tard, nous vous conseillons de télécharger et sauvegarder les documents sur votre ordinateur",
  "page.overview.title": "Aperçu mensuel",
  "page.settings.description": "Sur cette page, vous pouvez modifier vos préférences pour les E-factures. Veuillez noter que le traitement de ces modifications peut prendre quelques minutes.",
  "page.settings.emailNotifications": "Recevoir des notifications par e-mails",
  "page.settings.emailNotifications.description": "Si vous activez cette option, vous recevrez une notification par e-mail pour chaque nouveau document dans E-factures.",
  "page.settings.printAtCheckout.description": "Si vous activez cette option, vous recevrez une facture imprimée lors de votre passage en caisse en magasin. Si vous ne souhaitez plus recevoir de facture imprimée, désactivez cette option. Vous serez toujours en mesure d’accéder à tous vos documents numériques via E-factures et via l'application METRO.  ",
  "page.settings.title": "Paramétrages",
  "page.settings.update": "Modifier les paramètres"
},"HR":{
  "all.cards": "sve kartice",
  "app.banner.description": "Pristupite svojim računima bilo kada i bilo gdje",
  "common.cancel": "Otkazati",
  "common.card": "Kartica",
  "common.customer": "Kupac",
  "common.dataPrivacy": "Privatnost",
  "common.date": "Datum",
  "common.date.placeholder": "yyyy-mm-dd",
  "common.download": "Preuzimanje",
  "common.downloadPdf": "Preuzmite .pdf",
  "common.downloadSelected": "Preuzmi odabrano",
  "common.from": "Od",
  "common.invoiceNumber": "Račun br.",
  "common.legalNotice": "Uvjeti korištenja",
  "common.limit": "Prikazani Računi:",
  "common.netAmount": "Neto iznos",
  "common.next": "Sljedeći",
  "common.noResults": "Nema rezultata",
  "common.payment": "Plaćanje",
  "common.previous": "Prijašnji",
  "common.to": "Do",
  "common.totalAmount": "Ukupni iznos",
  "common.transaction.type.CONI": "Konsolidirani račun",
  "common.transaction.type.DOWN_PAYMENT": "Polog",
  "common.transaction.type.EMPTIES": "Ambalaža",
  "common.transaction.type.INVOICE": "Račun",
  "common.transaction.type.RETURN": "Povrat",
  "common.transaction.type.UNKNOWN": "Nepoznato",
  "common.type": "Tip",
  "invoices.card.header": "Računi za {0}",
  "invoices.card.show": "Prikaži račune",
  "maintenance.message": "Trenutno se suočavamo s nekim problemima s našim sustavom, ali naporno radimo na rješavanju problema. Molimo za strpljenje. Uskoro ćemo biti potpuno spremni za rad.",
  "nav.invoices": "Računi",
  "nav.logout": "Odjava",
  "nav.onlineShop": "Online trgovina",
  "nav.overview": "Pregled",
  "nav.settings": "Postavke",
  "page.error.default": "Ups! Dogodila se pogreška!",
  "page.error.default.message": "Došlo je do pogreške prilikom izvođenja navedenog zahtjeva. Molimo pokušajte ponovo kasnije!",
  "page.error.forbidden": "Zabranjeno",
  "page.error.invalidSearchInput": "Unesite najmanje 3 znaka",
  "page.error.noCardholder": "Nije moguće pristupiti računima",
  "page.error.noCardholder.message": "Ne možemo pristupiti vašim računima. Pokušajte se prijaviti s Metro korisničkim računom.",
  "page.error.notFound": "Stranica nije pronađena",
  "page.error.no_available_cards": "Nema dostupnih kartica",
  "page.error.resourceAccess": "Problem prilikom dohvaćanja podataka",
  "page.error.sorry": "Ispričavamo se!",
  "page.error.statusCode": "Šifra stanja",
  "page.error.traceIdMessage": "Šifra pogreške za ovu pogrešku je:",
  "page.error.wrongSearchInput": "Unos treba sadržavati samo slova (A-Z, a-z), znamenke (0-9) i ove znakove: /.,-_",
  "page.impersonation.card.help": "Unesite numerički broj kartice",
  "page.impersonation.card.placeholder": "ID kartice",
  "page.impersonation.confirm": "Potvrđujem da mi je dopušteno postati i ponašati se kao ovaj kupac.",
  "page.impersonation.customer.help": "Unesite numerički broj kupca",
  "page.impersonation.customer.placeholder": "ID kupca",
  "page.impersonation.info.description": "kliknite za konfiguraciju",
  "page.impersonation.store.help": "Unesite numerički broj trgovine",
  "page.impersonation.store.placeholder": "ID trgovine",
  "page.impersonation.update": "Primijeniti",
  "page.invoices.articles.table.description": "Opis",
  "page.invoices.articles.table.EAN": "EAN",
  "page.invoices.articles.table.MAN": "Metro artikl br.",
  "page.invoices.filter": "Filtar",
  "page.invoices.listTitle": "Moji računi",
  "page.invoices.search": "Pretraživanje",
  "page.invoices.search.placeholder": "Tražite artikle, broj računa itd.",
  "page.overview.availableAt": "dostupno na početku",
  "page.overview.availableUntil": "dostupno do kraja",
  "page.overview.button.empty": "prazna datoteka",
  "page.overview.description": "Ova stranica nudi popis kupljenih artikala i plaćanja za preuzimanje. Datoteke se daju kao dokumenti proračunske tablice koji se mogu otvoriti pomoću aplikacije za proračunske tablice po vašem izboru (npr. Microsoft Excel).",
  "page.overview.downloadOds": "Preuzmite .ods",
  "page.overview.export.header.MO_PM_TABLE_CARD_TYPE_DESC": "Opis vrste kartice",
  "page.overview.export.header.MO_PM_TABLE_CURRENCY_CODE": "Šifra valute",
  "page.overview.export.header.MO_PM_TABLE_FISCAL_MEDIA_DESC": "Opis fiskalnog medija",
  "page.overview.export.header.MO_PM_TABLE_MEDIA_DESC": "Opis medija",
  "page.overview.export.header.MO_TABLE_ARTICLE_DESCRIPTION": "Opis artikla",
  "page.overview.export.header.MO_TABLE_ARTICLE_GROUP_NAME": "Grupa artikla",
  "page.overview.export.header.MO_TABLE_ARTICLE_NUMBER": "Broj artikla",
  "page.overview.export.header.MO_TABLE_BUYING_STORE": "Trgovina za kupnju",
  "page.overview.export.header.MO_TABLE_CARDHOLDER_FIRST_NAME": "Ime",
  "page.overview.export.header.MO_TABLE_CARDHOLDER_LAST_NAME": "Prezime",
  "page.overview.export.header.MO_TABLE_CARD_NUMBER": "Broj kartice",
  "page.overview.export.header.MO_TABLE_CUSTOMER_NAME": "Ime kupca",
  "page.overview.export.header.MO_TABLE_GTIN_NUMBER": "GTIN",
  "page.overview.export.header.MO_TABLE_INVOICE_DATE": "Datum računa",
  "page.overview.export.header.MO_TABLE_INVOICE_NUMBER": "Broj računa",
  "page.overview.export.header.MO_TABLE_LOT_NUMBER": "Lot broj",
  "page.overview.export.header.MO_TABLE_ORDER_ID": "ID narudžbe",
  "page.overview.export.header.MO_TABLE_PRICE_GROSS": "Cijena bruto",
  "page.overview.export.header.MO_TABLE_PRICE_NET": "Cijena neto",
  "page.overview.export.header.MO_TABLE_PRICE_PER_PIECE_KG": "Cijena/kom/KG",
  "page.overview.export.header.MO_TABLE_QUANTITY": "Količina",
  "page.overview.export.header.MO_TABLE_QUANTITY_PER_SELLING_UNIT": "Količina/prodajna jedinica",
  "page.overview.export.header.MO_TABLE_TAXES_AND_FEES": "Porezi i naknade",
  "page.overview.export.header.MO_TABLE_VAT": "PDV",
  "page.overview.export.sheet.ARTICLE": "Artikli",
  "page.overview.export.sheet.PAYMENT": "Plaćanja",
  "page.overview.subDescription": "Izvješća za proteklih 13 mjeseci su dostupna i nema ograničenja kada se preuzimaju",
  "page.overview.title": "Pregled kupnje",
  "page.settings.description": "Na ovoj stranici možete urediti svoje postavke Računa. Imajte na umu da je za potpunu obradu promjene postavki potrebno nekoliko minuta.",
  "page.settings.emailNotifications": "Slanje obavijesti e-poštom",
  "page.settings.emailNotifications.description": "Ako uključite ovu opciju, primit ćete e-mail obavijest za svaki novi Račun.",
  "page.settings.printAtCheckout": "Ispis računa na blagajni",
  "page.settings.printAtCheckout.description": "Ako omogućite ovu opciju, primit ćete ispisani račun tijekom naplate u trgovini. Isključite ovu opciju ako više ne želite primati ispisani račun. Još uvijek možete pristupiti svim svojim digitalnim dokumentima.",
  "page.settings.printSuppression": "Uštedite papir, fakturirajte digitalno",
  "page.settings.printSuppression.description": "Ako aktivirate ovu opciju, nećete dobiti ispis računa na blagajni i štedite papir.",
  "page.settings.title": "Postavke Računa",
  "page.settings.update": "Spremi postavke",
  "page.settings.update.submitted": "Vaša promjena je poslana. Obrada može potrajati neko vrijeme. Molimo za strpljenje.",
  "page.settings.update.successful": "Postavke su uspješno ažurirane",
  "personal.card": "Osobna kartica"
},"hu":{
  "all.cards": "Minden kártya",
  "common.cancel": "Mégse",
  "common.card": "Kártya",
  "common.customer": "Vevő",
  "common.dataPrivacy": "Adatvédelem",
  "common.date": "Dátum",
  "common.date.placeholder": "éééé-hh-nn",
  "common.download": "letöltése",
  "common.downloadPdf": ".pdf letöltése",
  "common.downloadSelected": "Megjelöltek letöltése",
  "common.from": "-tól",
  "common.invoiceNumber": "Számlaszám",
  "common.legalNotice": "Általános Vásárlási Feltételek",
  "common.limit": "Megjelenített számlák:",
  "common.netAmount": "Nettó összeg",
  "common.next": "Következő oldal",
  "common.noResults": "Nincs találat",
  "common.payment": "Fizetésmód",
  "common.previous": "Előző oldal",
  "common.to": "-ig",
  "common.totalAmount": "Bruttó összeg",
  "common.transaction.type.COD": "COD",
  "common.transaction.type.CONI": "Gyűjtőszámla",
  "common.transaction.type.DELIVERY_SLIP": "Fuvarlevél",
  "common.transaction.type.DOWN_PAYMENT": "Előleg",
  "common.transaction.type.EMPTIES": "Göngyöleg",
  "common.transaction.type.INVOICE": "Számla",
  "common.transaction.type.PACKING_LIST": "Szállítólevél",
  "common.transaction.type.PAYMENT_RECEIPT": "Valós fiz.mód",
  "common.transaction.type.PAY_IN_CHARGE_REFUND": "Visszafizetés",
  "common.transaction.type.REIMBURSEMENT_DOWN_PAYMENT": "Előleg visszatérítés",
  "common.transaction.type.RETURN": "Visszáru",
  "common.transaction.type.UNKNOWN": "Számla",
  "common.transaction.type.VOIDING_INVOICE": "Sztornó számla",
  "common.type": "Típus",
  "invoices.card.header": "Számla {0}",
  "invoices.card.show": "Számlák mutatása",
  "maintenance.message": "A rendszer jelenleg nem működik megfelelően. A probléma elhárításán már dolgozunk.\nKérjük próbálja meg késöbb.\nMegértését köszönjük.",
  "nav.invoices": "Számlák",
  "nav.logout": "Kijelentkezés",
  "nav.onlineShop": "Online Shop",
  "nav.overview": "Áttekintés",
  "nav.settings": "Beállítások",
  "page.error.cardholderDependency": "A felhasználó nem található",
  "page.error.cardholderDependency.message": "Nem sikerült a felhasználó azonosítás. Próbálja meg késöbb. ",
  "page.error.default": "Upsz! Hiba lépett fel!",
  "page.error.default.message": "Hiba lépett fel a kérés teljesítése során, próbálja meg később újra!",
  "page.error.forbidden": "Megtagadva",
  "page.error.forbidden.message": "Önnek nincs hozzáférése az adatokhoz.",
  "page.error.invalidSearchInput": "Kérjük legalább 3 karakter adjon meg",
  "page.error.noCardholder": "Nincs hozzáférése a számlához",
  "page.error.noCardholder.message": "\nA számlák nem érhetők el. Kérjük, jelentkezzen be újra.",
  "page.error.notFound": "Az oldal nem található",
  "page.error.notFound.message": "A keresett forrás/anyag nem található.",
  "page.error.no_available_cards": "Nincsenek elérhető kártyák",
  "page.error.no_available_cards.message": "Még nincs jogosultsága a számlaadatok megtekintéséhez. Kérjük, fejezze be a felhasználói regisztrációs folyamatot.",
  "page.error.resourceAccess": "Probléma az adatok betöltésekor.",
  "page.error.resourceAccess.message": "A kérés feldolgozása hosszabb időt fog igénybe venni. Kérjük próbálja meg késöbb.",
  "page.error.sorry": "Szíves elnézését kérjük!",
  "page.error.statusCode": "Státusz kód:",
  "page.error.traceIdMessage": "Hiba kód:",
  "page.impersonation.attention": "Kérjük, vegye figyelembe a beállítások módosításakor (például a számlák olvasottként jelölése). Az állapotváltozás az ügyfél megszemélyesítésének tekinthető.",
  "page.impersonation.card.help": "Kérjük adja meg a kártya számát",
  "page.impersonation.card.placeholder": "Kártyaszám",
  "page.impersonation.confirm": "Megerősítem, hogy a felhasználóm úgy viselkedjen mint a kiválasztott vevőé.",
  "page.impersonation.confirm.description": "Felhívjuk figyelmét, hogy nem ellenőrzik, hogy ez az ügyfél valóban létezik-e. Ha nem létező vevőt ad meg, úgy viselkedik, mint vevő, számlaadatok nélkül.",
  "page.impersonation.customer.help": "Kérjük adja meg a vevő számát",
  "page.impersonation.customer.placeholder": "Vevőszám",
  "page.impersonation.description": "Ezt az űrlapot az e-számla támogatási célokra történő felhasználására használhatja egy adott ügyféllel összefüggésben.",
  "page.impersonation.info.description": "kattintson a beállításokhoz",
  "page.impersonation.info.title": "Megszemélyesítés",
  "page.impersonation.store.help": "Kérjük adja meg az áruház számát",
  "page.impersonation.store.placeholder": "Áruház szám",
  "page.impersonation.title": "Megszemélyesítés",
  "page.impersonation.update": "Alkalmaz",
  "page.impersonation.update.error": "Az űrlap rögzítése nem sikerült. Kérjük ellenőrizze az ellenőrzési hibaüzeneteket.",
  "page.impersonation.update.successful": "A felhasználó beállítások módosítása sikeresen megtörtént.\nAz új beállításokhoz életbelépéséhez jelentkezzen ki és be az alkalmazásból.",
  "page.invoices.articles.table.description": "Megnevezés",
  "page.invoices.articles.table.EAN": "Vonalkód",
  "page.invoices.articles.table.MAN": "METRO cikkszám",
  "page.invoices.filter": "Szűrő",
  "page.invoices.listTitle": "Saját E-számláim",
  "page.invoices.search": "Keresés",
  "page.invoices.search.placeholder": "Keresés számlaszám, név, stb. alapján",
  "page.overview.availableAt": "Elérhető",
  "page.overview.availableUntil": " elérhető a következő időpontig:",
  "page.overview.description": "Erről az oldalról az adott vásárlói kártyával az előző időszakban történt vásárlások adatai tölthetők le, havi bontásban. A letöltött fájlok táblázat kezelő alkalmazás (pl. Microsoft Excel) segítségével nyithatók meg.\n",
  "page.overview.downloadOds": "Letöltés",
  "page.overview.export.fallback.VAT_CODES_UNKNOWN": "ismeretlen áfa kód",
  "page.overview.export.header.MO_TABLE_ARTICLE_DESCRIPTION": "Termék megnevezés",
  "page.overview.export.header.MO_TABLE_ARTICLE_GROUP_NAME": "Termék csoport",
  "page.overview.export.header.MO_TABLE_ARTICLE_NUMBER": "Cikkszám",
  "page.overview.export.header.MO_TABLE_BUYING_STORE": "Áruház",
  "page.overview.export.header.MO_TABLE_CARD_NUMBER": "Kártyaszám",
  "page.overview.export.header.MO_TABLE_CUSTOMER_NAME": "Vevő neve",
  "page.overview.export.header.MO_TABLE_GTIN_NUMBER": "GTIN",
  "page.overview.export.header.MO_TABLE_INVOICE_DATE": "Számlázás dátuma",
  "page.overview.export.header.MO_TABLE_INVOICE_NUMBER": "Számlaszám",
  "page.overview.export.header.MO_TABLE_LOT_NUMBER": "LOT szám",
  "page.overview.export.header.MO_TABLE_ORDER_ID": "Rendelésszám",
  "page.overview.export.header.MO_TABLE_PRICE_GROSS": "Bruttó",
  "page.overview.export.header.MO_TABLE_PRICE_NET": "Nettó ár",
  "page.overview.export.header.MO_TABLE_PRICE_PER_PIECE_KG": "Ár/Mennyiség/Súly",
  "page.overview.export.header.MO_TABLE_PRICE_PER_SELLING_UNIT_NET": "Ár/Eladási egység",
  "page.overview.export.header.MO_TABLE_QUANTITY": "Menniység",
  "page.overview.export.header.MO_TABLE_QUANTITY_PER_SELLING_UNIT": "Mennyiség/Eladási egység",
  "page.overview.export.header.MO_TABLE_TAXES_AND_FEES": "ADÓ",
  "page.overview.export.header.MO_TABLE_VAT": "ÁFA",
  "page.overview.subDescription": "Felhívjuk figyelmét, hogy a havi áttekintések csak limitált ideig tekinthetőek meg. \nAmennyiben később is szeretné használni az adatokat, célszerű lementeni a fájlokat a számítógépére/mobil eszközére.",
  "page.overview.title": "Vásárlási összesítő",
  "page.settings.description": "Ezen az oldalon szerkesztheti az e-számla beállításait. Kérjük, vegye figyelembe, hogy a beállítások megváltoztatása néhány percet vesz igénybe a teljes feldolgozáshoz.",
  "page.settings.emailNotifications": "E-mail értesítés küldés",
  "page.settings.emailNotifications.description": "Amennyiben engedélyezi ezt a funkciót, minden számla(csatolmányként) egy e-mail-ben kiküldésre kerül Önnek. Továbbá a Companion alkalmazásunkat használók, értesítést kapnap a kiállított számlákról.",
  "page.settings.printAtCheckout": "Papír alapú számla nyomtatás",
  "page.settings.printAtCheckout.description": "Amennyiben engedélyezi ezen beállítást, minden vásárlásánál nyomtatott számla kerül kiállításra. Deaktiválja ezt az opciót amennyiben nem kíván nyomtatott számlát(kivéve göngyöleg és vevőszolgálati visszavétel).\nEzen a felületen keresztül és a Companion alkamazásunkban továbbra is hozzáfér az Ön számláihoz.",
  "page.settings.printSuppression": "Papírmegtakarítás, digitális számlázás",
  "page.settings.printSuppression.description": "Ha aktiválja ezt az opciót, nem kap nyomtatott számlákat a pénztárnál, és papírt takarít meg.",
  "page.settings.title": "E-számla beállítások",
  "page.settings.update": "Beállítások mentése",
  "page.settings.update.submitted": "Beállításai rögzítésre kerültek. A feldolgozás folyamatban. Kérjük türelmét.",
  "page.settings.update.successful": "A beállítások sikeresen frissítésre kerültek.",
  "personal.card": "Személyes kártya",
  "warning.business.has.no.valid.card": "A számlák megtekintéséhez válasszon egy vállalkozást a fiókjából, vagy a vállalkozásnak NINCS ÉRVÉNYES KÁRTYÁJA."
},"it":{
  "all.cards": "Tutte le Tessere",
  "app.banner.button": "Usa la nostra App",
  "app.banner.description": "Accedi alle tue fatture sempre e ovunque",
  "common.cancel": "Annulla",
  "common.card": "Tessera",
  "common.customer": "Cliente",
  "common.dataPrivacy": "Privacy",
  "common.date": "Data",
  "common.date.placeholder": "aaaa-mm-gg",
  "common.download": "Scarica",
  "common.downloadPdf": "FATTURA IN PDF",
  "common.downloadSelected": "Scarica selezione",
  "common.from": "Da",
  "common.invoiceNumber": "Numero Fattura",
  "common.legalNotice": "Termini & Condizioni",
  "common.limit": "Fatture per pagina:",
  "common.netAmount": "€ Totale",
  "common.next": "Successivo",
  "common.noResults": "Nessun risultato",
  "common.payment": "Pagamento",
  "common.previous": "Precedente",
  "common.to": "A",
  "common.totalAmount": "€ Totale + IVA",
  "common.transaction.type.COD": "Fattura",
  "common.transaction.type.CONI": "Fattura",
  "common.transaction.type.DELIVERY_SLIP": "Bolla di consegna",
  "common.transaction.type.DOWN_PAYMENT": "Fattura acconto",
  "common.transaction.type.EMPTIES": "Fattura",
  "common.transaction.type.INVOICE": "Fattura",
  "common.transaction.type.PACKING_LIST": "DDT",
  "common.transaction.type.PAYMENT_RECEIPT": "Ricevuta pagamento",
  "common.transaction.type.PAY_IN_CHARGE_REFUND": "Rimborso pagamento",
  "common.transaction.type.REIMBURSEMENT_DOWN_PAYMENT": "Rimborso acconto",
  "common.transaction.type.RETURN": "Fattura reso",
  "common.transaction.type.UNKNOWN": "Fattura",
  "common.transaction.type.VOIDING_INVOICE": "Fattura storno",
  "common.type": "Tipologia",
  "download.error.invoice": "Si sono verificati alcuni problemi durante il download della fattura, riprova più tardi.",
  "invoices.card.header": "Fatture per {0}",
  "invoices.card.show": "Mostra Fatture",
  "invoices.per.page.filter": "Fatture/Pagina",
  "maintenance.message": "Al momento stiamo riscontrando alcuni problemi con il nostro sistema. Stiamo lavorando per risolvere il problema. Per favore, sii paziente. Saremo presto pienamente operativi.",
  "nav.invoices": "Fatture",
  "nav.logout": "Esci",
  "nav.onlineShop": "Mercato Online",
  "nav.overview": "Archivio",
  "nav.settings": "Impostazioni",
  "page.error.cardholderDependency": "Impossibile trovare i tuoi account",
  "page.error.cardholderDependency.message": "Non riusciamo a trovare i tuoi account a causa di alcuni problemi. Riprova più tardi.",
  "page.error.default": "Ops! Si è verificato un errore!",
  "page.error.default.message": "Si è verificato un errore durante la ricerca. Si prega di riprovare più tardi!",
  "page.error.forbidden": "Impossibile accedere alle fatture",
  "page.error.forbidden.message": "Non è possibile accedere ad alcuna fattura. Ti preghiamo di effettuare l'accesso con un Account Metro valido.",
  "page.error.invalidSearchInput": "Per favore inserisci almeno 3 caratteri",
  "page.error.noCardholder": "Impossibile accedere alle fatture",
  "page.error.noCardholder.message": "Non è possibile accedere alle tue fatture. Per favore prova ad accedere con un account Cliente Metro",
  "page.error.notFound": "Pagina Non Trovata",
  "page.error.notFound.message": "La pagina cercata non è stata trovata.",
  "page.error.no_available_cards": "Nessuna tessera disponibile",
  "page.error.no_available_cards.message": "Non sei ancora autorizzato a visualizzare i dati della fattura. Per favore completa la registrazione",
  "page.error.resourceAccess": "Problema durante il recupero dei dati",
  "page.error.resourceAccess.message": "Qualcosa è andato storto da parte nostra e ci è voluto troppo tempo per elaborare la tua richiesta. Riprova più tardi!",
  "page.error.sorry": "Ci scusiamo!",
  "page.error.statusCode": "Codice di status",
  "page.error.traceIdMessage": "Il codice errore è:",
  "page.error.wrongSearchInput": "Sono consentiti solo lettere, numeri e questi caratteri: /.,-_",
  "page.impersonation.attention": "Un grande potere comporta una grande responsabilità: fai attenzione a quello che stai facendo. Tutte le modifiche di stato (modifica delle impostazioni, contrassegno delle fatture come lette) avranno effetto anche sul cliente impersonificato.",
  "page.impersonation.card.help": "Per favore inserisci un numero di tessera",
  "page.impersonation.card.placeholder": "ID Tessera",
  "page.impersonation.confirm": "Confermo di essere autorizzato ad agire in qualità di questo cliente",
  "page.impersonation.confirm.description": "Si prega di tenere presente che non vi è alcun controllo se questo cliente esista davvero. Un cliente inesistente è  un cliente privo di dati.",
  "page.impersonation.customer.help": "Per favore inserisci il numero cliente",
  "page.impersonation.customer.placeholder": "ID Cliente",
  "page.impersonation.description": "Puoi utilizzare questo modulo per impersonificare un cliente specifico e utilizzare eInvoice per supporto.",
  "page.impersonation.info.description": "Clicca per configurare",
  "page.impersonation.info.title": "Accesso per impersonificazione",
  "page.impersonation.store.help": "Per favore inserisci il numero di store",
  "page.impersonation.store.placeholder": "ID Store",
  "page.impersonation.title": "Impersonificazione",
  "page.impersonation.update": "Applica",
  "page.impersonation.update.error": "Invio del modulo non riuscito. Si prega di controllare gli errori di convalida.",
  "page.impersonation.update.successful": "Utente modificato correttamente. Utilizza il logout per uscire dall´impersonificazione. ",
  "page.invoices.articles.table.description": "Descrizione",
  "page.invoices.articles.table.EAN": "EAN",
  "page.invoices.articles.table.MAN": "Codice Articolo Metro",
  "page.invoices.filter": "Filtro",
  "page.invoices.listTitle": "Le mie fatture",
  "page.invoices.search": "Ricerca",
  "page.invoices.search.placeholder": "Ricerca per numero fattura, nome, ecc..",
  "page.overview.availableAt": "Disponibile all´inizio di",
  "page.overview.availableUntil": "Disponibile fino alla fine di",
  "page.overview.button.empty": "vuoto",
  "page.overview.description": "Scarica l'elenco mensile degli articoli acquistati e dei pagamenti effettuati. L'elenco è disponibile come foglio di calcolo e puoi aprirlo con un'applicazione di foglio di calcolo a tua scelta (per esempio Microsoft Excel).",
  "page.overview.downloadOds": "Scarica",
  "page.overview.export.fallback.VAT_CODES_UNKNOWN": "Partita IVA sconosciuta",
  "page.overview.export.header.MO_TABLE_ARTICLE_DESCRIPTION": "Descrizione articolo",
  "page.overview.export.header.MO_TABLE_ARTICLE_GROUP_NAME": "Gruppo di articoli",
  "page.overview.export.header.MO_TABLE_ARTICLE_NUMBER": "Numero dell´articolo",
  "page.overview.export.header.MO_TABLE_BUYING_STORE": "Negozio di acquisto",
  "page.overview.export.header.MO_TABLE_CARDHOLDER_FIRST_NAME": "Nome",
  "page.overview.export.header.MO_TABLE_CARDHOLDER_LAST_NAME": "Cognome",
  "page.overview.export.header.MO_TABLE_CARD_NUMBER": "Numero di tessera",
  "page.overview.export.header.MO_TABLE_CUSTOMER_NAME": "Nome del  cliente",
  "page.overview.export.header.MO_TABLE_GTIN_NUMBER": "GTIN",
  "page.overview.export.header.MO_TABLE_INVOICE_DATE": "Data della fattura",
  "page.overview.export.header.MO_TABLE_INVOICE_NUMBER": "Numero di fattura",
  "page.overview.export.header.MO_TABLE_INVOICE_TYPE": "Tipo fattura",
  "page.overview.export.header.MO_TABLE_LOT_NUMBER": "Numero di lotto",
  "page.overview.export.header.MO_TABLE_ORDER_ID": "ID ordine",
  "page.overview.export.header.MO_TABLE_PRICE_GROSS": "Prezzo lordo",
  "page.overview.export.header.MO_TABLE_PRICE_NET": "Prezzo netto",
  "page.overview.export.header.MO_TABLE_PRICE_PER_PIECE_KG": "Prezzo/Pezzo/KG",
  "page.overview.export.header.MO_TABLE_PRICE_PER_SELLING_UNIT_NET": "Prezzo/Unità di vendita netta",
  "page.overview.export.header.MO_TABLE_QUANTITY": "Quantità",
  "page.overview.export.header.MO_TABLE_QUANTITY_PER_SELLING_UNIT": "Quantità/Unità di vendita",
  "page.overview.export.header.MO_TABLE_TAXES_AND_FEES": "Tasse e commissioni",
  "page.overview.export.header.MO_TABLE_VAT": "IVA",
  "page.overview.export.sheet.ARTICLE": "Articoli",
  "page.overview.export.sheet.PAYMENT": "Pagamenti",
  "page.overview.subDescription": "In questa sezione hai a disposizione l'archivio degli ultimi 13 mesi.",
  "page.overview.title": "Panoramica degli acquisti",
  "page.settings.description": "In questa pagina puoi modificare le tue preferenze in merito alle fatture. Tieni presente che la modifica delle preferenze richiede alcuni minuti per essere completamente elaborata.",
  "page.settings.emailNotifications": "Inviami la copia della fattura via e-mail",
  "page.settings.emailNotifications.description": "Se attivi questa opzione, riceverai una copia della fattura in formato PDF via e-mail.",
  "page.settings.printAtCheckout": "Stampa le mie fatture in Punto Vendita",
  "page.settings.printAtCheckout.description": "Se attivi questa opzione, riceverai una copia della fattura in formato PDF via e-mail.",
  "page.settings.printSuppression": "Contribuisci all’ambiente, non stampare le fatture",
  "page.settings.printSuppression.description": "Se attivi questa opzione, riceverai la fattura solo in formato digitale. Aiutaci a ridurre l'impatto ambientale richiedendo di non stampare alcuna fattura al momento del pagamento.",
  "page.settings.title": "Impostazioni fatture",
  "page.settings.update": "Modifica Impostazioni",
  "page.settings.update.submitted": "La modifica è stata inviata. Potrebbe essere necessario un po' di tempo per l'elaborazione. Per favore, sii paziente.",
  "page.settings.update.successful": "Impostazioni aggiornate con successo",
  "personal.card": "Tessera",
  "warning.business.has.no.valid.card": "Seleziona una Tessera della tua identità digitale per visualizzare le relative fatture."
},"nl":{
  "all.cards": "alle kaarten",
  "app.banner.button": "Gebruik onze app",
  "app.banner.description": "Altijd en overal toegang tot uw facturen",
  "common.cancel": "Annuleren",
  "common.card": "Pas",
  "common.customer": "Klant",
  "common.dataPrivacy": "Privacy statement",
  "common.date": "Datum",
  "common.date.placeholder": "jjjj-mm-dd",
  "common.download": "Downloaden",
  "common.downloadPdf": "Download PDF",
  "common.downloadSelected": "Download selectie",
  "common.from": "Van",
  "common.invoiceNumber": "Factuurnummer",
  "common.legalNotice": "Algemene voorwaarden",
  "common.limit": "Aantal facturen:",
  "common.netAmount": "Netto",
  "common.next": "Volgende",
  "common.noResults": "Geen resultaten",
  "common.payment": "Betaling",
  "common.previous": "Vorige",
  "common.to": "Tot",
  "common.totalAmount": "Totaalbedrag",
  "common.transaction.type.COD": "COD",
  "common.transaction.type.CONI": "Verzamelfactuur",
  "common.transaction.type.DELIVERY_SLIP": "Afleverbon",
  "common.transaction.type.DOWN_PAYMENT": "Aanbetaling",
  "common.transaction.type.EMPTIES": "Emballage",
  "common.transaction.type.INVOICE": "Factuur",
  "common.transaction.type.PACKING_LIST": "Paklijst",
  "common.transaction.type.PAYMENT_RECEIPT": "Betaalbewijs",
  "common.transaction.type.PAY_IN_CHARGE_REFUND": "Betaal de terugbetaling",
  "common.transaction.type.REIMBURSEMENT_DOWN_PAYMENT": "Terugbetaling van jouw aanbetaling",
  "common.transaction.type.RETURN": "Creditfactuur",
  "common.transaction.type.UNKNOWN": "Onbekend",
  "common.transaction.type.VOIDING_INVOICE": "Vervallen factuur",
  "common.type": "Type",
  "customer.info": "Nieuw! Je facturen kun je nu ook per email ontvangen. Stel het direct in onder instellingen via de switch.\n\nNew! You can now receive your invoices by email. Set it up directly under settings using the switch. \n\n",
  "download.error.invoice": "Er zijn enkele problemen opgetreden bij het downloaden van uw factuur. Probeer het later opnieuw.",
  "invoices.card.header": "Facturen voor {0}",
  "invoices.card.show": "Toon facturen",
  "maintenance.message": "Er zijn op dit moment technische problemen waardoor we de facturen niet kunnen tonen. Probeer het op een later moment opnieuw.",
  "nav.invoices": "Facturen",
  "nav.logout": "Afmelden",
  "nav.onlineShop": "Webshop",
  "nav.overview": "Overzicht",
  "nav.settings": "Instellingen",
  "page.error.cardholderDependency": "Geen gekoppelde bedrijven gevonden",
  "page.error.cardholderDependency.message": "We kunnen geen bedrijven vinden die aan je MijnMakro account gekoppeld zijn.",
  "page.error.default": "Oeps... er ging wat mis!",
  "page.error.default.message": "Er is iets misgegaan. Probeer het op een later moment opnieuw.",
  "page.error.forbidden": "Niet toegestaan",
  "page.error.forbidden.message": "Dit is niet toegestaan.",
  "page.error.invalidSearchInput": "Gebruik minimaal 3 tekens",
  "page.error.noCardholder": "Facturen niet beschikbaar",
  "page.error.noCardholder.message": "De facturen zijn niet beschikbaar.",
  "page.error.notFound": "Pagina niet gevonden",
  "page.error.notFound.message": "De pagina bestaat niet meer of er is iets misgegaan.",
  "page.error.no_available_cards": "Geen beschikbare kaarten",
  "page.error.no_available_cards.message": "U bent nog niet bevoegd om factuurgegevens in te zien. Voltooi uw registratieproces.",
  "page.error.resourceAccess": "De gegevens kunnen niet opgehaald worden",
  "page.error.resourceAccess.message": "Er is iets misgegaan aan onze kant. Probeer het op een later moment opnieuw.",
  "page.error.sorry": "Sorry!",
  "page.error.statusCode": "Statuscode",
  "page.error.traceIdMessage": "Foutcode:",
  "page.error.wrongSearchInput": "De invoer mag alleen letters (A-Z, a-z), cijfers (0-9) en deze tekens bevatten: /.,-_",
  "page.impersonation.attention": "Let op: alle wijzigingen die je aanbrengt (ook of een factuur bekeken is) worden namens de klant uitgevoerd.",
  "page.impersonation.card.help": "Vul een geldig Makro-pasnummer in",
  "page.impersonation.card.placeholder": "Pasnummer",
  "page.impersonation.confirm": "Ik heb toestemming van de klant om zijn facturen in te zien en namens hem instellingen te wijzigen.",
  "page.impersonation.confirm.description": "Als je een klantnummer opgeeft dat niet bestaat, dan zie je hier ook geen gegevens.",
  "page.impersonation.customer.help": "Vul een geldig klantnummer in",
  "page.impersonation.customer.placeholder": "Klantnummer",
  "page.impersonation.description": "Je kunt dit formulier gebruiken om namens een klant in te loggen.",
  "page.impersonation.info.description": "Klik om de instelling aan te passen",
  "page.impersonation.info.title": "Inloggen namens een klant",
  "page.impersonation.store.help": "Vul een numeriek vestigingsnummer in",
  "page.impersonation.store.placeholder": "Vestigingsnummer",
  "page.impersonation.title": "Namens een klant inloggen",
  "page.impersonation.update": "Toepassen",
  "page.impersonation.update.error": "Er is wat misgegaan.",
  "page.impersonation.update.successful": "Gebruiker gewijzigd, log eerst uit om van gebruiker te wisselen.",
  "page.invoices.articles.table.description": "Omschrijving",
  "page.invoices.articles.table.EAN": "Barcode",
  "page.invoices.articles.table.MAN": "Artikelnummer",
  "page.invoices.filter": "Filter",
  "page.invoices.listTitle": "Mijn facturen",
  "page.invoices.search": "Zoeken",
  "page.invoices.search.placeholder": "Zoeken naar factuurnummer, productnaam etc.",
  "page.overview.availableAt": "beschikbaar vanaf",
  "page.overview.availableUntil": "beschikbaar tot het einde van",
  "page.overview.button.empty": "leeg bestand",
  "page.overview.description": "Dit is een overzicht van je aankopen in de afgelopen 2 maanden. Je kunt dit overzicht downloaden en gebruiken in bijvoorbeeld Microsoft Excel.",
  "page.overview.downloadOds": "Download .ods bestand",
  "page.overview.export.fallback.VAT_CODES_UNKNOWN": "Onbekende btw-code {0}",
  "page.overview.export.header.MO_PM_TABLE_AMOUNT": "Bedrag",
  "page.overview.export.header.MO_PM_TABLE_CARD_TYPE_DESC": "Beschrijving van het kaarttype",
  "page.overview.export.header.MO_PM_TABLE_CURRENCY_CODE": "Valuta code",
  "page.overview.export.header.MO_PM_TABLE_FISCAL_MEDIA_DESC": "Fiscale media omschrijving",
  "page.overview.export.header.MO_PM_TABLE_MEDIA_DESC": "Media omschrijving",
  "page.overview.export.header.MO_TABLE_ARTICLE_DESCRIPTION": "Productbeschrijving",
  "page.overview.export.header.MO_TABLE_ARTICLE_GROUP_NAME": "Productgroep",
  "page.overview.export.header.MO_TABLE_ARTICLE_NUMBER": "Artikelnummer",
  "page.overview.export.header.MO_TABLE_BUYING_STORE": "Vestiging van aankoop",
  "page.overview.export.header.MO_TABLE_CARDHOLDER_FIRST_NAME": "Voornaam",
  "page.overview.export.header.MO_TABLE_CARDHOLDER_LAST_NAME": "Achternaam",
  "page.overview.export.header.MO_TABLE_CARD_NUMBER": "Pasnummer",
  "page.overview.export.header.MO_TABLE_CUSTOMER_NAME": "Bedrijfsnaam",
  "page.overview.export.header.MO_TABLE_CUSTOMER_TYPE": "klant type",
  "page.overview.export.header.MO_TABLE_GTIN_NUMBER": "GTIN",
  "page.overview.export.header.MO_TABLE_INVOICE_DATE": "Factuurdatum",
  "page.overview.export.header.MO_TABLE_INVOICE_NUMBER": "Factuurnummer",
  "page.overview.export.header.MO_TABLE_INVOICE_TYPE": "Factuurtype",
  "page.overview.export.header.MO_TABLE_LOT_NUMBER": "Lot nummer",
  "page.overview.export.header.MO_TABLE_ORDER_ID": "Bestelnummer",
  "page.overview.export.header.MO_TABLE_PRICE_GROSS": "Prijs incl. btw",
  "page.overview.export.header.MO_TABLE_PRICE_NET": "Prijs excl. btw",
  "page.overview.export.header.MO_TABLE_PRICE_PER_PIECE_KG": "Prijs/stuk/kg",
  "page.overview.export.header.MO_TABLE_PRICE_PER_SELLING_UNIT_NET": "Prijs/verkoopeenheid",
  "page.overview.export.header.MO_TABLE_QUANTITY": "Hoeveelheid",
  "page.overview.export.header.MO_TABLE_QUANTITY_PER_SELLING_UNIT": "Hoeveelheid/verkoopeenheid",
  "page.overview.export.header.MO_TABLE_TAXES_AND_FEES": "Statiegeld en heffingen",
  "page.overview.export.header.MO_TABLE_VAT": "Btw",
  "page.overview.export.sheet.ARTICLE": "Artikelen",
  "page.overview.export.sheet.PAYMENT": "Betalingen",
  "page.overview.subDescription": "Rapporten van de afgelopen 13 maanden worden beschikbaar gesteld en er is geen beperking bij het downloaden",
  "page.overview.title": "Overzicht van je aankopen",
  "page.settings.description": "Hier kun je je factuurinstellingen wijzigen. Het kan even duren totdat de wijzigen doorgevoerd zijn.\nLet op! Als beslissingsbevoegde kun je de instellingen aanpassen voor iedere pashouder.\nWanneer de optie \"facturen via email\" wordt ingeschakeld gaat de email naar de betreffende pashouder. ",
  "page.settings.emailNotifications": "Factuur via email ontvangen",
  "page.settings.emailNotifications.description": "Als je deze optie inschakelt ontvang je al je facturen via email.",
  "page.settings.printAtCheckout": "Papieren factuur bij kassa afdrukken",
  "page.settings.printAtCheckout.description": "Als je deze optie inschakelt ontvang je geen geprinte facturen meer bij de kassa. Je facturen kun je altijd gemakkelijk inzien via de app. ",
  "page.settings.printSuppression": "Bespaar papier, factureer digitaal",
  "page.settings.printSuppression.description": "Activeer je deze optie, dan ontvang je geen afgedrukte facturen bij het afrekenen en bespaar je papier.",
  "page.settings.title": "Instellingen",
  "page.settings.update": "Opslaan",
  "page.settings.update.submitted": "Je wijziging wordt verwerkt.",
  "page.settings.update.successful": "De instelling is bijgewerkt",
  "personal.card": "Persoonlijke kaart",
  "warning.business.has.no.valid.card": "Selecteer een bedrijf in uw account om de facturen te bekijken, of het bedrijf heeft GEEN GELDIGE KAART."
},"nl_BE":{
  "common.card": "Kaart",
  "common.dataPrivacy": "Privacy",
  "common.date": "Datum",
  "common.date.placeholder": "dd-mm-yyyy",
  "common.download": "Download",
  "common.downloadPdf": "Download pdf",
  "common.downloadSelected": "Download Selectie",
  "common.from": "Van",
  "common.invoiceNumber": "Factuurnummer",
  "common.legalNotice": "Algemene voorwaarden",
  "common.limit": "Getoonde facturen:",
  "common.netAmount": "Nettobedrag",
  "common.next": "Volgende",
  "common.noResults": "Geen resultaat",
  "common.previous": "Vorige",
  "common.to": "Aan",
  "common.totalAmount": "Totaalbedrag",
  "common.transaction.type.CONI": "Consolidated Invoice",
  "common.transaction.type.DELIVERY_SLIP": "Leveringsbewijs",
  "common.transaction.type.DOWN_PAYMENT": "Voorschot",
  "common.transaction.type.EMPTIES": "Leeggoed",
  "common.transaction.type.INVOICE": "Factuur",
  "common.transaction.type.PACKING_LIST": "Paklijst",
  "common.transaction.type.PAYMENT_RECEIPT": "Ontvangstbewijs",
  "common.transaction.type.PAY_IN_CHARGE_REFUND": "Terugbetaling",
  "common.transaction.type.REIMBURSEMENT_DOWN_PAYMENT": "Terugbetaling voorschot",
  "common.transaction.type.RETURN": "Retour",
  "common.transaction.type.UNKNOWN": "Onbekend",
  "common.type": "Type",
  "invoices.card.header": "Facturen voor {0}",
  "invoices.card.show": "Toon facturen",
  "nav.invoices": "Facturen",
  "nav.logout": "Uitloggen",
  "nav.onlineShop": "Online Shop",
  "nav.overview": "Overzicht",
  "nav.settings": "Instellingen",
  "page.error.default": "Oeps, er is een fout opgetreden!",
  "page.error.default.message": "Er is een fout opgetreden bij het verwerken van uw vraag. Gelieve later nog eens te proberen.",
  "page.error.forbidden": "Verboden",
  "page.error.forbidden.message": "U hebt geen toestemming om dit te doen. Onze excuses voor het ongemak.",
  "page.error.noCardholder": "geen toegang tot facturen",
  "page.error.noCardholder.message": "We hebben geen toegang tot uw facturen. Probeer in te loggen met een METRO-klantenaccount.",
  "page.error.notFound": "Pagina niet gevonden",
  "page.error.notFound.message": "De door u opgevraagde pagina is niet gevonden.",
  "page.error.sorry": "Onze excuses!",
  "page.error.traceIdMessage": "De foutcode voor deze fout is:",
  "page.invoices.articles.table.description": "Omschrijving",
  "page.invoices.articles.table.EAN": "EAN",
  "page.invoices.articles.table.MAN": "artikelnummer",
  "page.invoices.filter": "Filter",
  "page.invoices.listTitle": "Mijn facturen",
  "page.invoices.search": "Zoek",
  "page.invoices.search.placeholder": "Zoek op factuurnummer, artikelomschrijving, ...",
  "page.overview.availableAt": "beschikbaar vanaf begin",
  "page.overview.availableUntil": "beschikbaar tot eind",
  "page.overview.description": "Op deze pagina vind je een maandelijks overzicht van  alle artikelen die je de laatste maand hebt aangekocht. Druk op  de “download” knop en een handige excel file wordt geopend waar je deze informatie in terug vindt.",
  "page.overview.downloadOds": "Download",
  "page.overview.export.fallback.VAT_CODES_UNKNOWN": "Btw code niet gekend {0}",
  "page.overview.export.header.MO_TABLE_ARTICLE_DESCRIPTION": "Artikel Omschrijving",
  "page.overview.export.header.MO_TABLE_ARTICLE_GROUP_NAME": "Artikelgroep",
  "page.overview.export.header.MO_TABLE_ARTICLE_NUMBER": "Artikelnr",
  "page.overview.export.header.MO_TABLE_BUYING_STORE": "Vestiging van Aankoop",
  "page.overview.export.header.MO_TABLE_CARD_NUMBER": "Klantenkaartnummer",
  "page.overview.export.header.MO_TABLE_CUSTOMER_NAME": "Klantennaam",
  "page.overview.export.header.MO_TABLE_INVOICE_DATE": "Faktuurdatum",
  "page.overview.export.header.MO_TABLE_INVOICE_NUMBER": "Faktuurnr",
  "page.overview.export.header.MO_TABLE_LOT_NUMBER": "lotnr",
  "page.overview.export.header.MO_TABLE_ORDER_ID": "Order Nr",
  "page.overview.export.header.MO_TABLE_PRICE_GROSS": "Prijs incl",
  "page.overview.export.header.MO_TABLE_PRICE_NET": "Prijs excl",
  "page.overview.export.header.MO_TABLE_PRICE_PER_PIECE_KG": "Prijs/st/kg",
  "page.overview.export.header.MO_TABLE_PRICE_PER_SELLING_UNIT_NET": "Prijs Metro Eenheid (excl)",
  "page.overview.export.header.MO_TABLE_QUANTITY": "Aantal",
  "page.overview.export.header.MO_TABLE_QUANTITY_PER_SELLING_UNIT": "Aantal stuks in  METRO Eenheid",
  "page.overview.export.header.MO_TABLE_TAXES_AND_FEES": "Taxes & Fee",
  "page.overview.export.header.MO_TABLE_VAT": "btw %",
  "page.overview.subDescription": "Gelieve er rekening mee te houden dat het maandelijks overzicht maar 12 MAANDEN  ter beschikking wordt gesteld. Indien je deze file later toch nog wilt raadplegen, raden wij je aan om deze  documenten te downloaden en te bewaren op uw computer.",
  "page.overview.title": "Maandelijks  Overzicht",
  "page.settings.description": "Hier kan je je factuurinstellingen wijzigen. Hou er rekening mee dat de verwerking van deze wijzigingen enkele minuten kan duren.",
  "page.settings.emailNotifications": "E-mailnotificaties verzenden",
  "page.settings.emailNotifications.description": "Als je deze optie inschakelt, ontvang je een e-mail met je factuur.",
  "page.settings.printAtCheckout": "Print factuur aan de kassa",
  "page.settings.printAtCheckout.description": "Schakel deze optie uit om geen facturen meer te ontvangen bij de kassa. Je vindt je facturen in de METRO App of hier in  MyMetro. Als je deze optie weer inschakelt, dan worden je facturen weer bij de kassa geprint. ",
  "page.settings.title": "Factuur instellingen",
  "page.settings.update": "Instellingen wijzigen",
  "page.settings.update.submitted": "Uw wijziging wordt behandeld. Het kan even duren voordat deze is verwerkt. ",
  "page.settings.update.successful": "Instellingen succevol geüpdatet"
},"pl":{
  "common.cancel": "Anuluj ",
  "common.card": "Karta",
  "common.customer": "Klient",
  "common.dataPrivacy": "Prywatność",
  "common.date": "Data ",
  "common.date.placeholder": "rrrr-mm-dd",
  "common.download": "Pobierz ",
  "common.downloadPdf": "Pobierz plik PDF ",
  "common.downloadSelected": "Pobierz wybrane ",
  "common.from": "z ",
  "common.invoiceNumber": "Numer faktury ",
  "common.legalNotice": "Zasady i warunki ",
  "common.limit": "Lista faktur:",
  "common.netAmount": "Kwota netto ",
  "common.next": "Następny ",
  "common.noResults": "Brak wyników ",
  "common.previous": "Poprzedni",
  "common.to": "Do",
  "common.totalAmount": "Łączna kwota ",
  "common.transaction.type.CONI": "Faktura skonsolidowana ",
  "common.transaction.type.DELIVERY_SLIP": "Dokument dostawy ",
  "common.transaction.type.DOWN_PAYMENT": "Zaliczka ",
  "common.transaction.type.EMPTIES": "Depozyty",
  "common.transaction.type.INVOICE": "Faktura",
  "common.transaction.type.PACKING_LIST": "Lista pickingowa",
  "common.transaction.type.PAYMENT_RECEIPT": "Potwierdzenie wpłaty",
  "common.transaction.type.PAY_IN_CHARGE_REFUND": "Płatność zwrotna",
  "common.transaction.type.REIMBURSEMENT_DOWN_PAYMENT": "Zwrot zaliczki ",
  "common.transaction.type.RETURN": "Zwrot",
  "common.transaction.type.UNKNOWN": "Nieznany ",
  "common.transaction.type.VOIDING_INVOICE": "Anulowanie faktury",
  "common.type": "Typ",
  "invoices.card.header": "Faktury dla: ",
  "invoices.card.show": "Pokaż faktury ",
  "maintenance.message": "Obecnie wystąpiły problemy techniczne, jesteśmy w trakcie ich usuwania. Przepraszamy za powstałe niedogodności, prosimy spróbować później.",
  "nav.invoices": "Faktury ",
  "nav.logout": "Wyloguj ",
  "nav.onlineShop": "Sklep internetowy",
  "nav.overview": "Przegląd ",
  "nav.settings": "Ustawienia ",
  "page.error.default": "Ups! Wystąpił błąd!",
  "page.error.default.message": "Wystąpił błąd podczas wykonywania zapytania, prosimy spróbować później. ",
  "page.error.forbidden": "Zabronione",
  "page.error.forbidden.message": "Brak uprawnień do wykonania żądania, przepraszamy za niedogodności. ",
  "page.error.noCardholder": "Nie można uzyskać dostępu do faktur  ",
  "page.error.noCardholder.message": "Nie możemy uzyskać dostępu do faktur. Prosimy o zalogowanie się przy pomocy karty klienta.  ",
  "page.error.notFound": "Strona nie została odnaleziona",
  "page.error.notFound.message": "Poszukiwany zasób nie został odnaleziony. ",
  "page.error.resourceAccess": "Problem podczas pobierania danych",
  "page.error.resourceAccess.message": "Coś poszło nie tak po naszej stronie i nie udało się przetworzyć żądania. Spróbuj ponownie później!",
  "page.error.sorry": "Przepraszamy!",
  "page.error.statusCode": "Kod statusu",
  "page.error.traceIdMessage": "Kod błędu to:",
  "page.impersonation.attention": "Prosimy o uwagę podczas modyfikacji ustawień (np. oznaczenie faktur jako przeczytane ). Wszelkie zmiany stanu będą mogły być uznane za podszywanie się pod klienta. ",
  "page.impersonation.card.help": "Proszę wprowadzić numer karty ",
  "page.impersonation.card.placeholder": "ID karty",
  "page.impersonation.confirm": "Potwierdzam, że karta klienta należy do mnie. ",
  "page.impersonation.confirm.description": "Prosimy pamiętać, że nie ma systemowego sprawdzenia czy dana karta klienta faktycznie istnieje. Jeśli rejestracja zostanie dokonana błędnie, w panelu klienta nie odnajdziemy żadnych danych. ",
  "page.impersonation.customer.help": "Proszę o wprowadzenie numeru klienta ",
  "page.impersonation.customer.placeholder": "Identyfikator klienta",
  "page.impersonation.description": "Możesz użyć tego formularza w celu zarejestrowania karty klienta do portalu e-faktura.",
  "page.impersonation.info.description": "Kliknij, aby skonfigurować ",
  "page.impersonation.info.title": "Dostęp do ustawień indywudualnych",
  "page.impersonation.store.help": "Wprowadź numer hali ",
  "page.impersonation.store.placeholder": "ID hali ",
  "page.impersonation.title": "Personifikacja",
  "page.impersonation.update": "Zastosuj",
  "page.impersonation.update.error": "Przesłanie formularza nie powiodło się. Prosimy o poprawienie błędów wypełnienia. ",
  "page.impersonation.update.successful": "Ustawienia pomyślnie zapisane. Prosimy o przelogowanie się celem zapisania zmian. ",
  "page.invoices.articles.table.description": "Opis ",
  "page.invoices.articles.table.EAN": "EAN",
  "page.invoices.articles.table.MAN": "Numer artykułu ",
  "page.invoices.filter": "Filtr ",
  "page.invoices.listTitle": "Moje faktury ",
  "page.invoices.search": "Szukaj ",
  "page.invoices.search.placeholder": "Wyszukaj numer faktury, nazwę itp.",
  "page.overview.availableAt": "Dostępne na początku ",
  "page.overview.availableUntil": "dostępne do ",
  "page.overview.description": "Ta strona zawiera listę pobranych artykułów w ciągu ostatnich dwóch miesięcy. Pliki są udostępniane jako dokumenty arkusza kalkulacyjnego, które można otwierać za pomocą wybranej aplikacji (np. Microsoft Excel).",
  "page.overview.downloadOds": "pobieranie",
  "page.overview.export.fallback.VAT_CODES_UNKNOWN": "Nieznana stawka VAT ",
  "page.overview.export.header.MO_TABLE_ARTICLE_DESCRIPTION": "Opis artykułu ",
  "page.overview.export.header.MO_TABLE_ARTICLE_GROUP_NAME": "Grupa artykułowa",
  "page.overview.export.header.MO_TABLE_ARTICLE_NUMBER": "numer artykułu ",
  "page.overview.export.header.MO_TABLE_BUYING_STORE": "Hala zakupu",
  "page.overview.export.header.MO_TABLE_CARD_NUMBER": "Numer karty ",
  "page.overview.export.header.MO_TABLE_CUSTOMER_NAME": "Nazwa klienta ",
  "page.overview.export.header.MO_TABLE_GTIN_NUMBER": "GTIN",
  "page.overview.export.header.MO_TABLE_INVOICE_DATE": "Data faktury ",
  "page.overview.export.header.MO_TABLE_INVOICE_NUMBER": "Numer faktury ",
  "page.overview.export.header.MO_TABLE_LOT_NUMBER": "Numer partii ",
  "page.overview.export.header.MO_TABLE_ORDER_ID": "Identyfikator zamówienia ",
  "page.overview.export.header.MO_TABLE_PRICE_GROSS": "Cena brutto ",
  "page.overview.export.header.MO_TABLE_PRICE_NET": "Cena netto ",
  "page.overview.export.header.MO_TABLE_PRICE_PER_PIECE_KG": "Cena/sztuka/KG ",
  "page.overview.export.header.MO_TABLE_PRICE_PER_SELLING_UNIT_NET": "Cena / jednostka sprzedaży netto",
  "page.overview.export.header.MO_TABLE_QUANTITY": "Ilość ",
  "page.overview.export.header.MO_TABLE_QUANTITY_PER_SELLING_UNIT": "Ilość/Jednostka sprzedaży ",
  "page.overview.export.header.MO_TABLE_TAXES_AND_FEES": "Podatki i opłaty ",
  "page.overview.export.header.MO_TABLE_VAT": "VAT",
  "page.overview.subDescription": "Prosimy pamiętać, że dane artykułów zostaną zastąpione nowszymi, a dokumenty arkusza kalkulacyjnego będą dostępne tylko przez ostatnie dwa miesiące.",
  "page.overview.title": "Przegląd zakupów ",
  "page.settings.description": "Na tej stronie możesz dokonać edycji uprawnień, prosimy pamiętać, że przetworzenia zmian może zająć kilka minut. ",
  "page.settings.emailNotifications": "Wyślij powiadomienie e-mail",
  "page.settings.emailNotifications.description": "Jeśli włączysz tą opcję, otrzymasz powiadomienie e-mail dla każdego nowego dokumentu.",
  "page.settings.printAtCheckout": "Wydruk faktury przy kasie",
  "page.settings.printAtCheckout.description": "Jeśli włączysz tę opcję, otrzymasz wydruk faktury podczas składania zamówienia w hali. Wyłącz tę opcję, jeśli nie chcesz już otrzymywać papierowej faktury. Nadal masz dostęp do wszystkich cyfrowych dokumentów za pośrednictwem e-faktury.",
  "page.settings.title": "Ustawienia e-faktury",
  "page.settings.update": "Zapisz ustawienia ",
  "page.settings.update.submitted": "Zmiany zostały wysłane, trwa przetwarzanie żądania. Prosimy o cierpliwość. ",
  "page.settings.update.successful": "Ustawienia zaktualizowane pomyślnie "
},"pt":{
  "all.cards": "Todos os cartões",
  "app.banner.description": "Aceda às suas faturas a qualquer hora e em qualquer lugar",
  "common.cancel": "Cancelar",
  "common.card": "Cartão",
  "common.customer": "Cliente",
  "common.dataPrivacy": "Política de Privacidade",
  "common.date": "Data",
  "common.date.placeholder": "yyyy-mm-dd",
  "common.download": "Download",
  "common.downloadPdf": "Descarregar .pdf",
  "common.downloadSelected": "Descarregar selecionada",
  "common.from": "De",
  "common.invoiceNumber": "N.º da fatura",
  "common.legalNotice": "Termos & Condições",
  "common.limit": "Faturas apresentadas:",
  "common.netAmount": "Valor Sem Iva",
  "common.next": "Seguinte",
  "common.noResults": "Sem resultados",
  "common.payment": "Pagamento",
  "common.previous": "Anterior",
  "common.to": "Para",
  "common.totalAmount": "Valor total",
  "common.transaction.type.CONI": "Fatura consolidada",
  "common.transaction.type.EMPTIES": "Nota de Crédito",
  "common.transaction.type.INVOICE": "Fatura",
  "common.transaction.type.PACKING_LIST": "Lista de encomendas",
  "common.transaction.type.PAYMENT_RECEIPT": "Recibo de pagamento",
  "common.transaction.type.RETURN": "Nota de Crédito",
  "common.transaction.type.UNKNOWN": "desconhecido",
  "common.transaction.type.VOIDING_INVOICE": "Nota de Crédito",
  "common.type": "Tipo",
  "invoices.card.header": "Faturas para {0}",
  "invoices.card.show": "Visualizar faturas",
  "maintenance.message": "De momento, estamos com alguns problemas com nosso sistema, mas estamos a resolver o problema. Por favor, seja paciente. A situação estará em breve solucionada.",
  "nav.invoices": "Faturas",
  "nav.logout": "Terminar sessão",
  "nav.onlineShop": "Loja Online",
  "nav.overview": "Resumo",
  "nav.settings": "Definições",
  "page.error.default": "Oops! Ocorreu um erro!",
  "page.error.default.message": "Ocorreu um erro a processar o seu pedido. Por favor tente mais tarde!",
  "page.error.forbidden": "Impossível aceder às faturas",
  "page.error.forbidden.message": "Não conseguimos aceder a nenhuma fatura para si. Por favor tente iniciar sessão com a sua conta de cliente Makro.",
  "page.error.invalidSearchInput": "Introduza pelo menos 3 caracteres",
  "page.error.noCardholder": "Não é possível aceder às faturas",
  "page.error.noCardholder.message": "Não é possível disponibilizar faturas. Por favor tente fazer o login com uma conta Makro de cliente.",
  "page.error.notFound": "Página não encontrada",
  "page.error.notFound.message": "O recurso de que estava à procura não foi encontrado.",
  "page.error.resourceAccess": "Problema ao carregar os dados",
  "page.error.sorry": "Pedimos desculpa!",
  "page.error.traceIdMessage": "O código de erro para este erro é:",
  "page.impersonation.card.help": "Introduza o nº do cartão",
  "page.impersonation.customer.help": "Introduza o nº do cliente",
  "page.impersonation.customer.placeholder": "ID Cliente",
  "page.impersonation.store.help": "Introduza o nº da loja",
  "page.impersonation.store.placeholder": "Nº Loja",
  "page.impersonation.update": "Aplicar",
  "page.invoices.articles.table.description": "Descrição",
  "page.invoices.articles.table.EAN": "Código Barras",
  "page.invoices.articles.table.MAN": "Código Makro",
  "page.invoices.filter": "Filtrar",
  "page.invoices.listTitle": "As minhas faturas",
  "page.invoices.search": "Pesquisar",
  "page.invoices.search.placeholder": "Pesquisar por artigos, n.º da fatura, etc.",
  "page.overview.availableAt": "disponível a partir de",
  "page.overview.availableUntil": "disponível até final de ",
  "page.overview.description": "Esta página disponibiliza a lista dos artigos comprados nos últimos meses. Poderão fazer o download da lista e abrir com o Microsoft Excel.",
  "page.overview.downloadOds": "download.ods",
  "page.overview.export.fallback.VAT_CODES_UNKNOWN": "Código iva desconhecido",
  "page.overview.export.header.MO_PM_TABLE_AMOUNT": "Valor",
  "page.overview.export.header.MO_PM_TABLE_CARD_TYPE_DESC": "Tipo cartão",
  "page.overview.export.header.MO_PM_TABLE_CURRENCY_CODE": "Moeda",
  "page.overview.export.header.MO_PM_TABLE_FISCAL_MEDIA_DESC": "Pagamento",
  "page.overview.export.header.MO_PM_TABLE_MEDIA_DESC": "Pagamento",
  "page.overview.export.header.MO_TABLE_ARTICLE_DESCRIPTION": "Descrição artigo",
  "page.overview.export.header.MO_TABLE_ARTICLE_GROUP_NAME": "Grupo artigo",
  "page.overview.export.header.MO_TABLE_ARTICLE_NUMBER": "Código artigo",
  "page.overview.export.header.MO_TABLE_BUYING_STORE": "Loja da compra",
  "page.overview.export.header.MO_TABLE_CARDHOLDER_FIRST_NAME": "Nome",
  "page.overview.export.header.MO_TABLE_CARDHOLDER_LAST_NAME": "Apelido",
  "page.overview.export.header.MO_TABLE_CARD_NUMBER": "Nº cartão",
  "page.overview.export.header.MO_TABLE_CUSTOMER_NAME": "Nome cliente",
  "page.overview.export.header.MO_TABLE_GTIN_NUMBER": "GTIN",
  "page.overview.export.header.MO_TABLE_INVOICE_DATE": "Data fatura",
  "page.overview.export.header.MO_TABLE_INVOICE_NUMBER": "Nº fatura",
  "page.overview.export.header.MO_TABLE_INVOICE_TYPE": "Tipo fatura",
  "page.overview.export.header.MO_TABLE_LOT_NUMBER": "Nº lote",
  "page.overview.export.header.MO_TABLE_ORDER_ID": "Nº encomenda",
  "page.overview.export.header.MO_TABLE_PRICE_GROSS": "Valor com iva",
  "page.overview.export.header.MO_TABLE_PRICE_NET": "Valor sem iva",
  "page.overview.export.header.MO_TABLE_PRICE_PER_PIECE_KG": "Valor unit/kg",
  "page.overview.export.header.MO_TABLE_PRICE_PER_SELLING_UNIT_NET": "Valor sem iva unidade venda",
  "page.overview.export.header.MO_TABLE_QUANTITY": "Quantidade",
  "page.overview.export.header.MO_TABLE_QUANTITY_PER_SELLING_UNIT": "Qtd/Unid venda",
  "page.overview.export.header.MO_TABLE_TAXES_AND_FEES": "Taxas",
  "page.overview.export.header.MO_TABLE_VAT": "IVA",
  "page.overview.subDescription": "É possível aceder ao resumo das compras do último ano",
  "page.overview.title": "Resumo compras",
  "page.settings.description": "Nesta página pode editar as suas preferências da fatura digital. A alteração das preferências vai ser processada durante os próximos minutos.",
  "page.settings.emailNotifications": "Enviar notificações por e-mail",
  "page.settings.emailNotifications.description": "Se habilitar esta opção, irá receber uma notificação de email para cada nova fatura / nota de crédito.",
  "page.settings.printAtCheckout": "Imprimir fatura na Caixa",
  "page.settings.title": "Definições da fatura digital",
  "page.settings.update": "Alterar definições",
  "page.settings.update.submitted": "A alteração foi submetida. Poderá demorar algum tempo para processar. Por favor espere",
  "page.settings.update.successful": "Definições alteradas com sucesso"
},"ro":{
  "all.cards": "toate cardurile",
  "common.cancel": "Anulează",
  "common.card": "Card",
  "common.customer": "Client",
  "common.dataPrivacy": "Confidentialitate",
  "common.date": "Data",
  "common.date.placeholder": "aaaa-ll-zz",
  "common.download": "Descarca",
  "common.downloadPdf": "Descărcați .pdf",
  "common.downloadSelected": "Descarca selectia",
  "common.from": "De la",
  "common.invoiceNumber": "Nr.  document",
  "common.legalNotice": "Termeni și condiții",
  "common.limit": "Facturi afișate:",
  "common.netAmount": "Valoare netă",
  "common.next": "Următor",
  "common.noResults": "Fara rezultate",
  "common.previous": "Anterior ",
  "common.to": "La",
  "common.totalAmount": "Valoare totală",
  "common.transaction.type.CONI": "Document consolidat",
  "common.transaction.type.DELIVERY_SLIP": "Chitanta de livrare",
  "common.transaction.type.DOWN_PAYMENT": "Down_payment",
  "common.transaction.type.EMPTIES": "Ambalaje",
  "common.transaction.type.INVOICE": "Document",
  "common.transaction.type.PACKING_LIST": "Listă de pick",
  "common.transaction.type.PAYMENT_RECEIPT": "Chitanță de plata",
  "common.transaction.type.PAY_IN_CHARGE_REFUND": "Rambursarea plății",
  "common.transaction.type.REIMBURSEMENT_DOWN_PAYMENT": "Rambursare în avans",
  "common.transaction.type.RETURN": "Retur",
  "common.transaction.type.UNKNOWN": "Necunoscut ",
  "common.transaction.type.VOIDING_INVOICE": "Document de anulare",
  "common.type": "Tip",
  "invoices.card.header": "Facturi pentru {0} ",
  "invoices.card.show": "Afiseaza facturile",
  "maintenance.message": "Ne confruntăm în prezent cu unele probleme cu sistemul nostru, dar lucrăm intens pentru a remedia problema. Te rog fii răbdător. Aplicația va redeveni disponibilă în curând.",
  "nav.invoices": "Documente Contabile",
  "nav.logout": "Deconectați-vă",
  "nav.onlineShop": "Magazin Online",
  "nav.overview": "Rezumat lunar",
  "nav.settings": "Setări",
  "page.error.cardholderDependency": "Nu găsim conturile dumneavoastră",
  "page.error.cardholderDependency.message": "Nu putem găsi conturile dvs. din cauza unor probleme. Vă rugăm să încercați din nou mai târziu.",
  "page.error.default": "Hopa! A apărut o eroare!",
  "page.error.default.message": "A apărut o eroare la efectuarea cererii specificate. Vă rugăm să încercați din nou mai târziu!",
  "page.error.forbidden": "Interzis",
  "page.error.forbidden.message": "Nu este permisă aceasta acțiune. Ne pare rău pentru inconvenient.",
  "page.error.noCardholder": "Nu se pot accesa facturile",
  "page.error.noCardholder.message": "Nu putem accesa nicio factură pentru dvs. Încercați să vă conectați cu un cont de client Metro",
  "page.error.notFound": "Pagina nu a fost gasita",
  "page.error.notFound.message": "Resursa căutată nu a fost găsită.",
  "page.error.resourceAccess": "Problemă apărută în timpul preluării datelor",
  "page.error.resourceAccess.message": "Ceva nu a mers bine și a durat prea mult să vă procesăm solicitarea. Vă rugăm să încercați din nou mai târziu!",
  "page.error.sorry": "Ne cerem scuze!",
  "page.error.statusCode": "Cod stare ",
  "page.error.traceIdMessage": "Codul de eroare pentru această eroare este:",
  "page.impersonation.attention": "Putere mai multa responsabilități mai mari: fii atent la ceea ce faci. Toate modificările de stare (modificarea setărilor, marcarea facturilor ca fiind citite) vor afecta, de asemenea, clientul impersonat.",
  "page.impersonation.card.help": "Vă rugăm să introduceți o valoare numerică pentru numărul cardului",
  "page.impersonation.card.placeholder": "ID card",
  "page.impersonation.confirm": "Confirm că am dreptul să devin și să acționez ca acest client.",
  "page.impersonation.confirm.description": "Vă rugăm să rețineți că nu există nicio verificare dacă acest client există cu adevărat. Dacă deveniți un client inexistent, acționați ca un client care nu are date.",
  "page.impersonation.customer.help": "Va rugăm să introduceți valoare numerică pentru codul clientului.",
  "page.impersonation.customer.placeholder": "ID client ",
  "page.impersonation.description": "Puteți utiliza acest formular pentru a deveni un client specific și puteți utiliza einvoice în numele său pentru a beneficia de ajutor.",
  "page.impersonation.info.description": "faceți clic pentru a configura",
  "page.impersonation.info.title": "Acces la impersonare",
  "page.impersonation.store.help": "Vă rugăm să introduceți o valoare numerica pentru numărul de magazin",
  "page.impersonation.store.placeholder": "ID Magazin",
  "page.impersonation.title": "Impersonarea",
  "page.impersonation.update": "Aplica",
  "page.impersonation.update.error": "Trimiterea formularului a eșuat. Verificați erorile de validare.",
  "page.impersonation.update.successful": "Utilizatorul a fost schimbat cu succes. Deconectați-vă pentru aplicarea modificărilor.",
  "page.invoices.articles.table.description": "Descriere",
  "page.invoices.articles.table.EAN": "EAN",
  "page.invoices.articles.table.MAN": "Cod Articol Metro",
  "page.invoices.filter": "Filtru ",
  "page.invoices.listTitle": "Documente mele contabile ",
  "page.invoices.search": "Căutare ",
  "page.invoices.search.placeholder": "Cauta articole,  numarul documentului etc.",
  "page.overview.availableAt": "disponibil la începutul",
  "page.overview.availableUntil": "disponibil până la sfârșitul",
  "page.overview.description": "Acest site oferă o listă descărcabilă de articole cumpărate și plăti. Fișierele sunt furnizate ca documente cu foi de calcul, care pot fi deschise cu aplicații specifice (de exemplu, Microsoft Excel).",
  "page.overview.downloadOds": "descărcați .ods",
  "page.overview.export.fallback.VAT_CODES_UNKNOWN": "Cod TVA necunoscut {0} ",
  "page.overview.export.header.MO_TABLE_ARTICLE_DESCRIPTION": "Descrierea articolului ",
  "page.overview.export.header.MO_TABLE_ARTICLE_GROUP_NAME": "Grupă de articole",
  "page.overview.export.header.MO_TABLE_ARTICLE_NUMBER": "Numărul articolului ",
  "page.overview.export.header.MO_TABLE_BUYING_STORE": "Magazin de cumpărare",
  "page.overview.export.header.MO_TABLE_CARD_NUMBER": "Număr de card",
  "page.overview.export.header.MO_TABLE_CUSTOMER_NAME": "Numele clientului ",
  "page.overview.export.header.MO_TABLE_GTIN_NUMBER": "GTIN ",
  "page.overview.export.header.MO_TABLE_INVOICE_DATE": "Data document",
  "page.overview.export.header.MO_TABLE_INVOICE_NUMBER": "Nr. document",
  "page.overview.export.header.MO_TABLE_LOT_NUMBER": "Numărul lotului",
  "page.overview.export.header.MO_TABLE_ORDER_ID": "ID Comandă",
  "page.overview.export.header.MO_TABLE_PRICE_GROSS": "Preț brut",
  "page.overview.export.header.MO_TABLE_PRICE_NET": "Pret net",
  "page.overview.export.header.MO_TABLE_PRICE_PER_PIECE_KG": "Preț/Bucată/KG",
  "page.overview.export.header.MO_TABLE_PRICE_PER_SELLING_UNIT_NET": "Preț / unitate de vânzare net",
  "page.overview.export.header.MO_TABLE_QUANTITY": "Cantitate",
  "page.overview.export.header.MO_TABLE_QUANTITY_PER_SELLING_UNIT": "Cantitate / unitate de vânzare",
  "page.overview.export.header.MO_TABLE_TAXES_AND_FEES": "Taxe și impozite",
  "page.overview.export.header.MO_TABLE_VAT": "TVA",
  "page.overview.subDescription": "Ultimele 13 luni sunt disponibile mereu pentru a fi descărcate.",
  "page.overview.title": "Rezumat Achizitii",
  "page.settings.description": "În această pagină, poți edita preferințele eInvoice. Rețineți că schimbarea preferințelor durează câteva minute pentru a fi procesată complet.",
  "page.settings.emailNotifications": "Trimiteți notificări prin e-mail",
  "page.settings.emailNotifications.description": "Dacă vei activa această opțiune vei primi un email de notificare pentru fiecare document nou în eInvoice.",
  "page.settings.printAtCheckout": "Tipărește factura la casă",
  "page.settings.printAtCheckout.description": "Dacă activați această opțiune, veți primi o factură tipărită la casa de marcat. Dezactivați această opțiune dacă nu mai doriți să mai primiți o factură tipărită. Veți avea accesa în continuare la toate documentele digitale prin eInvoice.",
  "page.settings.title": "Setări eInvoice",
  "page.settings.update": "Salvează setările",
  "page.settings.update.submitted": "Modificarea dvs. a fost trimisă. Procesul poate dura ceva timp. Te rog fii răbdător.",
  "page.settings.update.successful": "Setările au fost actualizate cu succes"
},"UA":{
  "all.cards": "всі карти",
  "app.banner.button": "Використовуйте наш додаток",
  "app.banner.description": "Доступ до рахунків-фактур у будь-який час і з будь-якого місця",
  "common.cancel": "Скасувати",
  "common.card": "Картка",
  "common.customer": "Клієнт",
  "common.dataPrivacy": "Конфіденційність",
  "common.date": "Дата",
  "common.date.placeholder": "рррр-мм-дд",
  "common.download": "Завантажити",
  "common.downloadPdf": "Завантажити .pdf",
  "common.downloadSelected": "Завантажити Вибране",
  "common.from": "Від",
  "common.invoiceNumber": "Номер рахунку-фактури",
  "common.legalNotice": "Умови та погодження",
  "common.limit": "Доступні рахунки-фактури:",
  "common.netAmount": "Сума нетто",
  "common.next": "Наступні",
  "common.noResults": "Немає результатів",
  "common.payment": "Оплата",
  "common.previous": "Попередні",
  "common.to": "До",
  "common.totalAmount": "Загальна сума",
  "common.transaction.type.COD": "COD",
  "common.transaction.type.CONI": "Консолідований рахунок-фактура",
  "common.transaction.type.DELIVERY_SLIP": "Квитанція про доставку",
  "common.transaction.type.DOWN_PAYMENT": "Початковий внесок",
  "common.transaction.type.EMPTIES": "Порожні",
  "common.transaction.type.INVOICE": "Рахунок-фактура",
  "common.transaction.type.PACKING_LIST": "Пакувальний лист",
  "common.transaction.type.PAYMENT_RECEIPT": "Квитанція про оплату",
  "common.transaction.type.PAY_IN_CHARGE_REFUND": "Відшкодування витрат на оплату",
  "common.transaction.type.REIMBURSEMENT_DOWN_PAYMENT": "Відшкодування першого внеску",
  "common.transaction.type.RETURN": "Повернення",
  "common.transaction.type.UNKNOWN": "Невідомо",
  "common.transaction.type.VOIDING_INVOICE": "Анулювання рахунку-фактури",
  "common.type": "Тип",
  "deactivate.invoice.printing.button": "Деактивувати друк рахунків-фактур",
  "deactivate.invoice.printing.description": "Чи хотіли б ви в майбутньому не отримувати роздруковані рахунки-фактури при покупках у магазині?",
  "download.error.invoice": "Виникли проблеми із завантаженням інвойсу, будь ласка, спробуйте пізніше.",
  "download.error.some.invoices": "Помилка при завантаженні",
  "invoices.card.header": "Рахунки-фактури для {0}",
  "invoices.card.show": "Показати рахунки-фактури",
  "invoices.per.page.filter": "Рахунки-фактури/Cторінка",
  "maintenance.message": "Наразі ми зіткнулися з деякими проблемами в нашій системі, але ми наполегливо працюємо над їх вирішенням. Будь ласка, наберіться терпіння. Незабаром ми будемо повністю готові до роботи.",
  "nav.invoices": "Рахунки-фактури",
  "nav.logout": "Вийти з системи",
  "nav.onlineShop": "Інтернет-магазин",
  "nav.overview": "Огляд",
  "nav.settings": "Налаштування",
  "page.error.cardholderDependency": "Не вдається знайти ваш обліковий запис",
  "page.error.cardholderDependency.message": "Ми не можемо знайти ваш обліковий запис через деякі проблеми. Будь ласка, спробуйте пізніше.",
  "page.error.default": "Упс! Виникла помилка!",
  "page.error.default.message": "При виконанні вказаного запиту виникла помилка. Будь ласка, спробуйте пізніше!",
  "page.error.forbidden": "Заборонено",
  "page.error.forbidden.message": "Ви не маєте права цього робити. Вибачте за незручності.",
  "page.error.invalidSearchInput": "Будь ласка, введіть щонайменше 3 символи",
  "page.error.noCardholder": "Не вдається отримати доступ до рахунків-фактур",
  "page.error.noCardholder.message": "Ми не можемо надати вам доступ до рахунків-фактур. Будь ласка, спробуйте увійти під своїм обліковим записом клієнта Metro.",
  "page.error.notFound": "Сторінку не знайдено",
  "page.error.notFound.message": "Ресурс, який ви шукали, не знайдено.",
  "page.error.no_available_cards": "Немає доступних карток",
  "page.error.no_available_cards.message": "Ви ще не авторизовані для перегляду даних рахунків-фактур. Будь ласка, завершіть процес реєстрації користувача.",
  "page.error.resourceAccess": "Проблема під час отримання даних",
  "page.error.resourceAccess.message": "Щось пішло не так і обробка вашого запиту зайняла занадто багато часу. Будь ласка, спробуйте пізніше!",
  "page.error.sorry": "Просимо вибачення!",
  "page.error.statusCode": "Статус",
  "page.error.traceIdMessage": "Код цієї помилки наступний:",
  "page.error.wrongSearchInput": "Вхідні дані повинні містити лише літери (A-Z, a-z), цифри (0-9) та ці символи: /.,-_",
  "page.impersonation.attention": "З новими можливостями приходить велика відповідальність: будьте обережні у своїх діях. Всі зміни стану (зміна налаштувань, маркування рахунків-фактур як прочитаних) також стосуватимуться клієнта-шахрая.",
  "page.impersonation.card.help": "Будь ласка, введіть числовий номер клієнта",
  "page.impersonation.card.placeholder": "Номер ДП",
  "page.impersonation.confirm": "Я підтверджую, що мені дозволено діяти як цей клієнт.",
  "page.impersonation.confirm.description": "Будь ласка, майте на увазі, що ми не можемо перевірити, чи існує цей клієнт насправді. Якщо ви стаєте неіснуючим клієнтом, ви дієте як клієнт, який не має даних.",
  "page.impersonation.customer.help": "Будь ласка, введіть числовий номер клієнта",
  "page.impersonation.customer.placeholder": "Номер клієнта",
  "page.impersonation.description": "За допомогою цієї форми ви можете використовувати Електронні рахунки-фактури з метою підтримки та інформування.",
  "page.impersonation.info.description": "натисніть, щоб налаштувати",
  "page.impersonation.info.title": "Доступ від імені",
  "page.impersonation.store.help": "Будь ласка, введіть числовий номер клієнта",
  "page.impersonation.store.placeholder": "Номер ТЦ",
  "page.impersonation.title": "Перевтілення",
  "page.impersonation.update": "Застосувати",
  "page.impersonation.update.error": "Не вдалося відправити форму. Будь ласка, перевірте внесені дані.",
  "page.impersonation.update.successful": "Користувача успішно змінено. Щоб вийти з облікового запису, скористайтеся виходом з системи.",
  "page.invoices.articles.table.description": "Опис",
  "page.invoices.articles.table.EAN": "EAN",
  "page.invoices.articles.table.MAN": "Номер артикулу Metro",
  "page.invoices.filter": "Фільтр",
  "page.invoices.listTitle": "Мої рахунки-фактури",
  "page.invoices.search": "Пошук",
  "page.invoices.search.placeholder": "Пошук за артикулом, номером рахунку-фактури тощо.",
  "page.overview.availableAt": "стане доступно на початку",
  "page.overview.availableUntil": "доступно до кінця",
  "page.overview.button.empty": "Порожній файл",
  "page.overview.description": "На цьому сайті можна завантажити список придбаних артикулів (товарів). Файли надаються у вигляді електронних таблиць, які можна відкрити за допомогою програми для роботи з електронними таблицями на ваш вибір (наприклад, Microsoft Excel).",
  "page.overview.downloadOds": "завантажити.ods",
  "page.overview.export.fallback.VAT_CODES_UNKNOWN": "Невідомий ідентифікаційний номер ПДВ {0}",
  "page.overview.export.header.MO_PM_TABLE_AMOUNT": "Сума",
  "page.overview.export.header.MO_PM_TABLE_CARD_TYPE_DESC": "Опис типу картки",
  "page.overview.export.header.MO_PM_TABLE_CURRENCY_CODE": "UAH",
  "page.overview.export.header.MO_PM_TABLE_FISCAL_MEDIA_DESC": "Опис фіскального носія",
  "page.overview.export.header.MO_PM_TABLE_MEDIA_DESC": "Опис",
  "page.overview.export.header.MO_TABLE_ARTICLE_DESCRIPTION": "Опис артикула (товару)",
  "page.overview.export.header.MO_TABLE_ARTICLE_GROUP_NAME": "Група артикулів",
  "page.overview.export.header.MO_TABLE_ARTICLE_NUMBER": "Номер артикулу",
  "page.overview.export.header.MO_TABLE_BUYING_STORE": "Торговельний центр",
  "page.overview.export.header.MO_TABLE_CARDHOLDER_FIRST_NAME": "Ім'я",
  "page.overview.export.header.MO_TABLE_CARDHOLDER_LAST_NAME": "Прізвище",
  "page.overview.export.header.MO_TABLE_CARD_NUMBER": "Номер картки",
  "page.overview.export.header.MO_TABLE_CUSTOMER_NAME": "Ім'я клієнта",
  "page.overview.export.header.MO_TABLE_CUSTOMER_TYPE": "Тип клієнта",
  "page.overview.export.header.MO_TABLE_GTIN_NUMBER": "GTIN",
  "page.overview.export.header.MO_TABLE_INVOICE_DATE": "Дата рахунку-фактури",
  "page.overview.export.header.MO_TABLE_INVOICE_NUMBER": "Номер рахунку-фактури",
  "page.overview.export.header.MO_TABLE_INVOICE_TYPE": "Тип інвойсу",
  "page.overview.export.header.MO_TABLE_LOT_NUMBER": "Номер лота",
  "page.overview.export.header.MO_TABLE_ORDER_ID": "Ідентифікатор замовлення",
  "page.overview.export.header.MO_TABLE_PRICE_GROSS": "Ціна брутто",
  "page.overview.export.header.MO_TABLE_PRICE_GROSS_WITH_DISCOUNT": "Ціна брутто зі знижкою",
  "page.overview.export.header.MO_TABLE_PRICE_NET": "Ціна нетто",
  "page.overview.export.header.MO_TABLE_PRICE_PER_PIECE_KG": "Ціна/шт/кг",
  "page.overview.export.header.MO_TABLE_PRICE_PER_SELLING_UNIT_NET": "Ціна/Одиниця виміру нетто",
  "page.overview.export.header.MO_TABLE_QUANTITY": "Кількість",
  "page.overview.export.header.MO_TABLE_QUANTITY_PER_SELLING_UNIT": "Кількість/Одиниця виміру",
  "page.overview.export.header.MO_TABLE_TAXES_AND_FEES": "Податки та збори",
  "page.overview.export.header.MO_TABLE_VAT": "ПДВ",
  "page.overview.export.sheet.ARTICLE": "Артикула",
  "page.overview.export.sheet.PAYMENT": "Платежі",
  "page.overview.subDescription": "Звіти за останні 13 місяців доступні без обмежень щодо часу завантаження",
  "page.overview.title": "Огляд закупівель",
  "page.settings.description": "На цій сторінці ви зможете змінити свої налаштування електронних рахунків-фактур. Будь ласка, зверніть увагу, що для повної обробки запиту (зміни налаштувань) знадобиться декілька хвилин.",
  "page.settings.emailNotifications": "Надсилати сповіщення на електронну пошту (E-mail)",
  "page.settings.emailNotifications.description": "Якщо ви увімкнете цю опцію, ви отримуватимете сповіщення на електронну пошту про кожен новий рахунок-фактуру.",
  "page.settings.printAtCheckout": "Роздрукувати рахунок-фактуру на касі",
  "page.settings.printAtCheckout.description": "Якщо ви увімкнете цю опцію, ви отримаєте рахунок-фактуру під час оформлення замовлення в магазині. Деактивуйте цю опцію, якщо ви більше не бажаєте отримувати рахуноки-фактури. Ви все одно матимете доступ до всіх своїх цифрових документів через цей модуль в особостому кабінеті.",
  "page.settings.printSuppression": "Заощаджуйте папір, використовуйте рахунки в електронному вигляді",
  "page.settings.printSuppression.description": "Якщо ви активуєте цю опцію, ви не будете отримувати роздруковані рахунки на касі та заощадите папір.",
  "page.settings.title": "Налаштування електронних рахунків-фактур",
  "page.settings.update": "Зберегти налаштування",
  "page.settings.update.submitted": "Вашу заявку було відправлено. Обробка може зайняти деякий час. Будь ласка, наберіться терпіння.",
  "page.settings.update.successful": "Налаштування успішно оновлено",
  "personal.card": "Персональна картка",
  "warning.business.has.no.valid.card": "Будь ласка, виберіть компанію з вашого облікового запису, щоб переглянути рахунки-фактури або у компанії НЕМАЄ ДІЙСНОЇ КАРТКИ."
},"uk":{
  "all.cards": "всі карти",
  "common.cancel": "Скасувати",
  "common.card": "Картка",
  "common.customer": "Клієнт",
  "common.dataPrivacy": "Конфіденційність",
  "common.date": "Дата",
  "common.date.placeholder": "рррр-мм-дд",
  "common.download": "Завантажити",
  "common.downloadPdf": "Завантажити .pdf",
  "common.downloadSelected": "Завантажити Вибране",
  "common.from": "Від",
  "common.invoiceNumber": "Номер рахунку-фактури",
  "common.legalNotice": "Умови та погодження",
  "common.limit": "Доступні рахунки-фактури:",
  "common.netAmount": "Сума нетто",
  "common.next": "Наступні",
  "common.noResults": "Немає результатів",
  "common.payment": "Оплата",
  "common.previous": "Попередні",
  "common.to": "До",
  "common.totalAmount": "Загальна сума",
  "common.transaction.type.COD": "COD",
  "common.transaction.type.CONI": "Консолідований рахунок-фактура",
  "common.transaction.type.DELIVERY_SLIP": "Квитанція про доставку",
  "common.transaction.type.DOWN_PAYMENT": "Початковий внесок",
  "common.transaction.type.EMPTIES": "Порожні",
  "common.transaction.type.INVOICE": "Рахунок-фактура",
  "common.transaction.type.PACKING_LIST": "Пакувальний лист",
  "common.transaction.type.PAYMENT_RECEIPT": "Квитанція про оплату",
  "common.transaction.type.PAY_IN_CHARGE_REFUND": "Відшкодування витрат на оплату",
  "common.transaction.type.REIMBURSEMENT_DOWN_PAYMENT": "Відшкодування першого внеску",
  "common.transaction.type.RETURN": "Назад",
  "common.transaction.type.UNKNOWN": "Невідомо",
  "common.transaction.type.VOIDING_INVOICE": "Анулювання рахунку-фактури",
  "common.type": "Тип",
  "invoices.card.header": "Рахунки-фактури для {0}",
  "invoices.card.show": "Показати рахунки-фактури",
  "invoices.per.page.filter": "Рахунки-фактури/Cторінка",
  "maintenance.message": "Наразі ми зіткнулися з деякими проблемами в нашій системі, але ми наполегливо працюємо над їх вирішенням. Будь ласка, наберіться терпіння. Незабаром ми будемо повністю готові до роботи.",
  "nav.invoices": "Рахунки-фактури",
  "nav.logout": "Вийти з системи",
  "nav.onlineShop": "Інтернет-магазин",
  "nav.overview": "Огляд",
  "nav.settings": "Налаштування",
  "page.error.cardholderDependency": "Не вдається знайти ваш обліковий запис",
  "page.error.cardholderDependency.message": "Ми не можемо знайти ваш обліковий запис через деякі проблеми. Будь ласка, спробуйте пізніше.",
  "page.error.default": "Упс! Виникла помилка!",
  "page.error.default.message": "При виконанні вказаного запиту виникла помилка. Будь ласка, спробуйте пізніше!",
  "page.error.forbidden": "Заборонено",
  "page.error.forbidden.message": "Ви не маєте права цього робити. Вибачте за незручності.",
  "page.error.invalidSearchInput": "Будь ласка, введіть щонайменше 3 символи",
  "page.error.noCardholder": "Не вдається отримати доступ до рахунків-фактур",
  "page.error.noCardholder.message": "Ми не можемо надати вам доступ до рахунків-фактур. Будь ласка, спробуйте увійти під своїм обліковим записом клієнта Metro.",
  "page.error.notFound": "Сторінку не знайдено",
  "page.error.notFound.message": "Ресурс, який ви шукали, не знайдено.",
  "page.error.no_available_cards": "Немає доступних карток",
  "page.error.no_available_cards.message": "Ви ще не авторизовані для перегляду даних рахунків-фактур. Будь ласка, завершіть процес реєстрації користувача.",
  "page.error.resourceAccess": "Проблема під час отримання даних",
  "page.error.resourceAccess.message": "Щось пішло не так і обробка вашого запиту зайняла занадто багато часу. Будь ласка, спробуйте пізніше!",
  "page.error.sorry": "Просимо вибачення!",
  "page.error.statusCode": "Статус",
  "page.error.traceIdMessage": "Код цієї помилки наступний:",
  "page.impersonation.attention": "З новими можливостями приходить велика відповідальність: будьте обережні у своїх діях. Всі зміни стану (зміна налаштувань, маркування рахунків-фактур як прочитаних) також стосуватимуться клієнта-шахрая.",
  "page.impersonation.card.help": "Будь ласка, введіть числовий номер клієнта",
  "page.impersonation.card.placeholder": "Номер ДП",
  "page.impersonation.confirm": "Я підтверджую, що мені дозволено діяти як цей клієнт.",
  "page.impersonation.confirm.description": "Будь ласка, майте на увазі, що ми не можемо перевірити, чи існує цей клієнт насправді. Якщо ви стаєте неіснуючим клієнтом, ви дієте як клієнт, який не має даних.",
  "page.impersonation.customer.help": "Будь ласка, введіть числовий номер клієнта",
  "page.impersonation.customer.placeholder": "Номер клієнта",
  "page.impersonation.description": "За допомогою цієї форми ви можете використовувати Електронні рахунки-фактури з метою підтримки та інформування.",
  "page.impersonation.info.description": "натисніть, щоб налаштувати",
  "page.impersonation.info.title": "Доступ від імені",
  "page.impersonation.store.help": "Будь ласка, введіть числовий номер клієнта",
  "page.impersonation.store.placeholder": "Номер ТЦ",
  "page.impersonation.title": "Перевтілення",
  "page.impersonation.update": "Застосувати",
  "page.impersonation.update.error": "Не вдалося відправити форму. Будь ласка, перевірте внесені дані.",
  "page.impersonation.update.successful": "Користувача успішно змінено. Щоб вийти з облікового запису, скористайтеся виходом з системи.",
  "page.invoices.articles.table.description": "Опис",
  "page.invoices.articles.table.EAN": "EAN",
  "page.invoices.articles.table.MAN": "Номер артикулу Metro",
  "page.invoices.filter": "Фільтр",
  "page.invoices.listTitle": "Мої рахунки-фактури",
  "page.invoices.search": "Пошук",
  "page.invoices.search.placeholder": "Пошук за артикулом, номером рахунку-фактури тощо.",
  "page.overview.availableAt": "стане доступно на початку",
  "page.overview.availableUntil": "доступно до кінця",
  "page.overview.description": "На цьому сайті можна завантажити список придбаних артикулів (товарів). Файли надаються у вигляді електронних таблиць, які можна відкрити за допомогою програми для роботи з електронними таблицями на ваш вибір (наприклад, Microsoft Excel).",
  "page.overview.downloadOds": "завантажити.ods",
  "page.overview.export.fallback.VAT_CODES_UNKNOWN": "Невідомий ідентифікаційний номер ПДВ {0}",
  "page.overview.export.header.MO_TABLE_ARTICLE_DESCRIPTION": "Опис артикула (товару)",
  "page.overview.export.header.MO_TABLE_ARTICLE_GROUP_NAME": "Група артикулів",
  "page.overview.export.header.MO_TABLE_ARTICLE_NUMBER": "Номер артикулу",
  "page.overview.export.header.MO_TABLE_BUYING_STORE": "Торговельний центр",
  "page.overview.export.header.MO_TABLE_CARD_NUMBER": "Номер картки",
  "page.overview.export.header.MO_TABLE_CUSTOMER_NAME": "Ім''я клієнта",
  "page.overview.export.header.MO_TABLE_GTIN_NUMBER": "GTIN",
  "page.overview.export.header.MO_TABLE_INVOICE_DATE": "Дата рахунку-фактури",
  "page.overview.export.header.MO_TABLE_INVOICE_NUMBER": "Номер рахунку-фактури",
  "page.overview.export.header.MO_TABLE_LOT_NUMBER": "Номер лота",
  "page.overview.export.header.MO_TABLE_ORDER_ID": "Ідентифікатор замовлення",
  "page.overview.export.header.MO_TABLE_PRICE_GROSS": "Ціна брутто",
  "page.overview.export.header.MO_TABLE_PRICE_NET": "Ціна нетто",
  "page.overview.export.header.MO_TABLE_PRICE_PER_PIECE_KG": "Ціна/шт/кг",
  "page.overview.export.header.MO_TABLE_PRICE_PER_SELLING_UNIT_NET": "Ціна/Одиниця виміру нетто",
  "page.overview.export.header.MO_TABLE_QUANTITY": "Кількість",
  "page.overview.export.header.MO_TABLE_QUANTITY_PER_SELLING_UNIT": "Кількість/Одиниця виміру",
  "page.overview.export.header.MO_TABLE_TAXES_AND_FEES": "Податки та збори",
  "page.overview.export.header.MO_TABLE_VAT": "ПДВ",
  "page.overview.subDescription": "Звіти за останні 13 місяців доступні без обмежень щодо часу завантаження",
  "page.overview.title": "Огляд закупівель",
  "page.settings.description": "На цій сторінці ви зможете змінити свої налаштування електронних рахунків-фактур. Будь ласка, зверніть увагу, що для повної обробки запиту (зміни налаштувань) знадобиться декілька хвилин.",
  "page.settings.emailNotifications": "Надсилати сповіщення на електронну пошту (E-mail)",
  "page.settings.emailNotifications.description": "Якщо ви увімкнете цю опцію, ви отримуватимете сповіщення на електронну пошту про кожен новий рахунок-фактуру.",
  "page.settings.printAtCheckout": "Роздрукувати рахунок-фактуру на касі",
  "page.settings.printAtCheckout.description": "Якщо ви увімкнете цю опцію, ви отримаєте рахунок-фактуру під час оформлення замовлення в магазині. Деактивуйте цю опцію, якщо ви більше не бажаєте отримувати рахуноки-фактури. Ви все одно матимете доступ до всіх своїх цифрових документів через цей модуль в особостому кабінеті.",
  "page.settings.title": "Налаштування електронних рахунків-фактур",
  "page.settings.update": "Зберегти налаштування",
  "page.settings.update.submitted": "Вашу заявку було відправлено. Обробка може зайняти деякий час. Будь ласка, наберіться терпіння.",
  "page.settings.update.successful": "Налаштування успішно оновлено",
  "personal.card": "Персональна картка",
  "warning.business.has.no.valid.card": "Будь ласка, виберіть компанію з вашого облікового запису, щоб переглянути рахунки-фактури або у компанії НЕМАЄ ДІЙСНОЇ КАРТКИ."
}};

export function getTranslation(language: string) { // @ts-ignore
  return parsed[language]
};