import React, {useEffect, useState} from 'react';
import {ICustomerSetting, ICustomerSettings, useApi} from "../context/ApiContext";
import {Alert, Description, Heading, Separator, Toggle} from "@metro-ui/core";
import {ISelectedCardAndBusiness, useGeneralContext} from "../context/GeneralContext";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import CardholderDropDownList from "../component/InputField/CardholderDropDownList";
import {useIdamAuthContext} from "../context/IdamAuthContext";
import {hasCountryConfig} from "../utils";

interface ISettingsStage {
  mail: boolean | undefined,
  print: boolean | undefined,
  info: boolean,
}

const defaultSettingsStage: ISettingsStage = {
  mail: undefined,
  print: undefined,
  info: false,
}

const StyledAlert = styled(Alert)`
    margin-top: 10px;
`
const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`
const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`
const FlexHalfWidth = styled.div`
    flex: none;
    width: 45%;
`
const HorizontalSpace = styled.div`
    flex: none;
    width: 5%;
`

const PRINTOUT = "PRINTOUT";
const MAIL_NOTIFICATION = "MAIL_NOTIFICATION";

function SettingsPage() {
  const {country, activatedSettings, setActivatedSettings, resolveCardsList, selectedCardAndBusiness, cardFromIdamToken, alexCssBusinessCards, impersonatedCard, isAppReady} = useGeneralContext();
  const {isImpersonationMode} = useIdamAuthContext();
  const {t} = useTranslation();
  const api = useApi();
  const [settingsState, setSettingsState] = useState<ISettingsStage>(defaultSettingsStage);
  const [disableButton, setDisableButton] = useState(false)
  const [dropdownCard, setDropdownCard] = useState(selectedCardAndBusiness)
  const cardsList = resolveCardsList()

  const getCardholderId = ():string =>{
    if (isImpersonationMode && impersonatedCard) {
      return impersonatedCard
    } else if (dropdownCard?.cardholderId)
      return dropdownCard?.cardholderId
    //Force first card to be selected in case no card is selected

    if (cardsList.length && dropdownCard) {
      setDropdownCard({
        businessId: dropdownCard.businessId,
        cardholderId: cardsList[0]?.cardHolderID
      })
    }
    return cardsList[0]?.cardHolderID
  }

  let cardholderId: string = getCardholderId();

  useEffect(() => {
    if (isAppReady && !activatedSettings && hasCountryConfig(country, "SETTING_PAGE_ACTIVATED_COUNTRIES")) {
      api?.activatedSettings(country)?.then((countrySettings) => {
        setActivatedSettings(countrySettings.activatedSettings)
      })
    }
  }, [isAppReady])

  const getEmailActivated = (cardholderSetting: ICustomerSetting[]) => {
    let emailActivated = activatedSettings?.find((s) => s.name === MAIL_NOTIFICATION)?.defaultValue
    if (emailActivated !== undefined) {
      const userEmail = cardholderSetting.find((setting) => setting.setting.name === MAIL_NOTIFICATION)?.value
      if (userEmail !== undefined)
        emailActivated = userEmail
    }
    return emailActivated;
  }

  const getPrintOutActivated = (cardholderSetting: ICustomerSetting[]) => {
    let printOutActivated = activatedSettings?.find((s) => s.name === PRINTOUT)?.defaultValue
    if (printOutActivated !== undefined) {
      const userPrintOut = cardholderSetting.find((setting) => setting.setting.name === PRINTOUT)?.value
      if (userPrintOut !== undefined)
        printOutActivated = userPrintOut
    }
    return printOutActivated;
  }

  const getCardholderSetting = (settings: ICustomerSettings[]) => settings.length === 1 ? settings[0].settings :
      settings.find((setting) => cardholderId === setting.customer.storeId + '_' + setting.customer.customerId + '_' + setting.customer.cardholderId)?.settings;

  useEffect(() => {
    if (isAppReady && activatedSettings && cardholderId && hasCountryConfig(country, "SETTING_PAGE_ACTIVATED_COUNTRIES")) {
      api?.getSettings(cardholderId)?.then((settings) => {
        const cardholderSetting = getCardholderSetting(settings);
        if (!cardholderSetting) {
          console.error("Cannot find customer settings")
          return
        }

        setSettingsState({
          mail: getEmailActivated(cardholderSetting),
          print: getPrintOutActivated(cardholderSetting),
          info: false,
        })
      })
    }
  }, [isAppReady, activatedSettings, cardholderId, alexCssBusinessCards, cardFromIdamToken])

  if (!hasCountryConfig(country, "SETTING_PAGE_ACTIVATED_COUNTRIES") || !isAppReady || !cardholderId) {
    return <></>;
  }

  function saveSettings() {
    setDisableButton(true)
    setSettingsState({...settingsState, info: false})
    api?.saveSettings(cardholderId, settingsState.mail === true, settingsState.print === true)
        ?.then(success => {
          if (success) {
            setSettingsState({...settingsState, info: true})
              setTimeout(() => { setSettingsState({...settingsState, info: false}); setDisableButton(false)
              }, 5000)
          }
        })
  }

  return (
    <>
      {settingsState.info &&
      <>
          <StyledAlert
              variant="success"
              size="small"
              title=""
              shouldHideTitle
          >
            {t("page.settings.update.submitted")}
          </StyledAlert>
      </>
      }
      <Heading>
        {t("page.settings.title")}
      </Heading>
      <Separator/>
      <CardholderDropDownList
          filterMinusId={true}
          isDisabled={disableButton}
          page = "SETTINGS_PAGE"
          value={dropdownCard}
          setValue={(card: ISelectedCardAndBusiness) => { setDropdownCard(card) }}/>
      {cardsList.length > 1 && <div style={{paddingTop: "25px"}}/>}
      <FlexRow>
        <FlexHalfWidth>
          <text>{t("page.settings.description")}</text>
        </FlexHalfWidth>
        <HorizontalSpace/>
        <FlexColumn>
          <div>
            {settingsState.print !== undefined &&
                <>
                  <Toggle
                      id="printout"
                      name="printout"
                      data-testid="printoutCheckbox"
                      value={!settingsState.print}
                      isDisabled={disableButton}
                      onChange={() => {
                        settingsState.print = !settingsState.print
                        saveSettings()
                      }}
                  >
                    {t("page.settings.printSuppression")}
                  </Toggle>
                  <Description>
                    {t("page.settings.printSuppression.description")}
                  </Description>
                </>
            }
          </div>
          <div>
            {settingsState.mail !== undefined &&
            <>
                <Toggle
                    id="emailNotification"
                    name="emailNotification"
                    data-testid="emailNotificationCheckbox"
                    value={settingsState.mail}
                    isDisabled={disableButton}
                    onChange={() => {
                      settingsState.mail = !settingsState.mail
                      saveSettings();
                    }}
                >
                  {t("page.settings.emailNotifications")}
                </Toggle>
                <Description>
                  {t("page.settings.emailNotifications.description")}
                </Description>
            </>
            }
          </div>
        </FlexColumn>
      </FlexRow>
    </>
  );
}

export default SettingsPage;