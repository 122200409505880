import {ICustomer} from "./context/ApiContext";
import {Buffer} from "buffer";
import Cookies from "js-cookie";

const REACT_APP = "REACT_APP_";
const countryCodeToLocale: Record<string, string> = {
  'nl': 'nl-NL',
  'de': 'de-DE',
  'es': 'es-ES',
  'fr': 'fr-FR',
  'hu': 'hu-HU',
  'it': 'it-IT',
  'pt': 'pt-PT',
  'ro': 'ro-RO',
  'bg': 'bg-BG',
  'be': 'nl-BE'
}

export function getConfig(config: string) {
  return process.env[REACT_APP + config] ?? ''
}

export function getCountryConfig(country: string, config: string) {
  if (Object.keys(process.env).includes(REACT_APP + country.toUpperCase() + "_" + config))
    return process.env[REACT_APP + country.toUpperCase() + "_" + config]
  else
    return process.env[REACT_APP + config]
}

export function hasCountryConfig(country: string, config: string): boolean {
  const countries = process.env[REACT_APP + config]
  if (countries && countries.toUpperCase().search(country.toUpperCase()) >= 0)
    return true;
  return false;
}

export function getCountryURLConfig(country: string, config: string) {
  const url = process.env[REACT_APP + country.toUpperCase() + "_" + config]
  if (url)
    return url
  else
    return "#"
}

function getParams(source: string) {
  return source
    .substring(1)
    .split("&")
    .reduce(function (initial: { [key: string]: any; }, item) {
      if (item) {
        let parts = item.split("=");
        initial[parts[0]] = decodeURIComponent(parts[1]);
      }
      return initial;
    }, {});
}

export function getSearchParams() {
  return getParams(window.location.search)
}

export function getHashParams() {
  return getParams(window.location.hash)
}

export function removeHashParamsFromUrl() {
  window.history.pushState("", document.title, window.location.pathname + window.location.search);
}

export const randomString = (length = 6) => {
  return Math.random().toString(16).substring(2, 2+length);
};

export const getJwtPayload = (jwtString: string) => JSON.parse(Buffer.from(jwtString.split('.')[1], 'base64').toString())

export const formatCurrencyForCountry = (amount: number, country: string, currency: string) => {
  const locale = countryCodeToLocale[country.toLowerCase()]
  if (locale) {
    return new Intl.NumberFormat(locale, { style: 'currency', currency: currency}).format(amount);
  }
  return new Intl.NumberFormat(country, { style: 'currency', currency: currency}).format(amount);
};

export const resolveCountry = (): string => {
  const hostname = window.location.hostname;
  if (hostname === 'einvoice-frontend.einvoice.metro.cloud' || hostname === 'einvoice-frontend.einvoice-pp.metro.cloud') {
    return getConfig("DEFAULT_COUNTRY")!!.toLowerCase()
  }
  const splits = hostname.split(".");
  const length = splits.length;
  const defaultUrls = ["com", "io"];
  if (length > 2 && !defaultUrls.includes(splits[length - 1]))
    return splits[length - 1].toLowerCase();
  if (length > 2 && splits[length - 2].endsWith("tr"))
    return "tr"

  return getConfig("DEFAULT_COUNTRY")!!.toLowerCase()
}

export function getBaseUrlForCountry(country: string) {
  const baseUrl = process.env[REACT_APP + country.toUpperCase() + "_BASE_URL"]
  if (baseUrl)
    return baseUrl
  else
    return "#"
}

interface Cardholder {
  country: string;
  storeId: string;
  customerId: string;
  cardholderId: string;
}

const splitCardholderId = (cardholderId?: string): string | undefined => {
  const splits = cardholderId?.split('-')
  if (!splits) {
    return undefined
  }

  let result = "";

  if (splits.length === 4) {
    result = splits[3]
  } else if (splits.length === 5) {
    result = "-" + splits[4]
  } else {
    return undefined
  }

  return result;
}

export const parseCardholder = (cardholder?: string): Cardholder | undefined => {
  const splits = cardholder?.split('-')
  if (!splits || splits.length !== 4)
    return undefined

  return {
    country: splits[0],
    storeId: splits[1],
    customerId: splits[2],
    cardholderId: splits[3],
  }
}

export const parseCardholderForCustomerSettings = (cardholder?: string): ICustomer | undefined => {
  let splits = cardholder?.split('-')
  if (!splits || splits.length < 4) {
    splits = cardholder?.split('_')
    cardholder = cardholder?.replaceAll('_', '-')
    if (!splits) {
      return undefined
    }
  }

  let cardholderId = splitCardholderId(cardholder);

  if (!cardholderId) {
    return undefined
  }

  return {
    country: splits[0],
    storeId: Number(splits[1]),
    customerId: Number(splits[2]),
    cardholderId: Number(cardholderId),
  }
}

export const capitalizeFirstLetter = (text : string) =>
  text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export const stringOrEmpty = (text: string) => {
  return text ? text : '';
}

export const isBlank = (text?: string): boolean => {
  return !text || text.trim().length === 0;
}

const INVOICE_TYPE_PREFIX = "common.transaction.type";
export const getInvoiceTypeTranslationKey = (invoiceType?: string): string => {
  if (!invoiceType) {
    return "";
  }

  return `${INVOICE_TYPE_PREFIX}.${invoiceType}`;
}

export function saveCookie(name: string, value: string, expirationDays: number) {
  if (Cookies.get("allowedCookieCategories")?.includes("functional")) {
    Cookies.set(name, value, { expires: expirationDays });
  }
}

export function isIpadOS () {
  return navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /Macintosh/i.test(navigator.userAgent);
}
