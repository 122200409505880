import React from "react";
import {BasicTableCell, BasicTableRow, Button} from "@metro-ui/core";
import {SubdirectoryArrowRightIcon} from "../icon/SubdirectoryArrowRight";
import dateFormat from "dateformat";
import {Icons} from "@metro-ui/core/es6/icons/allAtOnce";
import {INVOICE_DATE_FORMAT} from "../../page/InvoicePage";
import {useApi} from "../../context/ApiContext";
import {useTranslation} from "react-i18next";
import {InvoiceRowPodProp} from "./InvoiceRowProps";
import {getInvoiceTypeTranslationKey} from "../../utils";
import {triggerCustomEventGoogleAnalytics360} from "../../ga360";
import styled, {css} from "styled-components";
import {useGeneralContext} from "../../context/GeneralContext";

const StyledBasicTableCell = styled(BasicTableCell)<{downloaded:boolean}>`
  font-weight: bold;    
  opacity: 100%;
  ${ p=> p.downloaded && css`
    font-weight: normal;    
    opacity: 60%;
  `}
`
const InvoiceRowPod = ({ podInvoice, downloadingTransactionId, anySearchOrDownloadInProgress, setDownloadingTransactionId }: InvoiceRowPodProp) => {

  const {downloadInvoice} = useApi()
  const {t} = useTranslation();
  const {selectedCardAndBusiness} = useGeneralContext()
  const cdmAccountId = selectedCardAndBusiness?.businessId !== "personal" ? selectedCardAndBusiness?.businessId : undefined

  const downloaded = podInvoice.downloaded
  return <>
    <BasicTableRow
        key={podInvoice.transactionId}>
      <BasicTableCell doesNotWrap={true}>
        <SubdirectoryArrowRightIcon/>
      </BasicTableCell>
      <StyledBasicTableCell downloaded={downloaded} doesNotWrap={true} data-testid='tablePodCell'>
        {dateFormat(podInvoice.invoiceTimestamp * 1000, INVOICE_DATE_FORMAT, true, false)}
      </StyledBasicTableCell>
      <StyledBasicTableCell downloaded={downloaded} doesNotWrap={true} data-testid='tablePodCell'>
        {podInvoice.invoiceNumber}
      </StyledBasicTableCell>
      <StyledBasicTableCell downloaded={downloaded} doesNotWrap={true} data-testid='tablePodCell'>
        {t(getInvoiceTypeTranslationKey(podInvoice.customerInvoiceType))}
      </StyledBasicTableCell>
      <BasicTableCell doesNotWrap={true}>
      </BasicTableCell>
      <BasicTableCell doesNotWrap={true}>
      </BasicTableCell>
      <BasicTableCell doesNotWrap={true} isAlignedRight={true}>
        <Button
            Icon={Icons["Download"]}
            variant={"outline"}
            size="small"
            isWaiting={downloadingTransactionId === podInvoice.transactionId}
            isDisabled={anySearchOrDownloadInProgress}
            onClick={() => {
              setDownloadingTransactionId(podInvoice.transactionId)
              downloadInvoice(podInvoice.transactionId, cdmAccountId)?.then(() => {
                setDownloadingTransactionId("")
              });
              triggerCustomEventGoogleAnalytics360("download POD invoice")
            }}
            data-testid='downloadPdfButtonPod'
        >
          {t("common.downloadPdf")}
        </Button>
      </BasicTableCell>
      <BasicTableCell doesNotWrap={true}>
      </BasicTableCell>
    </BasicTableRow>
  </>
}
export default InvoiceRowPod;