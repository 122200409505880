import {font} from "@metro-ui/tokens";
import React from "react";

export const MobileViewTableSubRow = (title:string, value:string, downloaded: boolean) =>
  <div style={{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    opacity: downloaded ? '60%' : '100%',
    fontWeight: downloaded ? 'normal' : 'bold'
  }}>
    <p style={{
      fontSize : font.text.large.size,
      lineHeight: font.text.large["line-height"],
      marginBottom: '5px',
      marginTop: '5px',
    }}>{title}</p>
    <p style={{
      fontSize : font.text.large.size,
      lineHeight: font.text.large["line-height"],
      marginBottom: '5px',
      marginTop: '5px',
    }}>{value}</p>
  </div>

export const MobileViewPodTableSubRow = (title:string, PodExpansionIcon:JSX.Element ) =>
  <div style={{
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  }}>
    {PodExpansionIcon}
    <p style={{
      fontSize : font.text.large.size,
      lineHeight: font.text.large["line-height"],
      marginBottom: '5px',
      marginTop: '5px',
    }}>{title}</p>
  </div>