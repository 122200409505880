import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes,} from "react-router-dom";
import ContextsLayer from './context/ContextsLayer';
import InvoicePage from "./page/InvoicePage";
import SettingsPage from "./page/SettingsPage";
import Layout from "./component/Layout";
import ImpersonationPage from "./page/ImpersonationPage";
import OverviewPage from "./page/OverviewPage";
import NoAccountPage from "./page/NoAccountPage";
import LogoutPage from "./page/LogoutPage";
import MetadataDescription from "./component/MetadataDescription";

interface IPage {
  name: string;
  path: string;
  translationKey: string;
}

export const pages: { [key: string]: IPage; } = {
  invoices: {name: "invoices", path: "/", translationKey: "nav.invoices"},
  overview: {name: "overview", path: "/overview", translationKey: "nav.overview"},
  settings: {name: "settings", path: "/settings", translationKey: "nav.settings"},
  impersonation: {name: "impersonation", path: "/impersonation", translationKey: "nav.impersonation"},
  noAccount: {name: "noAccount", path: "/noaccount", translationKey: "nav.noaccount"},
  logOut: {name: "logOut", path: "/logout", translationKey: "nav.logout"},
};

export const pageList = Object.keys(pages).map((page): IPage => pages[page])

function App() {
  return (
    <BrowserRouter>
      <ContextsLayer>
        <MetadataDescription/>
        <Layout>
          <Routes>
            <Route path={pages.invoices.path} element={<InvoicePage/>}/>
            <Route path={pages.overview.path} element={<OverviewPage/>}/>
            <Route path={pages.settings.path} element={<SettingsPage/>}/>
            <Route path={pages.impersonation.path} element={<ImpersonationPage/>}/>
            <Route path={pages.noAccount.path} element={<NoAccountPage/>}/>
            <Route path={pages.logOut.path} element={<LogoutPage/>}/>
          </Routes>
        </Layout>
      </ContextsLayer>
    </BrowserRouter>
  );
}

export default App;
