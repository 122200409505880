import React, {ReactNode, useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {resolveLocaleByCountry} from "../i18n";
import {hasCountryConfig, resolveCountry} from "../utils"
import {useLocation} from "react-router-dom";
import {IAlexCssBusinessCards, ISetting} from "./ApiContext";
import {datadogLogs} from "@datadog/browser-logs";
import moment from "moment";
import {updateDatePickerLocale, updateMomentJsLocale} from "../locales";
import {getAllCards, resolveCardsList} from "./cardListUtils";

export interface ISelectedCardAndBusiness {
  businessId: string|undefined;
  cardholderId: string|undefined;
}

export interface ICard {
  cardHolderName?: string;
  cardHolderID: string;
}

interface IGeneral {
  language: string;
  country: string;
  storeId: string;
  setStoreId: (store: string)=>void;
  apiErrorMessage: string;
  cleanApiErrorMessage: ()=>void;
  setApiErrorMessage: (message:string, url:string)=>void;
  setLanguage: (language: string) => void;
  activatedSettings: ISetting[] | undefined;
  setActivatedSettings: (activatedSettings: ISetting[]) => void;
  setSelectedCardAndBusiness: (cardholderAndBusiness: ISelectedCardAndBusiness | undefined) => void;
  selectedCardAndBusiness: ISelectedCardAndBusiness | undefined;
  isAppReady: boolean;
  setIsAppReady: (isAppReady: boolean) => void;
  gtmInitialized: boolean;
  setGtmInitialized: (gtmInitialized: boolean) => void;
  overviewDateList: moment.Moment[] | undefined;
  setOverviewDateList: (overviewDateList: moment.Moment[]) => void;
  overviewReportEmpty: boolean;
  setOverviewReportEmpty: (overviewReportEmpty: boolean) => void;
  alexCssBusinessCards: IAlexCssBusinessCards[] | undefined;
  setAlexCssBusinessCards: (alexCssBusinessCards: IAlexCssBusinessCards[] | undefined) => void;
  cardFromIdamToken?: string;
  setCardFromIdamToken: (card?: string) => void;
  getAllCards: () => string[];
  resolveCardsList: () => ICard[];
  impersonatedCard: string | undefined;
  setImpersonatedCard: (impersonatedCard: string | undefined) => void;
  customerRole: string;
  invoiceLimit: number;
  setInvoiceLimit: (invoiceLimit: number) => void
  from: Date;
  setFrom: (fromDate: Date) => void
  to: Date;
  setTo: (fromDate: Date) => void
  searchValue: string
  setSearchValue: (search: string) => void
  invoiceDownloadEmptyTransactionId: string | undefined
  setInvoiceDownloadEmptyTransactionId: (transactionId: string | undefined) => void
}

export const defaultValue: IGeneral = {
  language: "",
  country: "",
  storeId: "",
  setStoreId: () => {},
  apiErrorMessage: "",
  cleanApiErrorMessage: () => {},
  setApiErrorMessage: () => {},
  setLanguage: ()=>{},
  activatedSettings: undefined,
  setActivatedSettings: () => {},
  selectedCardAndBusiness: undefined,
  setSelectedCardAndBusiness: () => {},
  isAppReady: false,
  setIsAppReady: () => {},
  gtmInitialized: false,
  setGtmInitialized: () => {},
  overviewDateList: undefined,
  setOverviewDateList: () => {},
  overviewReportEmpty: false,
  setOverviewReportEmpty: () => {},
  alexCssBusinessCards: undefined,
  setAlexCssBusinessCards: () => {},
  cardFromIdamToken: undefined,
  setCardFromIdamToken: () => {},
  getAllCards: () =>[],
  resolveCardsList: () => [],
  impersonatedCard:  undefined,
  setImpersonatedCard: () => {},
  customerRole: "",
  invoiceLimit: 10,
  setInvoiceLimit: () => {},
  from: moment().subtract(6, 'months').toDate(),
  setFrom: () => {},
  to: moment().toDate(),
  setTo: () => {},
  searchValue: "",
  setSearchValue: () => {},
  invoiceDownloadEmptyTransactionId: undefined,
  setInvoiceDownloadEmptyTransactionId: () => {}
}

export const GeneralContext = React.createContext<IGeneral>(defaultValue);

const GeneralContextProvider = ({children}: { children: ReactNode }) => {
  const {i18n} = useTranslation();
  const country = resolveCountry();
  const location = useLocation();

  const [language, setLanguageState] = useState(resolveLocaleByCountry(resolveCountry()))
  const [storeId, setStoreId] = useState("")
  const [apiErrorMessage, setApiErrorMessageState] = useState("")
  const [activatedSettings, setActivatedSettings] = useState<ISetting[]>()
  const [selectedCardAndBusiness, setSelectedCardAndBusiness] = useState<ISelectedCardAndBusiness | undefined>(undefined)
  const [impersonatedCard, setImpersonatedCard] = useState<string | undefined>(undefined)
  const [alexCssBusinessCards, setAlexCssBusinessCards_] = useState<IAlexCssBusinessCards[] | undefined>(undefined)
  const [cardFromIdamToken, setCardFromIdamToken] = useState<string | undefined>(undefined)
  const [isAppReady, setIsAppReady] = useState<boolean>(false)
  const [gtmInitialized, setGtmInitialized] = useState<boolean>(false);
  const [overviewDateList, setOverviewDateList] = useState<moment.Moment[] | undefined>(undefined)
  const [overviewReportEmpty, setOverviewReportEmpty] = useState<boolean>(false)
  const [customerRole, setCustomerRole] = useState("")
  const [invoiceLimit, setInvoiceLimit] = useState(10)
  const [from, setFrom] = useState<Date>(moment().subtract(6, 'months').toDate())
  const [to, setTo] = useState<Date>(moment().toDate())
  const [searchValue, setSearchValue] = useState<string>("")
  const [invoiceDownloadEmptyTransactionId, setInvoiceDownloadEmptyTransactionId] = useState<string | undefined>(undefined)

  useEffect(() => {
    updateMomentJsLocale(language);
    updateDatePickerLocale(language);
  },[language])

  useEffect(() => {
    setApiErrorMessageState("")
  }, [location])


  useEffect( ()=> {
    const isAlexCountry = hasCountryConfig(country, "ALEX_COUNTRY");
    if(!isAlexCountry && !customerRole)
      setCustomerRole("customer")
    else if(isAlexCountry && selectedCardAndBusiness && alexCssBusinessCards) {
      const selectedBusiness = alexCssBusinessCards.find((businessCard)=> businessCard.accountId == selectedCardAndBusiness.businessId)
      if(selectedBusiness)
        setCustomerRole(selectedBusiness.role)
    }
  }, [selectedCardAndBusiness, alexCssBusinessCards])

  const setLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setLanguageState(language);
  }

  const setAlexCssBusinessCards = (cards: IAlexCssBusinessCards[] | undefined) => {
    if (cards) {
      let alexCssBusinessCards_ = [...cards]
      cards.forEach((elem, busId) => {
        elem.legitimations.forEach((_card, cardId) => {
          alexCssBusinessCards_[busId].legitimations[cardId] = country.toUpperCase() + "-" + alexCssBusinessCards_[busId].legitimations[cardId]
        })

        elem.legitimationsWithName.forEach((_card, cardId) => {
          alexCssBusinessCards_[busId].legitimationsWithName[cardId].legitimation = country.toUpperCase() + "-" + alexCssBusinessCards_[busId].legitimationsWithName[cardId].legitimation
        })
      })
      setAlexCssBusinessCards_(alexCssBusinessCards_)
    }
    setAlexCssBusinessCards_(cards)
  }

  const cleanApiErrorMessage = () =>  setApiErrorMessageState("");

  const setApiErrorMessage = (message:string, url:string) => {
    datadogLogs.logger.error(message, {'requestedUrl' : url, 'response' : message})
    // console.log(message, {'requestedUrl' : url, 'response' : message})
    setApiErrorMessageState(message.toString());
  }

  const contextValue: IGeneral = useMemo(() => ({
    country, language, storeId, setStoreId, setLanguage,
    apiErrorMessage, setApiErrorMessage, cleanApiErrorMessage,
    activatedSettings, setActivatedSettings,
    resolveCardsList: () => resolveCardsList(country, alexCssBusinessCards, cardFromIdamToken, selectedCardAndBusiness),
    getAllCards: () => getAllCards(country, alexCssBusinessCards, cardFromIdamToken),
    alexCssBusinessCards, setAlexCssBusinessCards,
    cardFromIdamToken, setCardFromIdamToken,
    selectedCardAndBusiness, setSelectedCardAndBusiness,
    isAppReady, setIsAppReady,
    gtmInitialized, setGtmInitialized,
    overviewDateList, setOverviewDateList,
    overviewReportEmpty, setOverviewReportEmpty,
    impersonatedCard, setImpersonatedCard,
    customerRole,
    invoiceLimit, setInvoiceLimit,
    from, setFrom,
    to, setTo,
    searchValue, setSearchValue,
    invoiceDownloadEmptyTransactionId, setInvoiceDownloadEmptyTransactionId
  }), [activatedSettings, alexCssBusinessCards, apiErrorMessage, country, customerRole, gtmInitialized,
    impersonatedCard, invoiceLimit, isAppReady, language, cardFromIdamToken, overviewDateList, overviewReportEmpty,
    selectedCardAndBusiness, storeId, to, from, searchValue, invoiceDownloadEmptyTransactionId]);

  return (
    <GeneralContext.Provider value={contextValue}>
      {children}
    </GeneralContext.Provider>
  )
}

export const useGeneralContext = () => useContext(GeneralContext);

export default GeneralContextProvider;