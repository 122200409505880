import React from "react";
import {useGeneralContext} from "../../context/GeneralContext";
import useScript from "../../hook/useScript";
import {getCountryConfig} from "../../utils";
import "@webcomponents/webcomponentsjs/webcomponents-bundle";
import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js';

function CookieDisclaimerX() {
  const {country} = useGeneralContext()
  const url = getCountryConfig(country, "METROX_URL") + '/data/apps/metro?sc_device=webcomponent&components=cookie%20disclaimer'

  useScript(url, "METROX_COOKIE_DISCLAIMER")
  return (
    <>
      <cms-cookie-disclaimer></cms-cookie-disclaimer>
    </>
  )
}

export default CookieDisclaimerX;