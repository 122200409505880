import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from 'i18next-chained-backend';
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import resourcesToBackend from 'i18next-resources-to-backend';

import {getTranslation} from "./translations";
import {resolveCountry} from "./utils";

export const defaultLanguage = "en"
const languages: Record<string, string> = {
  'en': 'English',
  'nl': 'Dutch',
  'de': 'Deutsch',
  'es': 'Español',
  'fr': 'Français',
  'hu': 'Magyar',
  'it': 'Italiano',
  'nl-BE': 'Dutch',
  'fr-BE': 'Français',
  'de-BE': 'Deutsch',
  'pt': 'Português',
  'pl': 'Polski',
  'ro': 'Românesc',
  'bg': 'български',
  'uk': 'Українська',
  'hr': 'Croatia'
}
export const resources = Object.keys(languages)
    .reduce((obj, locale) => {
      obj[locale] = {translation: getTranslation(locale)}
      return obj
    }, {} as Record<string, Record<string, string>>)

export const resolveLocaleByCountry = (country: string) => {
    let resolvedCountry = country.toLowerCase()
    switch (resolvedCountry) {
        case 'ua':
            resolvedCountry = 'uk';
            break;
    }
  const localeList = getLocalesByCountry(resolvedCountry)
  if(localeList.length > 0)
    return localeList[0];
  return defaultLanguage
}

export const getLocalesByCountry = (country:string)=>Object.keys(resources).filter((locale)=>locale.toUpperCase().endsWith(country.toUpperCase()))

export const getLanguageName = (locale:string):string|undefined => languages[locale]
const language = resolveLocaleByCountry(resolveCountry());

const isJestTest = process.env.JEST_WORKER_ID !== undefined;

i18n
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: isJestTest ? resources : undefined,
        lng: language,
        fallbackLng: "en",
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        backend: {
            // list of back end for fall back mechanism. Details here: https://github.com/i18next/i18next-chained-backend
            backends: [
                LocalStorageBackend,
                HttpBackend,
                resourcesToBackend(resources)
            ],
            backendOptions: [
                {
                    expirationTime: 4 * 60 * 60 * 1000,
                }, {
                    loadPath: '/translation/EINVOICE/{{lng}}'
                }
            ]
        },
    }, (error, t) => {
        if (error) {
           console.error(`failed to init i18next, language: ${language}`, error);
        }
    });

export default i18n;